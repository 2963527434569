.ds-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-property: opacity;
  transition-duration: 400ms;
  will-change: opacity;
}
.ds-overlay--navigation {
  z-index: 120;
}
.ds-overlay--modal {
  z-index: 9999;
}
.ds-overlay.show {
  pointer-events: unset;
  transition-delay: 0ms;
  opacity: 1;
}
.ds-overlay.hide {
  transition-delay: 0ms;
  opacity: 0;
}
.ds-overlay.hide-after-delay {
  transition-delay: 120ms;
  opacity: 0;
}
