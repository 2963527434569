// Fonts .font-size();

.font-size(@sizeValueInREM) {
  font-size: ~"@{sizeValueInREM}rem";
}

.line-height(@sizeValueInREM) {
  line-height: ~"@{sizeValueInREM}rem";
}

.line-height(@sizeValueInREM, @FFsizeValueInREM) {
  line-height: ~"@{sizeValueInREM}rem";

  @media screen and (-moz-images-in-menus:0) {
    line-height: ~"@{FFsizeValueInREM}rem";
  }
}

.font(@fontSizeValueInREM, @lineHeightValueInREM) {
  font-size: ~"@{fontSizeValueInREM}rem";
  line-height: ~"@{lineHeightValueInREM}rem";
}

.font(@fontSizeValueInREM, @lineHeightValueInREM, @FFlineHeightValueInREM) {
  font-size: ~"@{fontSizeValueInREM}rem";
  line-height: ~"@{lineHeightValueInREM}rem";

  @media screen and (-moz-images-in-menus:0) {
    line-height: ~"@{FFlineHeightValueInREM}rem";
  }
}

// Fonts
.font-ds-light() {
  font-weight: normal;
  font-style: normal;
}

.font-ds-book() {
  font-weight: normal;
  font-style: normal;
}

.font-ds-bold() {
  font-weight: 700;
  font-style: normal;
}

.font-ds-black() {
  font-weight: 900;
  font-style: normal;
}
