@import url("./Mixins/_mixins.less");

.mobile-nav-right {
  display: none;
}

.is-logged-in {
  @media all and (max-width: (@nav-medium - 1)) {
    .mobile-nav-right {
      display: block;
    }
  }
}


.mobile-navigation-header {
  color: #FFF;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 0;
  justify-content: center;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  height: 6.6rem;

  @media (min-width: @nav-medium) {
    display: none;
  }

  &__logo {
    width: 100%;
    display: block;
  }

  .mobile-navigation-header__logo-link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-navigation-header__logo-cube {
    width: 3rem;
    height: 3rem;
  }

  .mobile-navigation-header__brand-logo {
    height: 2.45rem;
  }

  &__logo-img {
    padding-left: 1.5rem;
    max-height: 2.5rem;

    @media (min-width: @nav-small) {
      height: 3rem;
    }
  }

  &__logo-text {
    display: none;
  }
}

.menu-toggle {
  float: left;

  @media all and (min-width: @nav-medium) {
    display: none;
  }

  @media screen and (-ms-high-contrast: active) and (max-width: 30em),
    (-ms-high-contrast: none) and (max-width: 30em) {
    z-index: 1;
    position: relative;
  }

  &-right {
    position: fixed;
    right: 0;
    top: 0;
    display: none;

    .menu-toggle-btn {
      div {
        &:nth-child(2) {
          transform: translateX(200%);
          -webkit-transform: translateX(200%);
          z-index: -1;
          opacity: 0;
        }
      }
    }
  }

  &-btn {
    width: 16.2vw;
    height: 16.2vw;
    display: block;
    box-sizing: border-box;
    padding: 4vw 4vw;
    position: relative;
    overflow: visible;

    div {
      width: 6vw;
      height: 3px;
      background-color: #FFF;
      display: inline-block;
      transition: transform 0.4s ease;
      -webkit-transition: transform 0.4s ease;
      border-radius: 0.4vw;
      position: absolute;
      top: ~"calc(50%)";
      left: 50%;
      margin-left: -3vw;
      transform: translate(0, 0) rotate(0);
      -webkit-transform: translate(0, 0) rotate(0);

      &:nth-child(1) {
        margin-top: -2vw;
      }

      &:nth-child(3) {
        margin-top: 2vw;
      }
    }
  }

  &-btn.active {
    div {
      &:nth-child(1) {
        transform: translate(-0.3vw, 2.25vw) rotate(45deg);
        -webkit-transform: translate(-0.3vw, 2.25vw) rotate(45deg);
      }

      &:nth-child(2) {
        transform: translateX(-300%);
        -webkit-transform: translateX(-300%);
      }

      &:nth-child(3) {
        transform: translate(-0.3vw, -1.76vw) rotate(-45deg);
        -webkit-transform: translate(-0.3vw, -1.76vw) rotate(-45deg);
      }
    }
  }
}

.mobile-user-info {
  clear: both;
  width: 100%;
  display: none;
  background-color: rgba(0, 0, 0, .2);
  padding: 0.1vw 2vw;
  box-sizing: border-box;

  .is-logged-in & {
    display: block;

    @media all and (min-width: @nav-medium) {
      display: none;
    }
  }

  &.modal-wrapper.overlay-active:before {
    pointer-events: none;
    background-color: initial;
  }

  &:after {
    display: table;
    content: ' ';
    clear: both;
  }

  .js-balance-total {
    font-weight: bold;
    text-align: right;
    white-space: nowrap;

    &:not(:empty):before {
      font-weight: normal;
      content: 'Saldo: ';
    }
  }



  &__left,
  &__right {
    display: inline-block;
    vertical-align: middle;

    p,
    a {
      margin: 0;
      padding: 0.5vw 0;
      color: #FFF;
      font-size: 2.7vw;
      line-height: 1;

      @media all and (min-width: @nav-small) and (max-width: (@nav-medium - 1)) {
        font-size: 14px;
      }
    }
  }

  &__left {
    float: left;
  }

  &__right {
    float: right;
  }
}

.games-mobile-nav {
  position: fixed;
  background-color: #014B32;
  z-index: 20;

  .corporate-nav__list-link {
    &:before {
      display: none !important;
    }
  }

  @media all and (min-width: @nav-medium) {
    display: none;
  }

  a {
    color: #fff;

    &:after {
      border-color: #fff !important;
      border-width: 2px !important;
    }
  }

  a[class*=-link] {
    z-index: 90;
  }

  .sub-navigation {
    background-color: #fff !important;
    max-height: none !important;
    height: 0;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;

    transition: height 0.4s ease !important;

    .games-left,
    .games-center,
    .games-right {
      width: 100% !important;
      max-width: 100% !important;
      float: none;
      padding: 1rem 2% 4vw;

      p {
        font-size: 2.5vw;
      }

      .games-list {
        margin-top: 0;

        &-item {
          width: calc(~'50% - 1rem');
          max-height: none;
          height: 12vw;
        }
      }

      .header__cta-component {
        &__description {
          white-space: pre-line;
          font-size: 4vw;
        }

        &__cta-home {
          padding: 3.6vw 5vw 2.5vw;
          font-size: 4vw;
        }
      }
    }

    .games-right {
      padding-top: 4vw !important;
    }
  }
}

@media all and (max-width: (@nav-medium - 1)) {
  //Move header when opening mobile navigation(left)
  .header.is-showing-left-menu {
    transform: translateX(84.6vw) !important;
    -webkit-transform: translateX(84.6vw) !important;
  }
  
  //Move header when opening mobile navigation(right)
  .header.is-showing-right-menu {
    transform: translateX(-100vw) !important;
    -webkit-transform: translateX(-100vw) !important;

    &:before {
      transform: translateX(15.4vw);
      -webkit-transform: translateX(15.4vw);
    }

    .menu-toggle-right {
      position: fixed; // Override overflow hidden without removing it. 
      display: block;
      margin-top: 0;
      right: -15.4vw;
    }

    .profile-btn {
      .mobile-only {
        display: none;
      }
    }
  }

  .header {
    pointer-events: all;
  }

  nav:first-of-type {
    display: none;
  }

  nav:last-of-type {
    display: block;
    max-width: 100vw;
  }

  .corporate-nav {
    &__list-item.js-has-subnav:first-child {
      display: none;
    }
  }

  .brand-nav,
  .corporate-nav {
    overflow: hidden;
    padding: 0;

    .user-bar {
      display: block;

      .desktop-only {
        display: none;
      }

      &__item {
        position: relative;
        line-height: 1;
        color: #FFF;
        font-size: 1.1rem;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        padding: 0;
        margin-right: 3vw;
        float: right;
      }

      &__login-icon {
        height: 2rem;
        width: 2rem;
        position: absolute;
        top: 1.4rem;
        left: calc(~'50% - 1rem');
      }

      .login-btn.mobile-only {
        display: inline-block; //For frontpage only = redundant, Fix this
        position: relative;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        font-weight: bold;
        padding: 0;
        margin-right: 3vw;
        float: right;

        &:before {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          white-space: nowrap;
          line-height: 1;
        }

        .is-logged-in & {
          display: none;
        }
      }
        
      .create-btn.mobile-only {
        
        .is-logged-in & {
          display: none;
        }
      }

      .profile-btn.mobile-only {
        display: none;
        background: #fff;
        border-radius: 50%;
        position: relative;

        &:before {
          content: " ";
          width: 4vw;
          height: 4.5vw;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -56%);
          -ms-transform: translate(-50%, -56%);
          transform: translate(-50%, -56%);
          display: inline-block;
          background-position: 50%;
          background-repeat: no-repeat;
        }

        .is-logged-in & {
          display: block;
        }
      }
    }

    &__logo {
      &-text {
        display: none;
        text-decoration: none;
      }
    }

    //specific for corporate mobile
    .corporate-nav__logo {
      text-align: center;

      img {
        max-height: 2.5rem;
      }
    }

    &__navigation {
      position: fixed;
      margin-left: 0;
      width: 84.6vw;
      height: 100vh;
      left: 0;
      top: 0;
      box-shadow: 0 0 2px rgba(0, 0, 0, .2);
      z-index: 1;

      &.mobile-nav-left {
        transform: translateX(-84.6vw);
        -webkit-transform: translateX(-84.6vw);
      }

      &.mobile-nav-right {
        transform: translateX(115.4vw);
        -webkit-transform: translateX(115.4vw);
      }

      .is-tablet-detected & {
        width: 50vw;

        &.mobile-nav-left {
          transform: translateX(-50vw);
          -webkit-transform: translateX(-50vw);
        }

        &.mobile-nav-right {
          transform: translateX(100vw);
          -webkit-transform: translateX(100vw);
        }
      }
    }

    &__list {
      background-color: #FFF;
      max-height: 100vh;
      min-height: 100vh;
      padding-bottom: 80vw;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      position: relative;
      z-index: 1;
    }

    &__item,
    &__list-item {
      font-size: 1.6rem;
      width: 100%;
    }

    &__link,
    &__list-link {
      display: block;
      border-bottom: 1px solid #eee;
      color: #323232;
      font-size: inherit;

      .user-bar & {
        color: #fff;
        border-bottom: none;
      }

      .js-has-subnav & {
        &:after {
          position: absolute;
          display: block;
          z-index: 100;
          content: ' ';
          width: 1rem;
          height: 1rem;
          top: 3.7rem;
          left: auto;
          right: 3rem;
          border-right: 2px solid #323232;
          border-bottom: 2px solid #323232;
          transform: translateY(-50%) rotate(45deg);
          transition: all 0.4s ease;

          @media(min-width: @nav-medium) {
            display: none;
          }
        }
      }

      .js-has-subnav.has-shown-subnav &,
      .js-has-subnav.active & {
        &:after {
          transform: translateY(0) rotate(225deg);
        }
      }
    }

    .sub-navigation {
      max-height: 0;
      transition: max-height 0.4s ease;
      background-color: #f0f0f0;
      overflow: hidden;
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);

      &__inner {
        padding-left: 0;
      }

      &__list {
        width: 100%;
      }

      &__item {
        display: block;
        width: 100%;
        padding-left: 3rem;
      }

      &__link {
        padding: 2rem 1.8rem;
        color: #000;
      }
    }
    //Right-nav Aka account-nav
    .mobile-nav-right {
      .mega-menu__account-details:last-of-type,
      .mega-menu__account-details__divider,
      .last-login,
      .desktop-only,
      .welcome-text {
        display: none;
      }

      .mega-menu__account-details {
        &__row {

          &--right {
            float: right;
          }
        }
      }

      .mobile-navigation-header {
        .corporate-nav__logo-img {
          height: 2.2rem;
        }

        .profile-btn {
          background: #fff;
          border-radius: 50%;
          height: 9vw;
          width: 9vw;
          position: relative;
          top: 3.5vw;

          &:before {
            content: " ";
            width: 4vw;
            height: 4vw;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: inline-block;
            background-position: 50%;
            background-repeat: no-repeat;
          }

          &:after {
            position: absolute;
            content: ' ';
            left: 0;
            bottom: -3.5vw;
            width: 100%;
            height: 0.4rem;
            background-color: #FFF;
          }
        }
      }

      .mega-menu__account {
        padding: 0 6vw;

        &-details__title {
          margin-bottom: 0;

          &__inner {
            font-size: 4vw;
          }
        }

        &-details__large-text {
          font-size: 5vw;
        }

        &-details__right {
          float: right;
        }

        &-details__logout-btn {
          font-size: 3.5vw;
        }

        &-details__details-btn {
          font-size: 3.5vw;
          line-height: 1.5;
        }

        &-details__button {
          width: 100%;
          font-size: 4vw;
          display: block;
          margin-left: 0;
          text-align: center;
        }

        &-meta {
          white-space: initial;
        }
      }

      .sub-navigation-inner {
        background: #FFF;
        max-height: 100vh;
        min-height: 100vh;
        padding-bottom: 50vw;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        ul,
        li {
          margin: 0;
          padding: 0;
        }
      }

      .mega-menu__section:first-of-type {
        margin-top: 4vw;
        border-top: 1px solid #ccc;
      }

      .mega-menu__section__title {
        >.navigation-item-link {
          display: block;
          color: inherit;
          padding: 6vw 6vw 4vw;
          cursor: default;
          outline: none;
          text-decoration: none;
          font-size: 5vw;
          font-weight: 600;
          border-bottom: 1px solid #ccc;
          text-align: center;
        }
      }

      .mega-menu__link-list {
        &__item {
          &__link {
            display: block;
            padding: 4vw 6vw;
            border-bottom: 1px solid #ccc;
            font-size: 4vw;
            font-weight: 600;
            text-decoration: none;
            color: #333333;
          }
        }
      }
    }
  }
}

.menu-toggle-right {
  .menu-toggle-btn.active {
    div {
      margin-left: 0.4rem;
    }
  }
}

.menu-toggle {

  &-btn {
    width: 3rem;
    height: 3rem;
    padding: 3.3rem 2.7rem;

    div {
      width: 22px;
      height: 3px;
      margin-left: -10px;

      @media(min-width: @nav-small) {
        width: 35px;
      }

      &:nth-child(1) {
        margin-top: -10px;
      }

      &:nth-child(3) {
        margin-top: 10px;
      }
    }
  }


  &-btn.active {
    div {

      &:nth-child(1) {
        transform: translate(-8px, 10px) rotate(45deg);
        -webkit-transform: translate(-8px, 10px) rotate(45deg);
      }


      &:nth-child(3) {
        transform: translate(-8px, -10px) rotate(-45deg);
        -webkit-transform: translate(-8px, -10px) rotate(-45deg);
      }
    }
  }
}

.brand-nav,
.corporate-nav {
  &__logo-link {
    width: 100%;
    display: flex;
    align-items: center;

    @media(min-width: @nav-small) {
      justify-content: center;
    }

    @media(min-width: @nav-medium) {
      display: block;
    }

    .is-logged-in & {
      justify-content: center;
    }
  }

  &__logo {
    .top-navigation.is-showing-left-menu & {
      display: none;
    }

    .top-navigation.is-showing-right-menu & {
      display: none;
    }

    &-cube {
      width: 2rem;
      height: 2rem;

      @media(max-width: @nav-extra-small) {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  &__item {
    font-size: 1.6rem;
  }

  .user-bar {
    &__item {
      font-size: 1.1rem;
      height: 6.7rem;
      line-height: 9.1rem;
    }

    .login-btn.mobile-only {
      font-size: 1.1rem;
      line-height: 9.1rem;

      @media(min-width: @nav-medium) {
        font-size: 1.8rem;
      }
    }
    //avatar for PAM
    .dli &,
    .dlo & {
      .profile-btn.mobile-only {
        height: 4.4rem;
        width: 4.4rem;
        top: 1rem;

        &:before {
          width: 2.3rem;
          height: 2.3rem;
          margin: 0;
        }

        .top-navigation.is-showing-right-menu & {
          display: none;
        }
      }
    }
  }

  .games-mobile-nav {
    .corporate-nav__list-link {
      font-size: 2rem;
      padding: 2rem 5rem;
    }

    .sub-navigation {
      .games-list-item {
        height: 4.5rem;
      }

      .header__cta-component {
        &__description {
          font-size: 1.8rem;
        }

        &__cta-home {
          font-size: 2rem;
          padding: 2rem 5rem;
        }
      }
    }
  }

  .mobile-nav-right {
    .profile-btn.mobile-only {
      height: 4.4rem;
      width: 4.4rem;
      top: 1.4rem;

      &:before {
        width: 2.3rem;
        height: 2.3rem;
        margin: 0;
      }
    }
  }
}
//tablet
@media (min-width: @nav-small) and (max-width: (@nav-medium - 1)) {
  .brand-nav,
  .corporate-nav {

    &__navigation {
      &.mobile-nav-right {
        transform: translateX(100vw);
        -webkit-transform: translateX(100vw);

        .mega-menu__account {
          &-details__title {
            margin-bottom: 0;
          }

          &-details__logout-btn {
            font-size: inherit;
            padding-top: 0.2rem;

            .is-tablet-detected & {
              padding-top: 0.5rem;
            }
          }

          &-details__button,
          &-details__details-btn {
            font-size: inherit;
          }
        }

        .navigation-list {
          .navigation-item {
            .navigation-item-link {
              font-size: inherit;
            }
          }
        }
      }
    }

    .mobile-nav-right {
      .profile-btn.mobile-only {
        display: none !important;
      }
    }

    &__container {
      .pam-return-to-site-close {
        .is-tablet-detected & {
          left: 0 !important;
          width: 6vw !important;
          height: 6vw !important;
          top: 2vw !important;
        }
      }
    }
  }

  .header.is-showing-right-menu {
    padding: 0;
    transform: translateX(-84.6vw) !important;

    .menu-toggle-right {
      right: 0;
      transform: none !important;
    }

    .is-tablet-detected & {
      transform: translateX(-50vw) !important;
    }
  }

  .header.is-showing-left-menu {
    padding: 0;
    transform: translateX(84.6vw) !important;

    .is-tablet-detected & {
      transform: translateX(50vw) !important;
    }
  }

  .header {
    margin-top: -1px;
  }
}
//Windows phone specific
@media screen and (-ms-high-contrast: active) and (max-device-width: 30em), (-ms-high-contrast: none) and (max-device-width: 30em) {
  .mobile-navigation-header {
    &__logo-img {
      max-width: 30vw;
    }
  }

  .header {
    &.is-showing-left-menu {
      .mobile-nav-left {
        transform: translateX(0px);
      }
    }

    .brand-nav {
      transition: transform 0.4s ease;

      &__logo {
        text-align: center;
        z-index: 0;

        &-img {
          max-width: 30vw;
        }

        a {
          display: inline-block;
          z-index: 0;
        }
      }
    }
  }
}

