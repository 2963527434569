@import url("../../Domain/Campaigns/Mixins/_mixins.less");
@import (reference) "Mixins/_mixins.less";


@theme-color: #dc5f11;
@secondary-overlay-color: #0d55a1;


body.overlay-enabled .generic-overlay {
  height: 100%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  overflow: auto;
}

.generic-overlay {
  width: 980px;
  min-height: 500px;
  max-height: calc(~"100vh - 120px");
  margin: 100px auto 60px;
  box-shadow: 0 20px 30px @rgba-black-050;
  border-radius: 3px;
  .theme-background-colors();

  .genovl-header {
    position: relative;
    z-index: 10;
    height: 80px;
    padding: 13px 30px 0 31px;

    .genovl-title {
      float: left;
      margin: 0;
      padding-top: 12px;
      .cm-font-ds-black();
      .cm-font(2.5, 2.7);
      color: #fff;
    }

    .genovl-close {
      float: right;
      display: block;
      width: 42px;
      height: 43px;
      .cm-corners-all(3px);
      cursor: pointer;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(0, 0, 0, 0.3);

      &:hover {
        box-shadow: rgba(0,0,0,0.15) 0 0 18px inset;
      }

      &:before {
        display: block;
        position: absolute;
        width: 42px;
        height: 43px;
        margin: 0;
        content: "";
        .cm-corners-all(3px);
        border-top: 1px solid rgba(255, 255, 255, 0.3);
      }

      &:after {
        display: block;
        width: 18px;
        height: 19px;
        margin: 11px auto 0;
        content: "";
        background: url(~"@{design-base-url}/Graphics/regions/all-regions/sprite-global.png") no-repeat -300px -100px;
      }
    }
  }

  .genovl-column-wrapper {
    padding: 0 30px;
    .cm-clearfix();
  }

  .genovl-primary-column {
    float: right;
    position: relative;
    z-index: 20;
    width: (664/(980 - 2*30))*100%;

    &.full-size {
      width: 100%;

      .genovl-video {
        height: 517px;
      }
    }
  }

  .genovl-video {
    background: #808080;
    padding: 0;
    margin: 0;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 374px;

    iframe {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }
  }

  .genovl-image {
    background: #808080;
    padding: 0;
    margin: 0;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5);
    width: 100%;

    img {
      width: 100%;
      height: auto;
      padding: 0;
      margin: 0;
    }
  }



  &.marketing-overlay {

    .genovl-column-wrapper {
      padding: 0;
    }


    .genovl-article {
      box-shadow: none;
    }

    .genovl-headline {
      .cm-font-ds-black();
      .cm-font(6, 6.5);
      .cm-text-shadow(2px,2px,2px,rgba(0,0,0,.7));
      color: #000;
      margin-top: 0.4em;
      text-align: center;
      text-transform: uppercase;
    }

    .genovl-image {
      box-shadow: none;
    }



    &.color-bingo-lime {
      @theme-color: #a0d323;
      
      background-color: @theme-color;

      .genovl-headline {
        color: @theme-color;
      }      
      &.color-blitz-bordeaux {
        @theme-color: #6b0000;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }      
      }
      &.color-blitz-white {
        @theme-color: #ffffff;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }      
      }
      &.color-casino-gold {
        @theme-color: #a78952;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }      
      }
      &.color-ds-green {
        @theme-color: #004b32;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-ds-yellow {
        @theme-color: #feb700;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-dantoto-orange {
        @theme-color: #dc5f11;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-eurojackpot-gold {
        @theme-color: #e8a332;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-keno-lightblue {
        @theme-color: #00a5eb;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-lotto-red {
        @theme-color: #c50005;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-oddset-darkblue {
        @theme-color: #003e99;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-oddset-grey {
        @theme-color: #2b2b2b;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-oddset-orange {
        @theme-color: #dc5f11;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-onsdagslotto-blue {
        @theme-color: #0072ba;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-poker-black {
        @theme-color: #000000;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-quick-violet {
        @theme-color: #7f4488;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-tips-green {
        @theme-color: #009a60;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-cego-greyblue {
        @theme-color: #7d96b4;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
      &.color-spillehjoernet-greyblue {
        @theme-color: #7d96b4;
      
        background-color: @theme-color;

        .genovl-headline {
          color: @theme-color;
        }
      }
    }
  }

}
