.ds-navigation-account-menu__logged-out-details {
  width: 100%;
  text-align: center;
}
body.is-logged-in .ds-navigation-account-menu__logged-out-details {
  display: none;
}
body:not(.is-logged-in) .ds-navigation-account-menu__logged-out-details {
  display: block;
}
body.is-context-neutral .ds-navigation-account-menu__logged-out-details {
  display: block;
}
.ds-navigation-account-menu__region {
  width: 100%;
  background-color: #eeeeee;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 2.4rem;
  border-radius: 0.8rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .ds-navigation-account-menu__region {
    padding: 3.4rem;
  }
}
.ds-navigation-account-menu__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
}
.ds-navigation-account-menu__title {
  font-family: DanskeSpil, serif;
  line-height: normal;
  color: #222222;
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.01em;
}
@media screen and (min-width: 768px) {
  .ds-navigation-account-menu__title {
    font-size: 2.8rem;
  }
}
.ds-navigation-account-menu__region--dli .ds-navigation-account-menu__title {
  color: #003E99;
}
.ds-navigation-account-menu__region--dlo .ds-navigation-account-menu__title {
  color: #c50005;
}
.ds-navigation-account-menu__text {
  color: #828282;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
}
.ds-navigation-account-menu__region-info {
  align-self: center;
  color: #000000;
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  border: none;
  border-radius: 0.5rem;
  display: inline-flex;
  background: none;
}
