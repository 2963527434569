@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Colors/_colors";

.ds-navigation-account-menu__logged-out-details {
  width: 100%;
  text-align: center;

  body.is-logged-in & {
    display: none;
  }

  body:not(.is-logged-in) & {
    display: block;
  }

  body.is-context-neutral & {
    display: block;
  }
}

.ds-navigation-account-menu__region {
  width: 100%;
  background-color: @ds-gray-100;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 2.4rem;
  border-radius: 0.8rem;
  margin-bottom: 2rem;

  @media @sm {
    padding: 3.4rem;
  }
}

.ds-navigation-account-menu__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
}

.ds-navigation-account-menu__title {
  font-family: DanskeSpil, serif;
  line-height: normal;
  color: @ds-gray-900;
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.01em;

  @media @sm {
    font-size: 2.8rem;
  }

  .ds-navigation-account-menu__region--dli & {
    color: @ds-dark-blue;
  }

  .ds-navigation-account-menu__region--dlo & {
    color: @ds-red;
  }
}

.ds-navigation-account-menu__text {
  color: @ds-gray-525;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.ds-navigation-account-menu__region-info {
  align-self: center;
  color: @ds-black;
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  border: none;
  border-radius: 0.5rem;
  display: inline-flex;
  background: none;
}
