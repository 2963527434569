@notifications-small: 768px;
@notifications-medium: 1024px;
@notifications-large: 1440px;
@warning-color: #ffb800;

.notifications {
  transition: all 0.4s ease;
  position: relative;

  &.desktop-only {
    display: none;
  }

  @media all and (min-width: @notifications-small) {
    &.mobile-only {
      display: none;
    }
    &.desktop-only {
      display: block;
    }
  }

  &-item {
    display: none; // Will be shown by JS
    width: 100%;
    background-color: #FFF;
    text-align: center;
    padding: 2.5vw 2vw;
    box-sizing: border-box;
    animation-name: flipInX;
    animation-duration: 0.8s;
    border-top: 1px solid #eee;

    @media all and (min-width: @notifications-small) {
      padding: 7px 18px;
    }

    .notifications-cta {
      display: inline-block;
      background-color: #014b33;
      color: white;
      font-size: 13px;
      padding: 1rem 2rem;
      margin-top: 1rem;
      border-radius: 3px;
      text-decoration: none;

      &:hover {
        background-color: lighten(#014b33, 5%);
      }

      &:empty {
        display: none;
      }
    }

    &-inner {
      max-width: 1440px;
      width: 100%;
      min-height: 8vw;
      font-weight: 600;
      text-align: center;
      margin: 0 auto;
      padding-right: 6vw;
      position: relative;
      box-sizing: border-box;
      font-size: 3vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media all and (min-width: @notifications-small) {
        padding-left: 4rem;
        padding-right: 4rem;
        min-height: 2.5rem;
        font-size: 13px;
      }

      .pam-page.new-login-page & {
        padding-right: 0;
      }

      span {
        display: block;
        position: relative;
        padding: 5px;
        padding-left: 8.5vw;

        @media all and (min-width: @notifications-small) {
          padding-left: 40px;
        }

        a {
          color: inherit;
          text-decoration: underline;
        }

        &:before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50.5%);
          width: 8vw;
          height: 8vw;
          content: ' ';
          display: inline-block;
          vertical-align: middle;
          margin-right: 3vw;
          background-position: 0;
          background-size: 8vw;
  
          @media all and (min-width: @notifications-small) {
            width: 35px;
            height: 35px;
            background-size: 35px;
            margin-right: 10px;
          }
        }
      }

      span,
      button {
        vertical-align: middle;
        line-height:1.3;
      }

      button {
        width: 4vw;
        height: 4vw;
        background-color: transparent;
        background-image: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#close_use');
        background-position: center;
        background-size: 4vw;
        background-repeat: no-repeat;
        border: none;
        float: right;
        padding: 2vw;
        box-sizing: content-box;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50.5%);
        .ios-device & {
          background-image: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/close.svg');
        }
        @media all and (min-width: @notifications-small) {
          width: 15px;
          height: 15px;
          padding: 5px;
          background-size: 15px;
        }

        .pam-page.new-login-page & {
          display: none;
        }
      }
    }

    &.error-notification {
      font-weight: bold;
      background-color: @warning-color;
      .notifications-item-inner {
        span {
          &:before {
            background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#error_black_use');
            .ios-device & {
              background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/error_black.svg');
            }
          }
        }
        
      }
    }

    &.info-notification {
      .notifications-item-inner {
        span {
          &:before {
            background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#info_use');
            .ios-device & {
              background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/info.svg');
            }
          }
        }
      }
    }

    &.success-notification {
      .notifications-item-inner {
        span {
          &:before {
            background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#success_use');
            .ios-device & {
              background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/success.svg');
            }
          }
        }
      }
    }
    &.warning-notification {
      font-weight: bold;
      background-color: @warning-color;
      .notifications-item-inner {
        span {
          &:before {
            background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#warning_black_use');
            .ios-device & {
              background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/warning_black.svg');
            }
          }
        }
      }
    }
  }
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, -100px, 0, -90deg);
  }
  50% {
    transform: perspective(400px) rotate3d(1, -20px, 0, -90deg);
  }
  100% {
    transform: perspective(400px);
  }
}
