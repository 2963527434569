.ds-toasts {
  position: fixed;
  z-index: 99;
  bottom: 4vh;
  left: 50%;
  width: calc(96%);
  max-width: 40rem;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
}
@media screen and (min-width: 1024px) {
  .ds-toasts {
    bottom: 3vh;
  }
}
.ds-toast {
  display: flex;
  align-items: center;
  color: black;
  flex: 1;
  font-size: 1.4rem;
  line-height: 1.3;
  padding: 1rem 4rem 1rem 2rem;
  min-height: 5rem;
  background-color: #FFE299;
  transition: all 340ms var(--bezierEaseInOutQuad);
  visibility: hidden;
  border-radius: 0.8rem;
  opacity: 0;
  transform: translateY(1rem);
  box-shadow: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.1);
}
.ds-toast--open {
  visibility: unset;
  opacity: unset;
  transform: translateY(0);
}
.ds-toast__close {
  background: none;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  width: 5rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.ds-toast__close svg {
  width: 2.4rem;
  height: 2.4rem;
}
.ds-toast__close:active {
  transform: translateY(0.1rem);
}
