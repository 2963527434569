.ds-navigation-top-menu {
  grid-area: top-menu;
  display: none;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  height: 100%;
}
.ds-navigation-top-menu ul {
  list-style-type: none;
}
.ds-navigation-top-menu > li {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}
.ds-navigation-top-menu > li:hover > a,
.ds-navigation-top-menu > li.active > a,
.ds-navigation-top-menu > li:hover > button,
.ds-navigation-top-menu > li.active > button {
  background-color: rgba(0, 0, 0, 0.3);
}
.ds-navigation-top-menu > li.open > button svg {
  transform: rotateX(0.5turn);
}
.ds-navigation-top-menu svg {
  margin: 0 -0.8rem 0 0.4rem;
  width: 1.6rem;
  height: 1.6rem;
  transition: transform 340ms var(--bezierEaseInOutQuad);
}
.ds-navigation-top-menu a,
.ds-navigation-top-menu button {
  width: 100%;
  position: relative;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.9rem;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0.6rem;
  transition: background-color 200ms ease-out;
  user-select: none;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 600;
  color: white;
}
.ds-navigation-top-menu a:hover,
.ds-navigation-top-menu button:hover {
  text-decoration: none;
}
.ds-navigation-top-menu--sub-menu a,
.ds-navigation-top-menu--sub-menu button {
  font-size: 1.4rem;
  padding: 0.6rem 1.5rem;
}
.ds-navigation-top-menu--has-icons a,
.ds-navigation-top-menu--has-icons button {
  font-size: 1.4rem;
  padding: 3.6rem 1.8rem 0.8rem;
}
.ds-navigation-top-menu--has-icons a > img,
.ds-navigation-top-menu--has-icons button > img {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  height: 2.4rem;
}
@media screen and (min-width: 1216px) {
  .ds-navigation-top-menu {
    display: flex;
  }
}
.ds-navigation-top-menu__sub-items {
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate3d(-50%, 1rem, 0);
  min-width: 25rem;
  top: 7rem;
  background-color: white;
  filter: drop-shadow(0 0.8rem 2rem rgba(0, 0, 0, 0.14));
  border-radius: 0.8rem;
  padding: 1rem;
  flex-direction: column;
  gap: 0.4rem;
  visibility: hidden;
  opacity: 0;
  display: flex;
  transition: all 200ms ease;
}
.ds-navigation-top-menu__sub-items ul {
  display: grid;
  row-gap: 0.6rem;
}
.ds-navigation-top-menu__sub-items li {
  min-height: 4rem;
  border-radius: 0.6rem;
}
.ds-navigation-top-menu__sub-items a,
.ds-navigation-top-menu__sub-items button {
  height: 4rem;
  color: #000000;
  font-size: 1.4rem;
  line-height: 1.3;
  padding: 0.5rem 2rem 0.5rem 1.4rem;
  backface-visibility: hidden;
  /* to keep text from being blurred */
}
.ds-navigation-top-menu__sub-items > ul > li.active > a,
.ds-navigation-top-menu__sub-items > ul > li:has(a):hover > a,
.ds-navigation-top-menu__sub-items > ul > li.open > button,
.ds-navigation-top-menu__sub-items > ul > li:has(button):hover button {
  background-color: #eeeeee;
}
.ds-navigation-top-menu__sub-items > ul > li {
  background-color: transparent;
  transition: background-color 340ms var(--bezierEaseInOutQuad);
}
.ds-navigation-top-menu > li.open .ds-navigation-top-menu__sub-items {
  visibility: unset;
  opacity: unset;
  transform: translate3d(-50%, 0, 0);
}
.ds-navigation-top-menu--sub-menu .ds-navigation-top-menu__sub-items {
  top: 5rem;
  min-width: 23rem;
}
.ds-navigation-top-menu__sub-sub-items {
  position: relative;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 340ms var(--bezierEaseInOutQuad);
  will-change: grid-template-rows;
  border-radius: 0 0 0.6rem 0.6rem;
}
.ds-navigation-top-menu__sub-sub-items li {
  min-height: 3.6rem;
}
.ds-navigation-top-menu__sub-sub-items li a,
.ds-navigation-top-menu__sub-sub-items li button {
  height: 3.6rem;
}
.ds-navigation-top-menu__sub-sub-items > ul {
  overflow: hidden;
  margin: 0;
  transition: margin 340ms var(--bezierEaseInOutQuad);
}
.ds-navigation-top-menu__sub-sub-items > ul::before {
  content: "";
  position: absolute;
  top: 0.3rem;
  left: 1.2rem;
  right: 1.2rem;
  height: 0.1rem;
}
.ds-navigation-top-menu__sub-items > ul > li.open > button {
  border-radius: 0.6rem 0.6rem 0 0;
}
.ds-navigation-top-menu__sub-items > ul > li.open > button svg {
  transform: rotateX(0.5turn);
}
.ds-navigation-top-menu__sub-items > ul > li.open .ds-navigation-top-menu__sub-sub-items {
  grid-template-rows: 1fr;
  background-color: #eeeeee;
}
.ds-navigation-top-menu__sub-items > ul > li.open .ds-navigation-top-menu__sub-sub-items > ul {
  margin: 1.2rem 0;
}
.ds-navigation-top-menu__sub-items > ul > li.open .ds-navigation-top-menu__sub-sub-items > ul::before {
  background-color: rgba(0, 0, 0, 0.12);
}
