﻿.ds-global-sprite(@left: 0, @top: 0) {
  background-image: url(~'@{design-base-url}/Graphics/regions/all-regions/sprite-global.png');
  background-repeat: no-repeat;
  background-position: @left @top;
}

.radial-gradient(@darkcolor: rgb(0,0,0), @lightcolor: rgb(255,255,255), @radius: 400px, @coordinates: top center) {
  background: @darkcolor; /* Old browsers */
  background: @darkcolor radial-gradient(circle at @coordinates, @lightcolor 0%, @darkcolor @radius) no-repeat; /* W3C */
}
