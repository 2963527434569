@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Variables/colors";
@import (reference) "./NavigationMixins";

.ds-modal-popup {
  .ds-navigation__focus-outline();
  position: fixed;
  z-index: 10000;
  bottom: 3vh;
  left: 50%;
  max-height: 85vh;
  background-color: @ds-white;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 17vw);
  max-width: 38rem;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(-50%, 1rem, 0);
  transition: all 340ms cubic-bezier(0.455, 0.030, 0.515, 0.955);

  &--open {
    pointer-events: unset;
    visibility: unset;
    opacity: unset;
    transform: translate3d(-50%, 0, 0);
  }

  a:active,
  button:active {
    transform: translateY(0.1rem);
  }

  @media (min-width: @sm-breakpoint) and (min-height: @sm-breakpoint) {
    max-width: 52rem;
    max-height: 70vh;
    top: 15vh;
    bottom: auto;
  }
}

.ds-modal-popup__close {
  .ds-navigation__menu-close();

  .ds-modal-popup--hide-close-x & {
    display: none;
  }
}

.ds-modal-popup__inner {
  position: relative;
  top: auto;
  bottom: auto;
  width: 100%;
  overflow-y: auto;
  margin: 1rem 0;
  padding: 6.4rem 2.4rem 2.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;
  color: @ds-gray-900;
  text-align: center;

  @media (min-width: @sm-breakpoint) and (min-height: @sm-breakpoint) {
    padding: 6.4rem 4rem 3rem;
  }
}

.ds-modal-popup__icon {
  height: 7.2rem;
  margin-top: -4rem;
  margin-bottom: 2.4rem;
}

.ds-modal-popup__header {
  font-family: var(--font-family-danskespil);
  color: @ds-black;
  font-size: 2.8rem;
  line-height: 1.15;
  letter-spacing: -0.02em;
  font-style: normal;
  font-weight: 700;

  @media (min-width: @sm-breakpoint) and (min-height: @sm-breakpoint) {
    font-size: 4rem;
  }
}

.ds-modal-popup__text {
  font-size: 1.4rem;
  line-height: 1.57;
  font-style: normal;
  font-weight: 400;

  br + br:nth-of-type(2) {
    display: block;
    content: "";
    margin-top: 1.2em;
  }

  p {
    margin: 0 0 0.5em;
  }

  a {
    color: @ds-black;
    text-decoration: underline;
  }

  @media (min-width: @sm-breakpoint) and (min-height: @sm-breakpoint) {
    font-size: 1.6rem;
  }
}

.ds-modal-popup__buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.6rem;
  margin: 1rem auto 0;
  max-width: 30.8rem;

  @media (min-width: @sm-breakpoint) and (min-height: @sm-breakpoint) {
    margin-top: 2rem;
  }
}

.ds-modal-popup__button {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 0.8ch;
  width: 100%;
  padding: 0 1.2rem 0.1rem;
  flex: 0 0 5.6rem;
  border-radius: 10rem;
  border: 0.1rem solid fade(@ds-black, 40%);
  color: @ds-black;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: @ds-white;

  &:hover {
    text-decoration: none;
  }

  b {
    font-weight: inherit;
  }

  @media (min-width: @sm-breakpoint) and (min-height: @sm-breakpoint) {
    font-size: 1.4rem;
    flex: 0 0 5.4rem;
  }

  &--primary {
    background-color: @ds-yellow;
    border-color: @ds-yellow;
  }
}
