@font-face {
  font-family: "EurojackpotText";
  src: url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotText-Regular.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "EurojackpotText";
  src: url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotText-Bold.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "EurojackpotDisplay";
  src: url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Regular.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "EurojackpotDisplay";
  src: url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Bold.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "EurojackpotDisplay";
  src: url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Black.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
