﻿@import url("variables.less");

.mega-menu {
  @media all and (min-width: @nav-medium) {
    .navigation-list {
      padding-left: 0 !important;
    }

    .mega-menu__link-list {
      display: block !important;

      &__item {
        display: block;
        float: none;
        color: @nav-black-text;
        font-size: 1.4rem;

        &__link {
          display: inline-block;
          font-weight: 400;
          color: inherit;
          font-size: inherit;
          padding: 0 0 10px 0 !important;
          text-decoration: none;
        }
      }
    }
  }

  @media all and (max-width: (@nav-medium - 1)) {
    .sub-sub-navigation__section-link {
      font-size: 1.6rem;
      width: 100%;
      padding: 1.6rem;
      padding-left: 3.2rem;
      color: #000;
      font-weight: 600 !important;
      display: inline-block;
    }

    .mega-menu__link-list {
      max-height: none !important;
      display: block;
      width: 100%;

      &__item {
        font-size: 1.6rem;
        display: block;
        width: 100%;

        &__link {
          padding: 1.6rem;
          padding-left: 4.8rem;
          color: #000;
          font-weight: 600 !important;
          display: inline-block;
        }
      }
    }
  }
}
