@font-face {
  font-family: "Anton Regular";
  src: url("/Components/Common/Framework/Design/Fonts/Anton/anton-regular.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Anton/anton-regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "AvenirNext";
  src: url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextLTW05-HeavyCond.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextLTW05-HeavyCond.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir-Next';
  src: url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextW05-Regular.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextLTW05-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNextCondensed';
  src: url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextLTW05-HeavyCond.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextLTW05-HeavyCond.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir-Next';
  src: url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextW05-Italic.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextW05-Italic.woff") format("woff");
  font-style: italic;
}
@font-face {
  font-family: "DanskeSpil";
  src: url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Light.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DanskeSpil";
  src: url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Regular.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DanskeSpil";
  src: url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Medium.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DanskeSpil";
  src: url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Bold.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DanskeSpil";
  src: url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Black.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "EurojackpotText";
  src: url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotText-Regular.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "EurojackpotText";
  src: url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotText-Bold.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "EurojackpotDisplay";
  src: url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Regular.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "EurojackpotDisplay";
  src: url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Bold.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "EurojackpotDisplay";
  src: url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Black.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Eurojackpot/EurojackpotDisplay-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Kansas";
  src: url("/Components/Common/Framework/Design/Fonts/KansasNew/KansasNew-Black.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/KansasNew/KansasNew-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextThin.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextThin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextExtraLight.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextLight.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextRegular.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextMedium.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextSemibold.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextSemibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextBold.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextBlack.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "MergePro";
  src: url("/Components/Common/Framework/Design/Fonts/MergePro/MergePro.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/MergePro/MergePro.woff") format("woff");
  font-display: swap;
}
/* open-sans-latin-300-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-300-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-300-normal.woff) format('woff');
}
/* open-sans-latin-300-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-300-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-300-italic.woff) format('woff');
}
/* open-sans-latin-400-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-400-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-400-normal.woff) format('woff');
}
/* open-sans-latin-400-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-400-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-400-italic.woff) format('woff');
}
/* open-sans-latin-500-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-500-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-500-normal.woff) format('woff');
}
/* open-sans-latin-500-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-500-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-500-italic.woff) format('woff');
}
/* open-sans-latin-600-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-600-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-600-normal.woff) format('woff');
}
/* open-sans-latin-600-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-600-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-600-italic.woff) format('woff');
}
/* open-sans-latin-700-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-700-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-700-normal.woff) format('woff');
}
/* open-sans-latin-700-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-700-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-700-italic.woff) format('woff');
}
/* open-sans-latin-800-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-800-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-800-normal.woff) format('woff');
}
/* open-sans-latin-800-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-800-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-800-italic.woff) format('woff');
}
@font-face {
  font-family: "OliveVillage";
  src: url("/Components/Common/Framework/Design/Fonts/OliveVillage/OliveVillage-Regular.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/OliveVillage/OliveVillage-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OliveVillage";
  src: url("/Components/Common/Framework/Design/Fonts/OliveVillage/OliveVillage-Italic.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/OliveVillage/OliveVillage-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
/* raleway-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-500.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-500.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Note: Font was added for the casino project and may need to be added to danske spil as well */
/* raleway-500 italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-MediumItalic.woff2') format('woff2');
}
/* raleway-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-700.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-700.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Note: Font was added for the casino project and may need to be added to danske spil as well */
/* raleway-700 italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-BoldItalic.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-BoldItalic.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Note: Font was added for the casino project and may need to be added to danske spil as well */
/* raleway-800 - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-ExtraBold.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-ExtraBold.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Note: Font was added for the casino project and may need to be added to danske spil as well */
/* raleway-800 italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-ExtraBoldItalic.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-ExtraBoldItalic.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* raleway-900italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-900italic.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-900italic.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Shadows Into Light';
  src: url("/Components/Common/Framework/Design/Fonts/ShadowsIntoLight/shadowsintolight-regular.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/ShadowsIntoLight/shadowsintolight-regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: block;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Helium";
  src: url('/Components/Common/Framework/Design/Fonts/Helium/helium-bold.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Helium/helium-bold.woff') format('woff');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DSCasinoMultima";
  src: url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Regular.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DSCasinoMultima";
  src: url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Medium.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DSCasinoMultima";
  src: url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Bold.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DSCasinoMultima";
  src: url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Black.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "VikinglottoDisplay";
  src: url('/Components/Common/Framework/Design/Fonts/VikingLottoDisplayBasic/VikinglottoDisplay-Basic.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/VikingLottoDisplayBasic/VikinglottoDisplay-Basic.woff') format('woff');
}
:root {
  --font-family-danskespil: DanskeSpil, "Open Sans", Helvetica, Arial, sans-serif;
  --font-family-raleway: Raleway, "Open Sans", Helvetica, Arial, sans-serif;
  --font-family-degular: Degular, Arial, sans-serif;
  --font-family-ds-casino-multima: DSCasinoMultima, Arial, sans-serif;
}
