.generic-overlay .genovl-aside-column {
  float: left;
  position: relative;
  z-index: 20;
  width: 23.47826087%;
}
.generic-overlay .aside-navigation {
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.generic-overlay .aside-navigation .item {
  border-top: 1px solid #d6d6d6;
}
.generic-overlay .aside-navigation .item:first-child {
  border-top: none;
  border-radius: 3px 3px 0 0;
}
.generic-overlay .aside-navigation .item:last-child {
  border-radius: 0 0 3px 3px;
}
.generic-overlay .aside-navigation .item:nth-child(odd) {
  background-color: white;
}
.generic-overlay .aside-navigation .item:nth-child(even) {
  background-color: white;
}
.generic-overlay .aside-navigation .item .link {
  position: relative;
  display: block;
  padding: 13px 32px 14px 20px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: black;
}
.generic-overlay .aside-navigation .item .link:hover {
  text-decoration: none;
}
.generic-overlay .aside-navigation .item .link:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  width: 12px;
  height: 21px;
  margin-top: -11px;
  background-image: url(/Components/DanskeSpil/Project/Design/Graphics/regions/all-regions/sprite-global.png);
  background-repeat: no-repeat;
  background-position: -300px -200px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  opacity: 0.4;
}
.generic-overlay .aside-navigation .item .link.selected {
  color: white;
}
.generic-overlay.color-bingo-lime .aside-navigation .item:hover {
  background-color: #e8f8c2;
}
.generic-overlay.color-bingo-lime .aside-navigation .item.selected {
  background-color: #3a500d;
}
.generic-overlay.color-casino-gold .aside-navigation .item:hover {
  background-color: #e6dac4;
}
.generic-overlay.color-casino-gold .aside-navigation .item.selected {
  background-color: #4f3f26;
}
.generic-overlay.color-ds-green .header .close {
  background-color: #feb700;
}
.generic-overlay.color-ds-green .aside-navigation .item:hover {
  background-color: #c7e3d9;
}
.generic-overlay.color-ds-green .aside-navigation .item.selected {
  background-color: #feb700;
}
.generic-overlay.color-dantoto-orange .aside-navigation .item:hover {
  background-color: #ddc8bb;
}
.generic-overlay.color-dantoto-orange .aside-navigation .item.selected {
  background-color: #792c08;
}
.generic-overlay.color-eurojackpot-gold .aside-navigation .item:hover {
  background-color: #e3c7c8;
}
.generic-overlay.color-eurojackpot-gold .aside-navigation .item.selected {
  background-color: #430003;
}
.generic-overlay.color-keno-lightblue .aside-navigation .item:hover {
  background-color: #bcddeb;
}
.generic-overlay.color-keno-lightblue .aside-navigation .item.selected {
  background-color: #004d8f;
}
.generic-overlay.color-live-casino-gold .aside-navigation .item {
  background-color: #a78952;
  border-top: 1px solid #846b3f;
}
.generic-overlay.color-live-casino-gold .aside-navigation .item:hover {
  background-color: #bea069;
}
.generic-overlay.color-live-casino-gold .aside-navigation .item.selected {
  background-color: #503e23;
}
.generic-overlay.color-lotto-red .aside-navigation .item:hover {
  background-color: #f1b6b7;
}
.generic-overlay.color-lotto-red .aside-navigation .item.selected {
  background-color: #640003;
}
.generic-overlay.color-oddset-darkblue .aside-navigation .item:hover {
  background-color: #b2c9eb;
}
.generic-overlay.color-oddset-darkblue .aside-navigation .item.selected {
  background-color: #001c47;
}
.generic-overlay.color-poker-black .aside-navigation .item:hover {
  background-color: #d2d2d2;
}
.generic-overlay.color-poker-black .aside-navigation .item.selected {
  background-color: #4d4d4d;
}
.generic-overlay.color-quick-violet .aside-navigation .item:hover {
  background-color: #dcc0e0;
}
.generic-overlay.color-quick-violet .aside-navigation .item.selected {
  background-color: #3a1f3f;
}
.generic-overlay.color-tips-green .aside-navigation .item:hover {
  background-color: #ade6d0;
}
.generic-overlay.color-tips-green .aside-navigation .item.selected {
  background-color: #00472c;
}
.generic-overlay.color-cego-greyblue .aside-navigation .item:hover {
  background-color: #c4d2e3;
}
.generic-overlay.color-cego-greyblue .aside-navigation .item.selected {
  background-color: #3a4657;
}
.generic-overlay.color-spillehjoernet-greyblue .aside-navigation .item:hover {
  background-color: #c4d2e3;
}
.generic-overlay.color-spillehjoernet-greyblue .aside-navigation .item.selected {
  background-color: #3a4657;
}
.generic-overlay.color-onsdagslotto-blue .aside-navigation .item:hover {
  background-color: #b2c9eb;
}
.generic-overlay.color-onsdagslotto-blue .aside-navigation .item.selected {
  background-color: #001c47;
}
