﻿@import url("./Mixins/_mixins.less");

@grey: #222;
@white: white;
@black: black;

// If a page has the new "Spil med omtanke" feature component...
:has(.responsible-gaming__top) {

  // ...hide the static fix
  .time-consuming-notification-static-fix {
    display: none;
  }

  // ...hide the Time Consuming Notification
  .time-consuming-notification {
    display: none
  }
}

// livecasino, spillehjoernet, bingo game launcher, slingo game launcher
.region-avalon-livecasino, .region-avalon-bingo, .region-avalon-bingo-gvc, .region-avalon-bingo-slingo, .spillehjoernet-gamelauncher, .region-avalon-spillehjoernet {
  .time-consuming-notification.fallback {
    position: fixed;
    z-index: 1000;
    width: 100%;
  }
}

.region-avalon-bingo-gvc.is-mobile-detected { //bingo mobile use fallback version as there is no header
  .time-consuming-notification.fallback {
    position: relative;
    display: block;
    z-index: 1000;
    width: 100%;
  }
}

.time-consuming-notification-wrapper {
  transition: all 0.4s ease;
  z-index: 2;
  position: relative
}

// Static fix
.time-consuming-notification-static-fix {
  height: 3.6rem;

  @media (min-width: @nav-small) and (max-width: (@nav-medium - 1)) { //tablet
    height: 3.2rem;
  }

  @media all and (max-width: (@nav-small - 1)) { //mobile
    height: 3rem;
    font-size: 1.1rem;
  }
}

// Hide, if disabled on the current channel
body.time-consuming-notification-channel-disabled {
  .time-consuming-notification-static-fix, .time-consuming-notification {
    display: none;
  }
}
// Hide on mobile, when left hand navigation is slided in
.is-showing-left-menu {
  .time-consuming-notification {
    display: none;
  }
}


// Main styling begins here:


.time-consuming-notification {
  font-family: var(--font-family-danskespil);
  padding: 0;
  height: 3.6rem;
  background-color: @white;
  transition: all 0.4s ease;
  z-index: 2;
  position: relative;
  font-size: 1.3rem;
  font-weight: 400;
  color: @grey;

  @media (min-width: @nav-small) and (max-width: (@nav-medium - 1)) { //tablet
    height: 3.2rem;
  }

  @media all and (max-width: (@nav-small - 1)) { //mobile
    height: 3rem;
    font-size: 1.1rem;
  }

  @media all and (max-width: (@nav-extra-small - 1)) { // mobile - xstra small
    font-size: 0.84rem;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    background: @white;
    height: inherit;
  }

  &__link {
    margin: 0.50rem;

    @media (min-width: @nav-small) and (max-width: (@nav-medium - 1)) { //tablet
      margin: 0.6rem;
    }

    @media all and (max-width: (@nav-small - 1)) { //mobile
      // margin: 0.3rem 0.6rem;
    }

    a {
      color: @black;
      text-decoration: none;
    }
  }

  &__icon {
    height: 2.6rem;
    width: 2.2rem;

    @media (min-width: @nav-small) and (max-width: (@nav-medium - 1)) { //tablet
      height: 2.2rem;
      width: 2.2rem;
    }

    @media all and (max-width: (@nav-small - 1)) { //mobile
      height: 2.2rem;
      width: 2.2rem;
    }
  }

  &__header-text {
    font-weight: 300;
    flex: 1 1;
    text-align: center;
    margin: 1.1rem 0 0.9rem;
    line-height: 1.3rem;
    font-size: 1.3rem;

    @media (min-width: @nav-small) and (max-width: (@nav-medium - 1)) { //tablet
      margin: 1.1rem 0 0.8rem;
      line-height: 1.2rem;
      font-size: 1.1rem;
    }

    @media all and (max-width: (@nav-small - 1)) { //mobile
      margin: 0.9rem 0.5rem;
      font-size: 1rem;
    }

    @media all and (max-width: (@nav-extra-small - 1)) { // mobile - xstra small
      font-size: 0.84rem;
    }
  }

  &__timer {
    margin: 1.1rem 0 0.9rem;
    line-height: 1.4rem;
    display: flex;
    justify-content: flex-end;
    font-size: 1.3rem;
    text-align: end;

    &--hidden {
      display: none;
    }

    @media (min-width: @nav-small) and (max-width: (@nav-medium - 1)) { //tablet
      margin: 1.1rem 0 0.8rem;
      line-height: 1.2rem;
      font-size: 1.1rem;
    }

    @media all and (max-width: (@nav-small - 1)) { //mobile
      margin: 0.8rem 0.5rem 1.0rem;
      font-size: 1rem;
    }

    @media all and (max-width: (@nav-extra-small - 1)) { // mobile - xstra small
      font-size: 0.84rem;
    }
  }

  &__timer-item {
    width: 4.5rem;
  }

  &__timer-icon {
    height: 1.4rem;
    width: 2rem;
    margin: 1.1rem;

    @media (min-width: @nav-small) and (max-width: (@nav-medium - 1)) { //tablet
      height: 1.2rem;
      margin: 0.8rem;
    }

    @media all and (max-width: (@nav-small - 1)) { //mobile
      margin: 0.8rem 0;
      height: 1.2rem;
    }
  }

  &__stopwatch {
    height: 1.6rem;
    margin: 0.9rem 1rem 1.1rem;

    @media all and (max-width: (@nav-small - 1)) { //mobile
      height: 1.4rem;
      margin: 0.8rem 0 0.8rem;
    }
  }
}
