@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Variables/colors";

.ds-navigation__menu-close(@currentColor: @ds-black, @border: transparent, @backgroundColor: fade(@ds-black, 12%)) {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 2rem;
  right: 2rem;
  width: 3.6rem;
  height: 3.6rem;
  border: none;
  border-radius: 50%;
  background-color: @backgroundColor;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 0.4rem @border;

  @media @sm {
    top: 3rem;
    right: 4rem;
  }

  svg {
    // Due to Safari mobile, we need to position the svg absolute, otherwise it shrinks (see FOR-777).
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;

    color: @currentColor;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.ds-navigation__focus-outline(@outlineColor: rgba(0, 0, 0, 1)) {
  a:focus {
    outline: none;
  }

  a:focus-visible,
  *:focus-visible {
    outline: @outlineColor dotted thin;
    outline-offset: 0.2rem;
  }

  a:active,
  *:active {
    outline: none;
  }
}
