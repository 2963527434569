﻿@import url("variables.less");
@import url("./Mixins/_mixins.less");

.mega-menu {
  .mega-menu__image-list {
    display: flex;
    flex-wrap: wrap;

    @media all and (max-width: @nav-medium) {
      flex-direction: column;
    }

    &__item {
      color: @nav-black-text;
      padding: 1.2rem 5rem;
      font-size: 21px;
      display: inline-block;

      @media all and (min-width: @nav-medium) {
        padding: 0 1.5rem 2.2rem 1.5rem;
      }

      &__link {
        font-weight: 100;
        color: inherit;
        font-size: inherit;
        display: block;
        width: 100%;

        html[class] & {
          padding: 0 0 5px;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 2px;
          transition: .25s ease-out;
        }

        p {
          margin: 0;
          font-size: 12px;
          line-height: 1.5;
          overflow: hidden;
          transition: .25s ease-out;

          @media all and (max-width: @nav-medium) {
            font-size: 1.4rem;
          }
        }

        &:hover {
          opacity: 1;

          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  @media all and (min-width: (@nav-medium)) {
    .mega-menu__image-list {
      &__item {
        width: 33.3%;
      }
    }
  }

  // mobile
  @media all and (min-width: (@nav-medium)) {
    .mega-menu__image-list {
      &__item {
        width: 29%;
      }
    }

    .mega-menu__section {
      //If only one section exists:
      &:first-child:last-child {
        .mega-menu__image-list {
          &__item {
            max-width: 150px;
          }
        }
      }
    }
  }

  @media all and (max-width: (@nav-medium)) {
    .mega-menu__image-list {
      &__item {
        &__link {
          color: #000;
          font-weight: 600;
          display: inline-block;

          p {
            display: inline;
          }

          img {
            display: none;
          }
        }
      }
    }
  }
}
