@import "_imports.less";

.ppu {
  .ppu_wrapper--hide {
    opacity: 0;
    pointer-events: none;
  }

  .ppu_wrapper--show {
    opacity: 1;
    pointer-events: all;
  }

  .ppu__wrapper {
    position: relative;
    justify-content: center;
    overflow: hidden;
    transition: 1s;

    .ppu__closebutton {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      height: 2rem;
      width: 2rem;
    }

    .ppu__shadow {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      justify-content: center;
      background-color: @color-black;
      z-index: 9987;
      opacity: 0.4;
    }

    .ppu__content {
      margin-left: auto;
      margin-right: auto;
      max-height: 99%;
      overflow: hidden;
      z-index: 9988; // operations-messenger z-index 9990 - 10000
      text-align: center;
      background: @color-white;

      .ppu__element--show {
        top: 2vh;
      }

      .ppu__element--hide {
        top: -100%;
      }

      .ppu__element {
        position: fixed;
        width: 90vw;
        margin: 0 auto; /* Will not center vertically and won't work in IE6/7. */
        left: 0;
        right: 0;
        box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
        border-radius: 0.1rem;
        transition: 1.2s;
        transition-timing-function: ease-out;
        z-index: 9989;
        max-height: 96vh;
        background-color: inherit;
        padding: 5vw;
        font-size: 1rem;


        @media @md {
          padding: 2rem 10rem;
          width: 80rem;
          max-width: 100%;
          font-size: 1.5rem;
        }

        p {
          font-size: 0.9em;
        }

        h1 {
          font-size: 2.3em;
        }

        h2 {
          font-size: 2.2em;
        }

        h3 {
          font-size: 1.8em;
        }

        h4 {
          font-size: 1.5em;
        }

        h5 {
          font-size: 1.3em;
        }

        h6 {
          font-size: 1.1em;
        }

        a {
          font-size: 0.9em;
        }
      }
    }

    .ppu__loader {
      opacity: .7;
      background-color: black;
    }

    .ppu__cta-overflow {
      border-radius: 2rem;
      border: solid 2px transparent;
      height: 4.2rem;
      overflow: hidden;
      width: 21rem;
      display: inline-block;
      text-decoration: none;
      font-weight: 600;
      margin-top: 2rem;

      @media @md {
        &--left {
          float: left;
        }

        &--right {
          float: right;
        }
      }

      .ppu__cta {
        display: inline-table;
        height: 100%;
        width: 100%;
        text-decoration: none;
        color: @color-black;

        &:hover {
          text-decoration: none;
        }

        .ppu__cta-container {
          display: table-cell;
          vertical-align: middle;

          p {
            margin: 0;
            padding: 0;
            font-size: 1.3rem;
          }

          .ppu__cta-loader-box {
            position: absolute;
            transform: translateY(1rem) translateX(1rem) scale(1);
            opacity: 1;
            transition: transform .25s, opacity .15s;

            .loader-text {
              display: inline-block;
            }

            .loader-animation-box {
              margin-left: .5rem;
              display: inline-block;

              @keyframes loader-dot-animation {
                0%, 100% {
                  transform: translateY(0);
                }

                50% {
                  transform: translateY(-100%);
                }
              }

              .loader-dot {
                width: 1.2vw;
                height: 1.2vw;
                margin-left: .3rem;
                border-radius: 50%;
                background-color: @color-black;
                display: inline-block;

                &:nth-child(1n) {
                  animation: loader-dot-animation .5s ease-in-out -.66s infinite;
                }

                &:nth-child(2n) {
                  animation: loader-dot-animation .5s ease-in-out -.33s infinite;
                }

                &:nth-child(3n) {
                  animation: loader-dot-animation .5s ease-in-out -1s infinite;
                }


                @media @md {
                  width: .8rem;
                  height: .8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
