﻿@import "../../../../../../../DanskeSpil/Framework/Design/Styles/Mixins/DanskeSpilMixins.less"; 

.radial-gradient(@darkcolor: rgb(0,0,0), @lightcolor: rgb(255,255,255), @radius: 400px, @coordinates: top center) {
  background: @darkcolor; /* Old browsers */
  background: @darkcolor radial-gradient(circle at @coordinates, @lightcolor 0%, @darkcolor @radius) no-repeat; /* W3C */
}

.theme-background-gradients() {
  &.color-bingo-lime {
    .radial-gradient(@darkcolor: @cm-bingo-lime-primary, @lightcolor: @cm-bingo-lime-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-blitz-bordeaux {
    .radial-gradient(@darkcolor: @cm-blitz-bordeaux-primary, @lightcolor: @cm-blitz-bordeaux-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-blitz-white {
    .radial-gradient(@darkcolor: @cm-blitz-white-primary, @lightcolor: @cm-blitz-white-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-casino-gold {
    .radial-gradient(@darkcolor: @cm-casino-gold-primary, @lightcolor: @cm-casino-gold-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-ds-green {
    .radial-gradient(@darkcolor: @cm-ds-green-primary, @lightcolor: @cm-ds-green-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-ds-yellow {
    .radial-gradient(@darkcolor: @cm-ds-yellow-primary, @lightcolor: @cm-ds-yellow-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-dantoto-orange {
    .radial-gradient(@darkcolor: @cm-dantoto-orange-primary, @lightcolor: @cm-dantoto-orange-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-eurojackpot-gold {
    .radial-gradient(@darkcolor: @cm-eurojackpot-gold-primary, @lightcolor: @cm-eurojackpot-gold-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-keno-lightblue {
    .radial-gradient(@darkcolor: @cm-keno-lightblue-primary, @lightcolor: @cm-keno-lightblue-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-live-casino-gold {
    .radial-gradient(@darkcolor: @cm-live-casino-gold-primary, @lightcolor: @cm-live-casino-gold-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-lotto-red {
    .radial-gradient(@darkcolor: @cm-lotto-red-primary, @lightcolor: @cm-lotto-red-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-oddset-darkblue {
    .radial-gradient(@darkcolor: @cm-oddset-darkblue-primary, @lightcolor: @cm-oddset-darkblue-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-oddset-grey {
    .radial-gradient(@darkcolor: @cm-oddset-grey-primary, @lightcolor: @cm-oddset-grey-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-oddset-orange {
    .radial-gradient(@darkcolor: @cm-oddset-orange-primary, @lightcolor: @cm-oddset-orange-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-onsdagslotto-blue {
    .radial-gradient(@darkcolor: @cm-onsdagslotto-blue-primary, @lightcolor: @cm-onsdagslotto-blue-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-poker-black {
    .radial-gradient(@darkcolor: @cm-poker-black-primary, @lightcolor: @cm-poker-black-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-quick-violet {
    .radial-gradient(@darkcolor: @cm-quick-violet-primary, @lightcolor: @cm-quick-violet-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-tips-green {
    .radial-gradient(@darkcolor: @cm-tips-green-primary, @lightcolor: @cm-tips-green-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
  &.color-cego-greyblue {
    .radial-gradient(@darkcolor: @cm-cego-greyblue-primary, @lightcolor: @cm-cego-greyblue-secondary, @coordinates: 50% 215px, @radius: 650px);
  }
}

.theme-background-colors() {
  .color-bingo-lime & {
    background-color: @cm-bingo-lime-primary;
  }
  .color-blitz-bordeaux & {
    background-color: @cm-blitz-bordeaux-primary;
  }
  .color-blitz-white & {
    background-color: @cm-blitz-white-primary;
  }
  .color-casino-gold & {
    background-color: @cm-casino-gold-primary;
  }
  .color-ds-green & {
    background-color: @cm-ds-green-primary;
  }
  .color-ds-yellow & {
    background-color: @cm-ds-yellow-primary;
  }
  .color-dantoto-orange & {
    background-color: @cm-dantoto-orange-primary;
  }
  .color-eurojackpot-gold & {
    background-color: @cm-eurojackpot-gold-primary;
  }
  .color-keno-lightblue & {
    background-color: @cm-keno-lightblue-primary;
  }
  .color-lotto-red & {
    background-color: @cm-lotto-red-primary;
  }
  .color-oddset-darkblue & {
    background-color: @cm-oddset-darkblue-primary;
  }
  .color-oddset-grey & {
    background-color: @cm-oddset-grey-primary;
  }
  .color-oddset-orange & {
    background-color: @cm-oddset-orange-primary;
  }
  .color-onsdagslotto-blue & {
    background-color: @cm-onsdagslotto-blue-primary;
  }
  .color-poker-black & {
    background-color: @cm-poker-black-primary;
  }
  .color-quick-violet & {
    background-color: @cm-quick-violet-primary;
  }
  .color-tips-green & {
    background-color: @cm-tips-green-primary;
  }
  .color-cego-greyblue & {
    background-color: @cm-cego-greyblue-primary;
  }
  .color-spillehjoernet-greyblue & {
    background-color: @cm-cego-greyblue-primary;
  }
}

