@import (reference) "./variables.less";

.u-hidden {
  display: none !important;
}

.seo-text-container {
  width: 100%;
  padding: 0.8rem 2rem 1.1rem 2rem;
  background-color: #e6e6e6;
  color: @ds-white;

  .seo-text {
    max-width: 96rem;
    margin: 0 auto;
    
    a {
      color: @ds-white;
      text-decoration: underline;
    }

    h1,
    h2,
    h3 {
      text-transform: uppercase;
      text-align: center;
    }
  }
}

.global-footer {
  font-family: var(--font-family-danskespil);
  font-weight: 400;
  line-height: 1.4;
  color: @ds-white;
  border-top: 0.1rem solid @ds-white;
  box-sizing: border-box;

  @media @md {
    padding-bottom: 0;
  }

  @media print {
    display: none;
  }

  .info-bar {
    width: 100%;
    padding: 2rem 0;

    @media @md {
      padding: 4rem 0;
    }
  }

  .global-footer-container {
    border-bottom: 0.1rem solid @ds-white;
    box-sizing: border-box;
    width: 100%;
    max-width: 140rem;
    margin: 0 auto;
    padding: 0 2rem 2rem;
    display: flex;
    flex-direction: column;

    @media @md {
      flex-direction: row;
      text-align: left;
      padding-bottom: 3rem;
    }
  }

  *[class$="box"] {
    display: inline-block;
    vertical-align: top;
  }

  .address-box,
  .contact-box,
  .navigation-box {
    flex: 1;
    order: 1;
    width: 100%;
    text-align: center;
    margin: 1rem 0;

    @media @md {
      text-align: left;
    }
  }

  @media @md {
    .navigation-box {
      flex: 2;
      order: 2;
    }

    .contact-box {
      order: 3;
    }
  }

  .address-box {
    font-style: normal;

    .logo {
      margin-bottom: 0.2rem;

      .logo-img {
        max-width: 15rem;

        @media @sm {
          max-width: 21rem;
        }

        @media @md {
          max-width: 16rem;
          width: 100%;
        }

        @media screen and (-ms-high-contrast: active) and (max-width: 30em),(-ms-high-contrast: none) and (max-width: 30em) {
          max-width: 22.5rem;
          max-height: 3rem;
          width: auto;
          height: 100%;
        }
      }
    }

    p {
      margin: 0;
      font-size: 1.4rem;
      display: inline-block;

      @media @md {
        display: block;

        &::after {
          display: none;
        }
      }

      &:nth-of-type(1) {
        display: block;
      }

      &:nth-of-type(2) {
        &::after {
          content: ",";
          margin-right: 0.6rem;
        }
      }
    }
  }

  .navigation-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;

    a {
      flex: 1 1 50%;
      color: @ds-white;
      text-decoration: none;
      font-size: 1.4rem;
      padding: 0.4rem 0;
      display: inline-block;
      opacity: 1;
      transition: all 0.4s ease;

      @media @md {
        padding: 0;
      }

      &:hover {
        color: @ds-black;
        opacity: 0.5;
      }
    }


    &::after {
      content: " ";
      height: 0.1rem;
      width: 90%;
      display: block;
      margin: 2.6rem auto 0.8rem;
      background-color: @ds-white;
    }

    @media @md {
      &::after {
        display: none;
      }
    }
  }

  .contact-box {
    p {
      max-width: 100vw;
      display: block;
      margin: 0 auto;
      font-size: 1.4rem;

      @media @md {
        max-width: 20rem;
        margin: 0;
      }
    }

    a[role="button"] {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      cursor: pointer;
      margin: 1.5rem 0;
      background-color: @ds-white;
      height: 5rem;
      line-height: 4.9rem;
      padding: 0 2rem;
      color: @ds-black;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 0.5rem;
      display: inline-block;
      font-size: 1.4rem;
      white-space: nowrap;
    }
  }

  .global-footer-footer-theme-keno {
    .info-bar {
      background-color: #00a5eb;
    }
  }

  .compliance {
    max-width: 95rem;
    margin: 0 auto;
    padding: 1.5rem 2rem 0;
    text-align: center;

    @media @sm {
      padding: 3rem 2rem 1rem;
    }

    .compliance__title {
      font-size: 2.6rem;
      line-height: 1.3;
      font-weight: bold;
      max-width: 52rem;
      margin: 0 auto 0.6rem;
    }

    .compliance__subtitle {
      font-size: 1.6rem;
      max-width: 52rem;
      line-height: 1.4;
      font-weight: 400;
      margin: 0 auto 0.6rem;

      @media @sm {
        font-size: 2rem;
      }

      a {
        text-decoration: underline;
        color: @ds-white;
      }
    }

    .compliance__items {
      margin-top: 2.3rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .compliance__item {
        font-weight: 300;
        .compliance-item-bg();
        text-decoration: none;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        flex: 0 0 auto;
        width: 100%;
        min-height: 8rem;
        margin-bottom: 2rem;
        border-radius: 0.8rem;
        box-shadow: 0 0 1.6rem 0 rgba(0, 0, 0, 0.15);
        padding: 1rem 2rem;

        @media @xs {
          flex-direction: column;
          text-align: center;
          width: ~"calc(50% - 0.5rem)";
          margin-bottom: 1rem;
          padding: 3rem 1rem 2rem;
        }

        @media @sm {
          width: ~"calc(25% - 0.5rem)";
        }

        @media @lg {
          width: ~"calc(25% - 1rem)";
        }

        .compliance__item-logo {
          flex: 0 0 8rem;
          padding-right: 1rem;
          box-sizing: border-box;

          @media @xs {
            padding: 0;
            flex: 0 0 9rem;
          }

          img {
            height: auto;
            width: 6rem;

            @media @xs {
              height: 6rem;
              width: auto;
            }

            @media @lg {
              height: 7rem;
            }
          }
        }

        .compliance__item-text {
          font-size: 1.4rem;
          line-height: 1.3;
          color: @ds-white;
          text-decoration: none;
        }
      }
    }
  }

  .disclaimer {
    background: @ds-white;
    padding-top: 3rem;
  }

  .disclaimer__content {
    display: flex;
    justify-content: center;
    max-width: 35rem;
    margin: 0 auto;
  }

  .disclaimer-item {
    font-weight: 300;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0.5rem 3rem;
  }

  a.disclaimer-item {
    color: inherit;
  }

  .disclaimer-item--logo {
    img {
      height: 5rem;
    }
  }

  .disclaimer-item--clock {
    position: absolute;
    color: @ds-black;
    font-weight: bold;
    font-size: 1.2rem;
    width: 5rem;
    height: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.3rem solid @ds-black;
    border-radius: 50%;
  }

  .disclaimer-item--label {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.1rem;
    color: @ds-black;
    max-width: 100%;
  }
}

.compliance-item-bg() {
  background: @footer-compliance-item-bg--default;

  .region-allornothing &,
  .region-avalon-allornothing & {
    background: @footer-compliance-item-bg--altellerintet;
  }

  .region-avalon-bingo & {
    background: @footer-compliance-item-bg--bingo;
  }

  .region-frontpage &,
  .region-avalon-frontpage &,
  .region-avalon-corporate &,
  .region-kundecenter &,
  .region-avalon-kundecenter &,
  .region-avalon-danskespil &,
  .region-retail-account & {
    background: @footer-compliance-item-bg--ds;
  }

  .region-dantoto &,
  .region-avalon-dantoto & {
    background: @footer-compliance-item-bg--dantoto;
  }

  .region-eurojackpot &,
  .region-avalon-eurojackpot & {
    background: @footer-compliance-item-bg--eurojackpot;
  }

  .region-keno &,
  .region-avalon-keno & {
    background: @footer-compliance-item-bg--keno;
  }

  .region-avalon-livecasino & {
    background: @footer-compliance-item-bg--livecasino;
  }

  .region-avalon-lotto &,
  .region-lotto & {
    background: @footer-compliance-item-bg--lotto;
  }

  .region-oddset &,
  .region-avalon-oddset &,
  .region-oddsetbonus & {
    background: @footer-compliance-item-bg--oddset;
  }

  .region-playtogether &,
  .region-avalon-playtogether & {
    background: @footer-compliance-item-bg--spilsammen;
  }

  .region-poker & {
    background: @footer-compliance-item-bg--poker;
  }

  .region-avalon-klasselotteriet & {
    background: @footer-compliance-item-bg--klasselotteriet;
  }

  .region-avalon-quick & {
    background: @footer-compliance-item-bg--quick;
  }

  .region-spillehjoernet-gamelauncher &,
  .region-avalon-spillehjoernet &,
  .region-spillehjoernet & {
    background: @footer-compliance-item-bg--spillehjoernet;
  }

  .region-subscriptions & {
    background: @footer-compliance-item-bg--plus;
  }

  .region-tips &,
  .region-avalon-tips & {
    background: @footer-compliance-item-bg--tips;
  }

  .region-vikinglotto &,
  .region-avalon-vikinglotto & {
    background: @footer-compliance-item-bg--vikinglotto;
  }

  .region-casino & {
    background: @footer-compliance-item-bg--casino;
  }

  .region-avalon-ovb & {
    background: @footer-compliance-item-bg--virtuel;
  }
}
