@import url("./Mixins/_mixins.less");

.brand-nav {
  position: relative;
  transition: transform 200ms ease;
  overflow: visible;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  .user-bar {
    @media all and (min-width: @nav-medium) {
      display: none !important; //Due to login errors d.d. this is set to important. It will work perfectly, but there might be a more sane way to override TopNavigation-account.less styles
    }
  }

  .desktop-only {
    @media (max-width: (@nav-small - 1)) {
      display: none;
    }
  }

  .mobile-only {
    @media (min-width: @nav-medium) {
      display: none;
    }
  }

  &__container {
    white-space: nowrap;
    height: 6.6rem;

    @media all and (min-width: @nav-medium) {
      max-width: 1440px;
      margin: 0 auto;
      height: 7.6rem;
    }
    //Only exists on PAM pages
    .pam-return-to-site-close {
      width: 3rem;
      height: 3rem;
      margin-left: 1rem;
      border: 2px solid @ds-white;
      border-radius: 50%;
      position: absolute;
      right: 2rem;
      top: 2.2rem;
      cursor: pointer;
      display: none;

      &__icon {
        //svg
        width: 50%;
        height: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__logo {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(~'100% - 21rem');
    height: 6.5rem;
    margin: 0 10rem 0 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .is-logged-in & {
      width: calc(~'100% - 13rem');
    }

    @media all and (min-width: @nav-medium) {
      width: 17rem;
      margin: 0;
      height: 7.5rem;
      left: auto;
    }
    //Only exists on PAM pages
    .pam-return-to-site-back {
      display: none;
      cursor: pointer;
      .clear;

      &__brand {
        float: left;
        margin-left: 1.5rem;
        color: @ds-white;
        font-weight: 600;
        font-size: 1.6rem;
        transform: translateY(0.3rem);

        &:hover {
          text-decoration: underline;
        }
      }

      &__logo-img {
        display: none;
        max-height: 2rem;
        max-width: 13rem;
        width: auto;
        height: auto;

        @media all and (min-width: @nav-medium) {
          display: inline-block;
        }
      }

      .brand-nav__logo-text {
        display: block;
        font-size: 0.9rem;
        color: @ds-white;
        margin: -0.5rem 0 0.5rem;
        font-style: italic;
        font-weight: normal;
      }

      &__icon {
        width: 1.1rem;
        height: 1.1rem;
        float: left;
        position: relative;
        top: 1rem;
      }
    }

    a {
      text-decoration: none;
    }
  }

  &__logo-cube {
    width: 4vw;
    height: 4vw;
    display: inline-block;

    @media all and (min-width: @nav-medium) {
      display: none;
    }
  }

  &__logo-img {
    padding-left: 1rem;
    display: inline-block;
    margin: 0;
    max-height: 2.5rem;
    max-width: 17rem;
    height: 100%;


    @media(max-width: @nav-between-extra-small-and-small) {
      max-height: 1.7rem;
      max-width: calc(~'100% - 1.8rem');
    }

    @media all and (min-width: @nav-medium) {
      border: 0;
      margin: 0;
      text-align: left;
      padding-left: 0;
    }
  }

  &__logo-text {
    display: block;
    font-size: 0.8rem;
    color: @ds-white;
    margin: 0.5rem 0;
    font-style: italic;
  }

  &__navigation {
    margin-left: 180px;
    width: auto;
    height: 100%;
    transform: none;
    background-color: transparent;
    display: inline-block;
  }

  &__list {
    margin: 0;
    padding: 0;
    overflow: hidden;
    max-height: 100%;
    height: 100%;
  }

  &__item {
    font-size: 2.1rem;
    display: inline-block;
    list-style: none;
    float: left;

    &:not(.js-has-subnav) {
      .brand-nav__link:hover {
        opacity: 0.7;
      }
    }
    //Show the subnavigation
    &.has-shown-subnav {
      .sub-navigation {
        @media all and (min-width: @nav-medium) {
          transform: translateY(100%) scaleY(1);
          transition-timing-function: ease-out;
          z-index: 1;
        }
      }

      svg.brand-nav__link-icon {
        transform: rotate(.5turn);
      }
    }
  }

  &__link {
    padding: 2.9rem 1.8rem 2.8rem;
    font-weight: 600;
    font-size: 1.6rem;
    display: inline-block;
    border-bottom: 0;
    color: white;
    position: relative;
    text-decoration: none;
    transition: all 0.2s ease;

    &:hover {
      text-decoration: none;
    }

    svg.brand-nav__link-icon {
      fill: white;
      height: 1.1rem;
      width: 1.1rem;
      margin-left: .6rem;
      display: none;
      transition: transform 150ms ease-out;

      @media all and (min-width: @nav-medium) {
        display: inline-block;
      }
    }

    .js-has-subnav & {
      // Navigation active triangel
      &:after {
        @media all and (min-width: @nav-medium) {
          height: 0;
          width: 0;
          content: ' ';
          display: block;
          border-left: 0.8rem solid transparent;
          border-right: 0.8rem solid transparent;
          border-bottom: 0.8rem solid transparent;
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -0.8rem;
          transform: translateY(1000%);
          z-index: 0;
          transition: transform 200ms ease;
        }
      }
    }

    .has-shown-subnav & {
      &:after {
        transform: translateY(0) rotate(225deg);

        @media all and (min-width: @nav-small) and (max-width: (@nav-medium - 1)) {
          transform: translateY(0.25rem) rotate(225deg);
        }

        @media all and (min-width: @nav-medium) {
          transform: translateY(0);
          border-bottom-color: @ds-white;
        }
      }
    }
  }

  .sub-navigation {

    @media all and (min-width: @nav-medium) {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      min-width: @nav-medium;
      background-color: @ds-white;
      transform-origin: top;
      transform: translateY(100%) scaleY(0);
      max-height: none !important;
      transition: all 200ms ease;
      z-index: 0;
    }

    &__inner {
      max-width: @nav-large;
      margin: 0 auto;
      padding-left: 17rem;
    }

    &__list {
      padding: 0;
      display: inline-block;
    }

    &__item {
      font-size: 1.6rem;
      display: inline-block;
      list-style: none;
      float: left;
    }

    &__link {
      color: @ds-black;
      text-decoration: none;
      font-weight: 600;
      display: inline-block;

      @media all and (min-width: @nav-medium) {
        padding: 1.9rem 1.8rem 1.1rem;
        min-height: 0;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

// hide brand menu on register pages for web
@media all and (min-width: @nav-medium) {
  .region-player-account-management.Register,
  .region-player-account-management.ChangePasswordPage,
  .region-retail-account.page-retail-upgrade {
    .brand-nav {
      display: none;
    }
  }
}

