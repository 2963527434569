.ds-navigation-side-menu {
  list-style-type: none;
  width: 100%;
  margin-bottom: 3rem;
}
.ds-navigation-side-menu a:focus {
  outline: none;
}
.ds-navigation-side-menu a:focus-visible,
.ds-navigation-side-menu *:focus-visible {
  outline: #000000 dotted thin;
  outline-offset: 0.2rem;
}
.ds-navigation-side-menu a:active,
.ds-navigation-side-menu *:active {
  outline: none;
}
.ds-navigation-side-menu ul {
  list-style-type: none;
}
.ds-navigation-side-menu li {
  border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-rows: 5.4rem;
  align-items: center;
}
.ds-navigation-side-menu li:last-child {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}
.ds-navigation-side-menu img {
  position: absolute;
  left: 0;
  width: 2rem;
  height: 2rem;
}
.ds-navigation-side-menu svg {
  stroke: #000000;
  width: 2.4rem;
  height: 2.4rem;
  transition: transform 340ms var(--bezierEaseInOutQuad);
}
.ds-navigation-side-menu a,
.ds-navigation-side-menu button {
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.3;
  color: #000000;
  border: none;
  background: none;
  backface-visibility: hidden;
  /* to keep text from being blurred */
}
.ds-navigation-side-menu a:focus-visible,
.ds-navigation-side-menu button:focus-visible {
  border: none;
}
.ds-navigation-side-menu a:hover,
.ds-navigation-side-menu button:hover {
  text-decoration: none;
}
.ds-navigation-side-menu--has-icons a,
.ds-navigation-side-menu--has-icons button {
  padding-left: 3rem;
}
.ds-navigation-side-menu li.active > a,
.ds-navigation-side-menu li.active > button {
  font-weight: bold;
}
.ds-navigation-side-menu--white-theme a,
.ds-navigation-side-menu--white-theme button {
  color: white;
}
.ds-navigation-side-menu--white-theme li {
  border-color: rgba(255, 255, 255, 0.2);
}
.ds-navigation-side-menu--white-theme li:last-child {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.2);
}
.ds-navigation-side-menu--white-theme--sub-menu li {
  border-color: rgba(255, 255, 255, 0.2);
}
.ds-navigation-side-menu--white-theme svg {
  stroke: white;
}
.ds-navigation-side-menu--large li {
  grid-template-rows: 6rem;
}
.ds-navigation-side-menu--large a,
.ds-navigation-side-menu--large button {
  font-size: 1.8rem;
}
.ds-navigation-side-menu--sub-menu li {
  border-top: none;
  grid-template-rows: 3.4rem;
}
.ds-navigation-side-menu--sub-menu li:last-child {
  border-bottom: unset;
}
.ds-navigation-side-menu--sub-menu a,
.ds-navigation-side-menu--sub-menu button {
  font-size: 1.4rem;
}
.ds-navigation-side-menu--sub-menu svg {
  width: 1.8rem;
  height: 1.8rem;
}
.ds-navigation-side-menu__sub-items ul {
  margin-top: 0;
  margin-bottom: 0;
  transition: margin 340ms var(--bezierEaseInOutQuad);
}
.ds-navigation-side-menu__sub-items li {
  grid-template-rows: 3.4rem;
  border-top: none;
}
.ds-navigation-side-menu__sub-items li:last-child {
  border-bottom: none;
}
.ds-navigation-side-menu__sub-items a,
.ds-navigation-side-menu__sub-items button {
  font-size: 1.4rem;
  padding-left: 2rem;
}
.ds-navigation-side-menu--large .ds-navigation-side-menu__sub-items li {
  grid-template-rows: 4rem;
}
.ds-navigation-side-menu--large .ds-navigation-side-menu__sub-items a,
.ds-navigation-side-menu--large .ds-navigation-side-menu__sub-items button {
  font-size: 1.6rem;
  padding-left: 0;
}
.ds-navigation-side-menu--has-icons .ds-navigation-side-menu__sub-items a,
.ds-navigation-side-menu--has-icons .ds-navigation-side-menu__sub-items button {
  padding-left: 3rem;
}
.ds-navigation-side-menu__sub-sub-items a,
.ds-navigation-side-menu__sub-sub-items button {
  padding-left: 3.5rem;
}
.ds-navigation-side-menu--has-icons .ds-navigation-side-menu__sub-sub-items a,
.ds-navigation-side-menu--has-icons .ds-navigation-side-menu__sub-sub-items button {
  padding-left: 4.5rem;
}
.ds-navigation-side-menu--large .ds-navigation-side-menu__sub-sub-items a,
.ds-navigation-side-menu--large .ds-navigation-side-menu__sub-sub-items button {
  font-size: 1.4rem;
}
.ds-navigation-side-menu--large .ds-navigation-side-menu__sub-sub-items li {
  grid-template-rows: 3.7rem;
}
.ds-navigation-side-menu__sub-items,
.ds-navigation-side-menu__sub-sub-items {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 340ms var(--bezierEaseInOutQuad);
}
.ds-navigation-side-menu__sub-items > ul,
.ds-navigation-side-menu__sub-sub-items > ul {
  overflow: hidden;
}
.ds-navigation-side-menu > li.open > button svg {
  transform: rotateX(0.5turn);
}
.ds-navigation-side-menu > li.open .ds-navigation-side-menu__sub-items {
  grid-template-rows: 1fr;
}
.ds-navigation-side-menu > li.open .ds-navigation-side-menu__sub-items > ul {
  margin-bottom: 2rem;
}
.ds-navigation-side-menu--large > li.open .ds-navigation-side-menu__sub-items > ul {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
}
.ds-navigation-side-menu__sub-items > ul > li.open > button svg {
  transform: rotateX(0.5turn);
}
.ds-navigation-side-menu__sub-items > ul > li.open .ds-navigation-side-menu__sub-sub-items {
  grid-template-rows: 1fr;
}
.ds-navigation-side-menu__sub-items > ul > li.open .ds-navigation-side-menu__sub-sub-items > ul {
  margin-bottom: 1rem;
}
.ds-navigation-side-menu--large .ds-navigation-side-menu__sub-items .ds-navigation-side-menu__sub-sub-items > ul {
  margin-left: 2rem;
}
.ds-navigation-side-menu--large .ds-navigation-side-menu__sub-items li.open .ds-navigation-side-menu__sub-sub-items > ul {
  margin-top: 1.5rem;
}
