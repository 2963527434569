.responsible-gaming__top {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navigation-compliance-bar-height, 0rem);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  background: white;
  border-radius: 0 0 2rem 2rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 0.1rem 0.3rem 0;
  color: #000000;
}
.responsible-gaming__top a:focus {
  outline: none;
}
.responsible-gaming__top a:focus-visible,
.responsible-gaming__top *:focus-visible {
  outline: #000000 dotted thin;
  outline-offset: 0.2rem;
}
.responsible-gaming__top a:active,
.responsible-gaming__top *:active {
  outline: none;
}
.responsible-gaming__top.hide {
  display: none;
}
body:has(.ds-navigation.hide) .responsible-gaming__top,
body:not(:has(.ds-navigation)) .responsible-gaming__top {
  border-radius: 0;
}
@media (orientation: landscape) {
  body.is-mobile-detected:has(.ds-navigation.hide-on-mobile-landscape) .responsible-gaming__top {
    border-radius: 0;
  }
}
.responsible-gaming__top-icon {
  padding: 0 0.8rem;
  height: 100%;
  display: grid;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .responsible-gaming__top-icon {
    padding: 0 0.8rem 0 1.5rem;
  }
}
.responsible-gaming__top-icon img {
  width: 2rem;
  height: 2rem;
}
.responsible-gaming__top-clock {
  line-height: 1;
  font-weight: normal;
}
.responsible-gaming__top-toggle {
  border: none;
  display: grid;
  gap: 0.8rem;
  align-items: center;
  grid-template-columns: 1fr auto;
  height: 100%;
  padding: 0.1rem 0.8rem 0;
  background: none;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1;
  color: currentColor;
}
@media screen and (min-width: 768px) {
  .responsible-gaming__top-toggle {
    padding: 0.1rem 1.5rem 0;
  }
}
.responsible-gaming__top-toggle-icon {
  width: 2rem;
  height: 2rem;
  background: #d4d4d4;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.responsible-gaming__top-toggle-icon img,
.responsible-gaming__top-toggle-icon svg {
  color: currentColor;
  width: 1.6rem;
  height: 1.6rem;
}
.responsible-gaming__top-link {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  padding: 0 1rem;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1216px) {
  .responsible-gaming__top-link {
    display: flex;
  }
}
.responsible-gaming__top-link img {
  height: 2rem;
}
.responsible-gaming__menu {
  text-wrap: pretty;
  position: fixed;
  z-index: 130;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  transform: translateX(40rem);
  transition: transform 250ms;
  will-change: transform;
  background: white;
  max-width: 40rem;
}
.responsible-gaming__menu a:focus {
  outline: none;
}
.responsible-gaming__menu a:focus-visible,
.responsible-gaming__menu *:focus-visible {
  outline: #000000 dotted thin;
  outline-offset: 0.2rem;
}
.responsible-gaming__menu a:active,
.responsible-gaming__menu *:active {
  outline: none;
}
.responsible-gaming__menu a:active,
.responsible-gaming__menu button:active {
  transform: translateY(0.1rem);
}
.responsible-gaming__menu.open {
  transition-delay: 100ms;
  transform: translateX(0);
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu {
    transform: translateX(102.4rem);
    max-width: 102.4rem;
  }
}
.responsible-gaming__menu-close {
  position: absolute;
  z-index: 1;
  top: 2rem;
  right: 2rem;
  width: 3.6rem;
  height: 3.6rem;
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.responsible-gaming__menu-close svg {
  color: currentColor;
  stroke: white;
  width: 2.4rem;
  height: 2.4rem;
}
.responsible-gaming__menu-inner {
  position: absolute;
  inset: 0;
  padding: 3.8rem 2.7rem 0;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-inner {
    padding: 8.2rem 7.3rem 0 7.3rem;
  }
}
.responsible-gaming__menu-title {
  font-family: DanskeSpil, serif;
  font-size: 4.4rem;
  line-height: 1.15;
  color: #004b32;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin-bottom: 0.2rem;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-title {
    font-size: 5.6rem;
    margin-bottom: 0.8rem;
  }
}
.responsible-gaming__menu-subtitle {
  font-size: 1.4rem;
  line-height: 1.57;
  color: #004b32;
  margin-bottom: 2.4rem;
  max-width: 80ch;
}
.responsible-gaming__menu-read-more {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: white;
  height: 4.2rem;
  border-radius: 10rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  background-color: #003a26;
  border: none;
  padding: 0 3.8rem;
  margin-bottom: 5rem;
}
.responsible-gaming__menu-read-more:hover {
  text-decoration: none;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-read-more {
    margin-bottom: 4.3rem;
  }
}
.responsible-gaming__menu-lists {
  display: grid;
  gap: 4.8rem;
  grid-template-columns: 1fr;
  margin-bottom: 4.5rem;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-lists {
    grid-template-columns: 1fr 1fr;
    gap: 7rem;
  }
}
.responsible-gaming__menu-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 3rem;
  align-self: start;
}
.responsible-gaming__menu-bullet {
  display: grid;
  grid-template-columns: 2.4rem 1fr;
  column-gap: 1.6rem;
  color: #004b32;
}
.responsible-gaming__menu-bullet-icon {
  width: 2.4rem;
  height: 2.4rem;
}
.responsible-gaming__menu-bullet-title {
  grid-column-start: 2;
  font-family: DanskeSpil, serif;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1.333;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.responsible-gaming__menu-bullet-title a {
  color: currentColor;
}
.responsible-gaming__menu-bullet-title svg {
  width: 1.6rem;
  height: 1.6rem;
  margin-bottom: 0.2rem;
}
.responsible-gaming__menu-bullet-text {
  color: #004b32;
  grid-column-start: 2;
  font-size: 1.2rem;
  line-height: 1.666;
}
.responsible-gaming__menu-bullet-text a {
  color: currentColor;
  text-decoration: underline;
}
.responsible-gaming__menu-infos {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 0.8rem;
  overflow: hidden;
  align-self: start;
}
.responsible-gaming__menu-info {
  position: relative;
  background: #eeeeee;
  font-size: 1.6rem;
  line-height: 1.5;
}
.responsible-gaming__menu-info:not(:last-child)::after {
  content: "";
  position: absolute;
  inset: 0;
  top: auto;
  bottom: 0.2rem;
  height: 0.1rem;
  background: rgba(0, 58, 39, 0.2);
}
.responsible-gaming__menu-info input[type="checkbox"] {
  display: none;
}
.responsible-gaming__menu-info label {
  cursor: pointer;
  padding: 1rem 5rem 1rem 2.7rem;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 7.2rem;
  font-family: DanskeSpil, serif;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.4;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-info label {
    min-height: 6.4rem;
    font-size: 1.8rem;
  }
}
.responsible-gaming__menu-info label svg {
  position: absolute;
  right: 2.7rem;
  width: 2rem;
  height: 2rem;
  transform: rotate(-45deg);
  transition: transform 340ms ease-out;
}
.responsible-gaming__menu-info > div {
  overflow: hidden;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 340ms ease-out;
}
.responsible-gaming__menu-info > div > div {
  transition: padding 340ms ease-out;
  padding: 0 2.7rem;
  overflow: hidden;
}
.responsible-gaming__menu-info a {
  color: currentColor;
  text-decoration: underline;
}
.responsible-gaming__menu-info input[type="checkbox"]:checked ~ label svg {
  transform: rotate(0);
}
.responsible-gaming__menu-info input[type="checkbox"]:checked ~ div {
  grid-template-rows: 1fr;
}
.responsible-gaming__menu-info input[type="checkbox"]:checked ~ div > div {
  padding-bottom: 2.5rem;
}
ul.responsible-gaming__menu-links {
  list-style-type: none;
  padding: 0;
  margin-bottom: 4.8rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
@media screen and (min-width: 1024px) {
  ul.responsible-gaming__menu-links {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 6.5rem;
  }
}
.responsible-gaming__menu-link {
  background: #d4d4d4;
  border-radius: 1rem;
  transition: background 0.2s ease-in;
}
.responsible-gaming__menu-link:hover {
  background: #eeeeee;
}
.responsible-gaming__menu-link a {
  font-family: DanskeSpil, serif;
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: -0.02em;
  font-weight: 600;
  min-height: 13.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.2rem;
  color: #004b32;
}
.responsible-gaming__menu-link a:hover {
  text-decoration: none;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-link a {
    min-height: 13rem;
    font-size: 2.4rem;
    gap: 1rem;
  }
}
.responsible-gaming__menu-link a svg {
  width: 3.2rem;
  height: 3.2rem;
}
.responsible-gaming__menu-footer {
  padding: 4.8rem 2.7rem;
  margin: 0 -2.7rem;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-footer {
    padding: 6.5rem 7.3rem;
    margin: 0 -7.3rem;
  }
}
.responsible-gaming__menu-sticker {
  color: #004b32;
  border-radius: 0.8rem;
  background: #FFE299;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 3rem;
  margin-bottom: 4.8rem;
}
.responsible-gaming__menu-sticker:hover {
  text-decoration: none;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-sticker {
    width: 64rem;
    padding: 4.4rem;
    margin-bottom: 3.6rem;
  }
}
.responsible-gaming__menu-sticker-label {
  position: relative;
  font-family: DanskeSpil, serif;
  font-size: 1.2rem;
  letter-spacing: -0.02em;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 3rem;
}
.responsible-gaming__menu-sticker-label::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 5rem;
  height: 0.1rem;
  background: #FEB700;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-sticker-label {
    margin-bottom: 2rem;
  }
}
.responsible-gaming__menu-sticker-text {
  font-family: DanskeSpil, serif;
  font-size: 2.4rem;
  letter-spacing: -0.02em;
  line-height: 1.2;
  font-weight: 600;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-sticker-text {
    font-size: 4rem;
    letter-spacing: -0.03em;
    max-width: 18ch;
    line-height: 1.15;
  }
}
.responsible-gaming__menu-sticker-cta {
  pointer-events: none;
  display: none;
  height: 5.6rem;
  border-radius: 10rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #003a26;
  border: 0.1rem solid rgba(0, 58, 39, 0.4);
  transition: border-color 0.2s ease-in;
  padding: 0 4rem;
  justify-content: center;
  align-items: center;
  margin: 2rem auto 0;
}
.responsible-gaming__menu-sticker:hover .responsible-gaming__menu-sticker-cta {
  border-color: #003a26;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-sticker-cta {
    display: flex;
  }
}
.responsible-gaming__menu-footer-links {
  list-style-type: none;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 1rem;
}
@media screen and (min-width: 1024px) {
  .responsible-gaming__menu-footer-links {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0;
  }
}
.responsible-gaming__menu-footer-link a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 0.8rem;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #004b32;
}
.responsible-gaming__menu-footer-link a img {
  height: 4.4rem;
  margin: 0 auto;
}
