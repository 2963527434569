@import "TextMixins.less";
@import "BoxMixins.less";


// ====== NEW BRAND BUTTONS ================
// These buttons is used in the Radeditor. Apply CSS class to link.

.cm-brand-buttons-style-one(@height: 30px, @minWidth: 100px, @bgColor: rgb(128,169,28), @fontSize: 1.5) {
  display: table-cell;
  height: @height;
  min-width: @minWidth;
  padding: 0 25px 5px;
  background-color: @bgColor;
  .cm-corners-all(3px);
  .cm-font-ds-bold();
  .cm-font-size(@fontSize);
  color: rgb(255,255,255);
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  &:hover {
    background-color: @bgColor + 15%;
  }

}

.cm-brand-buttons-style-two(@height: 34px, @minWidth: 100px, @fontSize: 1.3, @baseColor: rgb(254,183,0), @highlightColor: rgb(254,205,64)) {
  display: table-cell;
  height: @height;
  min-width: @minWidth;
  padding: 0 12px 5px;
  background: @baseColor;
  background: @baseColor radial-gradient(circle at 28% top, @highlightColor, @baseColor) no-repeat;
  .cm-corners-all(4px);
  .cm-font-ds-black-italic();
  .cm-font-size(@fontSize);
  color: rgb(255,255,255);
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;

  &:hover {
    background: @baseColor + 15% radial-gradient(circle at 28% top, @highlightColor + 15%, @baseColor + 15%) no-repeat;
  }

}

.cm-brand-buttons-style-three(@height: 36px, @minWidth: 100px, @fontSize: 1.6, @topColor: rgba(224,118,51,1), @bottomColor: rgba(188,82,15,1)) {
  display: table-cell;
  height: @height;
  min-width: @minWidth;
  padding: 0 12px 5px;
  background: @topColor;
  background: linear-gradient(to top, @bottomColor, @topColor);
  .cm-corners-all(4px);
  .cm-font-ds-book();
  .cm-font-size(@fontSize);
  color: rgb(255,255,255);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  &:hover {
    background: linear-gradient(to top, @bottomColor + 15%, @topColor + 15%);
  }

}


.cm-brand-buttons() {

  // style one
  .cm-bingo-button() {
    .cm-brand-buttons-style-one(@bgColor: rgb(128,169,28));
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.40), 0 0 0 1px rgb(96,127,11), inset 0 1px 0 0 rgba(160,211,35,0.80);
    text-shadow: 0 1px 0 rgb(96,127,21);
  }
  .cm-bingo-button-large() {
    .cm-brand-buttons-style-one(@height: 60px, @minWidth: 220px, @bgColor: rgb(128,169,28), @fontSize: 2);
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.40), 0 0 0 1px rgb(96,127,11), inset 0 1px 0 0 rgba(160,211,35,0.80);
    text-shadow: 0 1px 0 rgb(96,127,21);
  }
  &.bingo-button {
    .cm-bingo-button();
  }
  &.bingo-button-large {
    .cm-bingo-button-large();
  }

  // casino
  .cm-casino-button() {
    .cm-brand-buttons-style-one(@bgColor: rgb(101,32,68));
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.40), 0 0 0 1px rgb(67,13,41), inset 0 1px 0 0 rgba(151,48,102,0.80);
    text-shadow: 0 1px 0 rgb(124,100,59);
  }
  .cm-casino-button-large() {
    .cm-brand-buttons-style-one(@height: 60px, @minWidth: 220px, @bgColor: rgb(101,32,68), @fontSize: 2);
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.40), 0 0 0 1px rgb(67,13,41), inset 0 1px 0 0 rgba(151,48,102,0.80);
    text-shadow: 0 1px 0 rgb(124,100,59);
  }
  &.casino-button {
    .cm-casino-button();
  }
  &.casino-button-large {
    .cm-casino-button-large();
  }

  // dantoto
  .cm-dantoto-button() {
    .cm-brand-buttons-style-one(@bgColor: rgb(71,171,102));
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.40), 0 0 0 1px rgb(31,122,68), inset 0 1px 0 0 rgba(92,200,125,0.80);
    text-shadow: 0 1px 0 rgb(31,122,68);
  }
  .cm-dantoto-button-large() {
    .cm-brand-buttons-style-one(@height: 60px, @minWidth: 220px, @bgColor: rgb(71,171,102), @fontSize: 2);
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.40), 0 0 0 1px rgb(31,122,68), inset 0 1px 0 0 rgba(92,200,125,0.80);
    text-shadow: 0 1px 0 rgb(31,122,68);
  }
  &.dantoto-button {
    .cm-dantoto-button();
  }
  &.dantoto-button-large {
    .cm-dantoto-button-large()
  }

  // quick
  .cm-quick-button() {
    .cm-brand-buttons-style-one(@bgColor: rgb(127,68,136));
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.40), 0 0 0 1px rgb(94,39,103), inset 0 1px 0 0 rgba(166,124,172,0.80);
    text-shadow: 0 1px 0 rgb(127,68,136);
  }
  .cm-quick-button-large() {
    .cm-brand-buttons-style-one(@height: 60px, @minWidth: 220px, @bgColor: rgb(127,68,136), @fontSize: 2);
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.40), 0 0 0 1px rgb(94,39,103), inset 0 1px 0 0 rgba(166,124,172,0.80);
    text-shadow: 0 1px 0 rgb(127,68,136);
  }
  &.quick-button {
    .cm-quick-button();
  }
  &.quick-button-large {
    .cm-quick-button-large();
  }

  // cross-sales
  .cm-cross-sales-button() {
    .cm-brand-buttons-style-one(@bgColor: rgb(254,183,0));
    color: rgb(0,75,50);
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.40), 0 0 0 1px rgb(94,39,103), inset 0 1px 0 0 rgba(255,255,255,0.50);
    text-shadow: 0 -1px 0 rgba(255,255,255,0.50);
  }
  .cm-cross-sales-button-large() {
    .cm-brand-buttons-style-one(@height: 60px, @minWidth: 220px, @bgColor: rgb(254,183,0), @fontSize: 2);
    color: rgb(0,75,50);
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.40), 0 0 0 1px rgb(94,39,103), inset 0 1px 0 0 rgba(255,255,255,0.50);
    text-shadow: 0 -1px 0 rgba(255,255,255,0.50);
  }
  &.cross-sales-button {
    .cm-cross-sales-button();
  }
  &.cross-sales-button-large {
    .cm-cross-sales-button-large();
  }

  // Style two
  .cm-eurojackpot-button() {
    .cm-brand-buttons-style-two(@fontSize: 1.4, @baseColor: rgb(0,0,0), @highlightColor: rgb(50,50,50));
    padding-bottom: 0px;
    .cm-font-anton();
    color: rgb(254,193,21);
  }
  .cm-eurojackpot-button-large() {
    .cm-brand-buttons-style-two(@height: 69px, @minWidth: 200px, @fontSize: 2, @baseColor: rgb(0,0,0), @highlightColor: rgb(50,50,50));
    padding-bottom: 0px;
    .cm-font-anton();
    color: rgb(254,193,21);
  }
  &.eurojackpot-button {
    .cm-eurojackpot-button();
  }
  &.eurojackpot-button-large {
    .cm-eurojackpot-button-large();
  }

  // lotto
  .cm-lotto-button() {
    .cm-brand-buttons-style-two(@baseColor: rgb(254,183,0), @highlightColor: rgb(254,205,64));
    box-shadow: 0 1px 0 rgba(223,161,0,1.00);
    text-shadow: 0 1px 1px rgba(223,161,0,0.80);
  }
  .cm-lotto-button-large() {
    .cm-brand-buttons-style-two(@height: 69px, @minWidth: 200px, @fontSize: 1.7, @baseColor: rgb(254,183,0), @highlightColor: rgb(254,205,64));
    box-shadow: 0 1px 0 rgba(223,161,0,1.00);
    text-shadow: 0 1px 1px rgba(223,161,0,0.80);
  }
  &.lotto-button {
    .cm-lotto-button();
  }
  &.lotto-button-large {
    .cm-lotto-button-large();
  }

  // Style three
  .cm-danskespil-button() {
    .cm-brand-buttons-style-three(@fontSize: 1.4, @topColor: rgba(255,196,37,1), @bottomColor: rgba(254,183,0,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.50), inset 0 0 0 1px rgba(255,255,255,0.20);
    .cm-font-ds-black();
    text-transform: uppercase;
    text-shadow: 0 1px 1px rgba(0,0,0,0.30);
  }
  .cm-danskespil-button-large() {
    .cm-brand-buttons-style-three(@height: 60px, @minWidth: 220px, @fontSize: 2, @topColor: rgba(255,196,37,1), @bottomColor: rgba(254,183,0,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.50), inset 0 0 0 1px rgba(255,255,255,0.20);
    .cm-font-ds-black();
    text-transform: uppercase;
    text-shadow: 0 1px 2px rgba(0,0,0,0.30);
  }
  &.danskespil-button {
    .cm-danskespil-button();
  }
  &.danskespil-button-large {
    .cm-danskespil-button-large();
  }

  // oddset
  .cm-oddset-button() {
    .cm-brand-buttons-style-three(@fontSize: 1.5, @topColor: rgba(0,62,153,1), @bottomColor: rgba(0,47,113,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.75), inset 0 0 0 1px rgba(255,255,255,0.20);
    .cm-font-ds-bold();
  }
  .cm-oddset-button-large() {
    .cm-brand-buttons-style-three(@height: 60px, @minWidth: 220px, @fontSize: 2, @topColor: rgba(0,62,153,1), @bottomColor: rgba(0,47,113,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.75), inset 0 0 0 1px rgba(255,255,255,0.20);
    .cm-font-ds-bold();
  }
  &.oddset-button {
    .cm-oddset-button();
  }
  &.oddset-button-large {
    .cm-oddset-button-large();
  }

  .cm-oddset-button-orange() {
    .cm-brand-buttons-style-three(@fontSize: 1.5, @topColor: rgba(220,95,17,1), @bottomColor: rgba(174,74,12,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.75), inset 0 0 0 1px rgba(255,255,255,0.20);
    .cm-font-ds-bold();
    text-shadow: 0 1px 1px rgba(156,60,0,0.50);
  }
  .cm-oddset-button-orange-large() {
    .cm-brand-buttons-style-three(@height: 60px, @minWidth: 220px, @fontSize: 2, @topColor: rgba(220,95,17,1), @bottomColor: rgba(174,74,12,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.75), inset 0 0 0 1px rgba(255,255,255,0.20);
    .cm-font-ds-bold();
    text-shadow: 0 1px 1px rgba(156,60,0,0.50);
  }
  &.oddset-button-orange {
    .cm-oddset-button-orange();
  }
  &.oddset-button-large-orange {
    .cm-oddset-button-orange-large();
  }

  // poker
  .cm-poker-button() {
    .cm-brand-buttons-style-three(@fontSize: 1.5, @topColor: rgba(254,184,4,1), @bottomColor: rgba(217,160,14,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.50), inset 0 0 0 1px rgba(255,255,255,0.20);
    .cm-font-ds-black();
    color: rgb(0,0,0);
  }
  .cm-poker-button-large() {
    .cm-brand-buttons-style-three(@height: 60px, @minWidth: 220px, @fontSize: 2, @topColor: rgba(254,184,4,1), @bottomColor: rgba(217,160,14,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.50), inset 0 0 0 1px rgba(255,255,255,0.20);
    .cm-font-ds-black();
    color: rgb(0,0,0);
  }
  &.poker-button {
    .cm-poker-button();
  }
  &.poker-button-large {
    .cm-poker-button-large();
  }

  // tips
  .cm-tips-button() {
    .cm-brand-buttons-style-two(@baseColor: #00985f, @highlightColor: #56ba75);
    box-shadow: 0 1px 0 rgba(0,0,0,0.30);
    text-shadow: 0 1px 1px rgba(0,0,0,0.30);
  }
  .cm-tips-button-large() {
    .cm-brand-buttons-style-two(@height: 69px, @minWidth: 200px, @fontSize: 1.7, @baseColor: #00985f, @highlightColor: #56ba75);
    box-shadow: 0 1px 0 rgba(0,0,0,0.30);
    text-shadow: 0 1px 1px rgba(0,0,0,0.30);
  }
  /*  .cm-tips-button() {
    .cm-brand-buttons-style-three(@topColor: rgba(224,118,51,1), @bottomColor: rgba(188,82,15,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.75), inset 0 0 0 1px rgba(255,255,255,0.20);
    text-shadow: 0 1px 1px rgba(156,60,0,0.50);
  }
  .cm-tips-button-large() {
    .cm-brand-buttons-style-three(@height: 60px, @minWidth: 220px, @fontSize: 2, @topColor: rgba(224,118,51,1), @bottomColor: rgba(188,82,15,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.75), inset 0 0 0 1px rgba(255,255,255,0.20);
    text-shadow: 0 1px 1px rgba(156,60,0,0.50);
  }*/
  &.tips-button {
    .cm-tips-button();
  }
  &.tips-button-large {
    .cm-tips-button-large();
  }

  // spillehjoernet
  .cm-spillehjoernet-button() {
    .cm-brand-buttons-style-three(@fontSize: 1.5, @topColor: #d5549d, @bottomColor: #702765);
    //background: linear-gradient(#d5549d, #702765);
    box-shadow: 0 1px 2px rgba(0,0,0,0.50), inset 0 0 0 1px rgba(255,255,255,0.20);
    .cm-font-ds-black();
    text-shadow: 0 1px 1px rgba(0,0,0,0.75);
  }
  .cm-spillehjoernet-button-large() {
    .cm-brand-buttons-style-three(@height: 60px, @minWidth: 220px, @fontSize: 2, @topColor: #d5549d, @bottomColor: #702765);
    //background: linear-gradient(#d5549d, #702765);
    box-shadow: 0 1px 2px rgba(0,0,0,0.50), inset 0 0 0 1px rgba(255,255,255,0.20);
    .cm-font-ds-black();
    text-shadow: 0 1px 1px rgba(0,0,0,0.75);
  }
  &.spillehjoernet-button {
    .cm-spillehjoernet-button();
  }
  &.spillehjoernet-button-large {
    .cm-spillehjoernet-button-large();
  }


}

.cm-brand-button-classes() {
  
  .keno-cta-button {
    z-index: auto;
  }
  .bingo-button {
    z-index: auto;
  }

  .bingo-button-large {
    z-index: auto;
  }

  .casino-button {
    z-index: auto;
  }

  .casino-button-large {
    z-index: auto;
  }

  .cross-sales-button {
    z-index: auto;
  }

  .cross-sales-button-large {
    z-index: auto;
  }

  .danskespil-button {
    z-index: auto;
  }

  .danskespil-button-large {
    z-index: auto;
  }

  .dantoto-button {
    z-index: auto;
  }

  .dantoto-button-large {
    z-index: auto;
  }

  .eurojackpot-button {
    z-index: auto;
  }

  .eurojackpot-button-large {
    z-index: auto;
  }

  .lotto-button {
    z-index: auto;
  }

  .lotto-button-large {
    z-index: auto;
  }

  .oddset-button {
    z-index: auto;
  }

  .oddset-button-large {
    z-index: auto;
  }

  .oddset-button-orange {
    z-index: auto;
  }

  .oddset-button-large-orange {
    z-index: auto;
  }

  .poker-button {
    z-index: auto;
  }

  .poker-button-large {
    z-index: auto;
  }

  .quick-button {
    z-index: auto;
  }

  .quick-button-large {
    z-index: auto;
  }

  .tips-button {
    z-index: auto;
  }

  .tips-button-large {
    z-index: auto;
  }

  .spillehjoernet-button {
    z-index: auto;
  }

  .spillehjoernet-button-large {
    z-index: auto;
  }
}



// ====== OBSOLETE BUTTONS - DO NOT USE ================
.cm-button-box(@font-size: 1.5, @height: 40px, @padding-top: 11px, @min-width: 35%) {
  display: block;
  min-width: @min-width;
  height: @height;
  padding-top: @padding-top;

  @media screen and (-moz-images-in-menus:0) {
    padding-top: @padding-top;
  }

  .cm-font(@font-size, @font-size);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
}


.cm-button-box-inline(@font-size: 1.5, @height: 40px, @padding-top: 11px, @padding: 20px, @min-width: 35%) {
  .cm-button-box(@font-size, @height, @padding-top, @min-width);
  display: inline-block;
  width: auto;
  padding-right: @padding;
  padding-left: @padding;
}

.cm-button-shadow() {
  box-shadow: @rgba-black-040 0 3px 2px;
}

.cm-button-color() {
  .corporate-yellow() {
    .cm-corners-all(3px);
    border-top: 1px solid rgb(255,219,128);
    background-color: rgb(254,183,0);
    box-shadow: rgba(0,0,0,0.40) 0 2px 2px, rgb(94,39,103) 0 0 0 1px;
    .cm-font-ds-bold();
    color: rgb(0,75,50);
    text-shadow: 0 -1px 0 rgba(255,255,255,0.50);

    &:hover {
      border-top-color: rgb(255,226,151);
      background-color: rgb(255,197,46);
      color: rgb(0,75,50);
    }
  }

  .bingo-green() {
    .cm-corners-all(3px);
    border-top: 1px solid rgb(154,203,34);
    background-color: rgb(128,169,28);
    box-shadow: rgba(0,0,0,0.40) 0 3px 2px, rgb(96,127,21) 0 0 0 1px;
    .cm-font-ds-bold();
    color: rgb(255,255,255);
    text-shadow: 0 1px 0 rgba(96,127,21,1.00);

    &:hover {
      border-top-color: rgb(158,208,35);
      background-color: rgb(149,195,35);
      color: rgb(255,255,255);
    }
  }

  .bingo-dark-green() {
    .cm-corners-all(3px);
    background-color: rgb(58,80,6);
    .cm-font-ds-bold();
    color: rgb(255,255,255);

    &:hover {
      background-color: rgb(96,127,21);
      color: rgb(255,255,255);
    }
  }

  .casino-brown() {
    .cm-corners-all(3px);
    background: rgb(80,62,35);
    .cm-font-ds-bold();
    color: rgb(255, 255, 255);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);

    &:hover {
      background: rgb(80,62,35) + 10%;
    }
  }

  .casino-purple() {
    .cm-corners-all(3px);
    border-top: 1px solid rgb(158,113,165);
    background-color: rgb(101,32,68);
    box-shadow: rgb(95,39,104) 0 0 0 1px, rgba(0,0,0,0.40) 0 3px 2px;
    .cm-font-ds-bold();
    color: #fff;
    text-shadow: 0 1px 0 #7c643b;

    &:hover {
      border-top-color: rgb(162,118,169);
      background-color: rgb(151,48,102);
      color: #fff;
    }
  }

  .dantoto-green() {
    .cm-corners-all(3px);
    border-top: 1px solid rgb(79,190,113);
    background-color: rgb(71,171,102);
    box-shadow: rgba(0,0,0,0.25) 0 0 0 1px, rgba(0,0,0,0.25) 0 3px 2px;
    .cm-font-ds-bold();
    color: rgb(255, 255, 255);
    text-shadow: 0 1px 0 rgb(25,72,43);

    &:hover {
      background-color: rgb(79,190,113);
      color: rgb(255, 255, 255);
    }
  }

  .eurojackpot-black() {
    .cm-corners-all(3px);
    background: rgb(54,54,54); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(54,54,54,1) 0%, rgba(0,0,0,1) 100%); /* FF3.6+ */
    background: -webkit-linear-gradient(top, rgba(54,54,54,1) 0%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -ms-linear-gradient(top, rgba(54,54,54,1) 0%,rgba(0,0,0,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(54,54,54,1) 0%,rgba(0,0,0,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#363636', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.25);
    .cm-font-ds-sans-bold();
    text-transform: uppercase;
    color: #fec115;

    &:after {
      .cm-custom-icon();
      content: "\e601";
      padding-left: 5px;
    }
  }

  .eurojackpot-yellow() {
    .cm-corners-all(3px);
    background: rgb(207,140,26); /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2NmOGMxYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ5JSIgc3RvcC1jb2xvcj0iI2RhYTUzMyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2JhOGMyYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNiMDc3MTYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(207,140,26,1) 0%, rgba(218,165,51,1) 49%, rgba(186,140,43,1) 50%, rgba(176,119,22,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(207,140,26,1)), color-stop(49%,rgba(218,165,51,1)), color-stop(50%,rgba(186,140,43,1)), color-stop(100%,rgba(176,119,22,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(207,140,26,1) 0%,rgba(218,165,51,1) 49%,rgba(186,140,43,1) 50%,rgba(176,119,22,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(207,140,26,1) 0%,rgba(218,165,51,1) 49%,rgba(186,140,43,1) 50%,rgba(176,119,22,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(207,140,26,1) 0%,rgba(218,165,51,1) 49%,rgba(186,140,43,1) 50%,rgba(176,119,22,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(207,140,26,1) 0%,rgba(218,165,51,1) 49%,rgba(186,140,43,1) 50%,rgba(176,119,22,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cf8c1a', endColorstr='#b07716',GradientType=0 ); /* IE6-8 */
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.25);
    .cm-font-ds-sans-bold();
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0,0,0,0.50);

    &:hover {
      background: rgb(208,143,29); /* Old browsers */
      /* IE9 SVG, needs conditional override of 'filter' to 'none' */
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIxJSIgc3RvcC1jb2xvcj0iI2QwOGYxZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ5JSIgc3RvcC1jb2xvcj0iI2U3YzE1MCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2M0YTM0MiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNiMTdhMTkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
      background: -moz-linear-gradient(top, rgba(208,143,29,1) 1%, rgba(231,193,80,1) 49%, rgba(196,163,66,1) 50%, rgba(177,122,25,1) 100%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(1%,rgba(208,143,29,1)), color-stop(49%,rgba(231,193,80,1)), color-stop(50%,rgba(196,163,66,1)), color-stop(100%,rgba(177,122,25,1))); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top, rgba(208,143,29,1) 1%,rgba(231,193,80,1) 49%,rgba(196,163,66,1) 50%,rgba(177,122,25,1) 100%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top, rgba(208,143,29,1) 1%,rgba(231,193,80,1) 49%,rgba(196,163,66,1) 50%,rgba(177,122,25,1) 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(top, rgba(208,143,29,1) 1%,rgba(231,193,80,1) 49%,rgba(196,163,66,1) 50%,rgba(177,122,25,1) 100%); /* IE10+ */
      background: linear-gradient(to bottom, rgba(208,143,29,1) 1%,rgba(231,193,80,1) 49%,rgba(196,163,66,1) 50%,rgba(177,122,25,1) 100%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d08f1d', endColorstr='#b17a19',GradientType=0 ); /* IE6-8 */
    }
  }

  .lotto-yellow(@line-height: 20px) {
    .cm-corners-all(3px);
    background-color: #feb804;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.25);
    .cm-font-ds-book();
    color: #fff;

    &:hover {
      text-decoration: none;
    }

    &:after {
      .cm-custom-icon();
      content: "\e601";
      line-height: @line-height;
      padding-left: 8px;
      vertical-align: top;
    }
  }

  .quick-purple() {
    .cm-corners-all(3px);
    border-top: 1px solid rgb(158,113,165);
    background-color: rgb(127,68,136);
    box-shadow: rgb(94,39,103) 0 0 0 1px, rgba(0,0,0,0.40) 0 3px 2px;
    .cm-font-ds-bold();
    color: rgb(255, 255, 255);
    text-shadow: 0 1px 0 rgb(127,68,136);

    &:hover {
      border-top-color: rgb(162,118,169);
      background-color: rgb(142,93,149);
      color: rgb(255, 255, 255);
    }
  }

  .quick-dark-purple() {
    .cm-corners-all(3px);
    background-color: rgb(67,21,75);
    .cm-font-ds-bold();
    color: rgb(255,255,255);

    &:hover {
      background-color: rgb(178,141,184);
      color: rgb(255,255,255);
    }
  }
}


.cm-buttons(@fontSize: 1.5,@height: 4.0, @heightFF: 3.4, @width: 35%) {
  .button() {
    display: block;
    width: @width;
    min-width: 105px;
    margin: 0 auto;
    border-radius: 3px;
    .cm-font-ds-bold();
    .cm-font(@fontSize, @height, @heightFF);
    text-decoration: none;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }
}
