@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Colors/_colors";
@import (reference) "./NavigationMixins";

.ds-navigation-burger-menu {
  .ds-navigation__focus-outline();
  position: fixed;
  z-index: 130;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 35rem;
  transform: translateX(35rem);
  transition: transform 250ms;
  will-change: transform;

  a:active,
  button:active {
    transform: translateY(0.1rem);
  }

  &.open {
    transition-delay: 100ms;
    transform: translateX(0);
  }

  &.close {
    transform: translateX(35rem);
    transition: transform 200ms;
    transition-delay: 0ms;

    @media @sm {
      transform: translateX(45rem);
    }
  }

  @media @sm {
    max-width: 45rem;
    transform: translateX(45rem);
  }
}

.ds-navigation-burger-menu__top {
  position: absolute;
  z-index: 1;
  inset: 0;
  bottom: auto;
  height: 7.6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    padding: 1rem;
  }
  
  img {
    height: 5rem;
    max-width: 100%;
  }
}

.ds-navigation-burger-menu__close {
  .ds-navigation__menu-close(@ds-white);

  @media @sm {
    top: 2rem;
    right: 2rem;
  }
}

.ds-navigation-burger-menu__inner {
  position: absolute;
  inset: 0;
  padding: 0 2rem 6rem;
  margin-top: 7.6rem;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scrollbar-width: thin;

  @media @sm {
    padding: 0 4rem 4rem;
    margin-top: 9rem;
    scrollbar-width: unset;
  }
}

.ds-navigation-burger-menu__quick-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.3;
  color: white;
  display: grid;
  justify-content: center;
  grid-row-gap: 1.3rem;
  text-align: center;

  a {
    color: @ds-white;
  }

  li:has(img) {
    margin-bottom: 1rem;

    img {
      height: 2rem;
    }
  }
}