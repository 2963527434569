/* When overlay enabled, disable the vertical scrollbar on body, and let overlay_wrapper scrollbar take over */
body.overlay-enabled {
  .overlay-wrapper {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 5001;
  }

  .overlay-container {
    position: absolute;
    left: 50%;
  }

  .overlay {
    position: relative;
    left: -50%;
  }
}

/* When overlay disabled */
body {
  padding-right: 0;

  .overlay-wrapper {
    display: none;
  }
}

