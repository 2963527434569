.generic-smartbanner-static-fix {
  height: 0;
  overflow: hidden;
}
.generic-smartbanner-static-fix.is-open {
  height: 84px;
}
.generic-smartbanner {
  width: 100%;
  height: 0;
  overflow: hidden;
  background: #f3f3f3;
  font-family: Helvetica, sans, sans-serif;
  position: relative;
  z-index: 2;
  transition: all 0.4s ease;
}
.generic-smartbanner.is-open {
  height: 84px;
}
.generic-smartbanner__content {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.generic-smartbanner__content > *:not(:first-child):not(:last-child) {
  margin-left: 1rem;
}
.generic-smartbanner__button--close {
  font-size: 20px;
  display: block;
  margin: 0;
  font-family: serif;
  color: #000;
  text-decoration: none;
}
.generic-smartbanner__logo {
  width: 64px;
  height: 64px;
  border-radius: 15px;
}
.generic-smartbanner__info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 60%;
}
.generic-smartbanner__info--app-name {
  font-size: 14px;
  font-weight: 600;
}
.generic-smartbanner__info--company-name,
.generic-smartbanner__info--description {
  font-size: 12px;
}
.generic-smartbanner__cta {
  color: #1474fc;
  margin-left: auto;
  padding: 1rem;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  display: block;
}
.generic-smartbanner__cta:hover,
.generic-smartbanner__cta:active {
  text-decoration: none;
}
