@import (reference) "./NavigationMixins";

:root {
  --bezierEaseInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

body {
  padding-top: var(--header-height, 0rem);
}

body.no-scroll {
  overflow: hidden;
  width: 100%;
}

.ds-navigation {
  .ds-navigation__focus-outline();
  position: fixed;
  inset: 0;
  bottom: unset;
  z-index: 100;
  overflow: visible;
  transform: unset;
  will-change: transform;
  transition-property: transform;
  transform-style: preserve-3d;
  padding-top: var(--navigation-compliance-bar-height, 0rem);

  &--scroll-down {
    transition-delay: 100ms;
    transition-duration: 500ms; /* When scrolling down, hiding the nav bar. */
    transform: translateY(-100%);
    transition-timing-function: var(--bezierEaseInOutQuad);
  }

  &--scroll-up {
    transition-delay: 200ms;
    transition-duration: 450ms; /* When scrolling up, showing the nav bar. */
    transform: translateY(0);
    transition-timing-function: var(--bezierEaseInOutQuad);
  }

  &.hide {
    display: none;
  }

  @media (orientation: landscape) {
    body.is-mobile-detected &.hide-on-mobile-landscape {
      display: none;
    }
  }
}