@import (reference) "../../AvalonComponents/Styles/Variables/colors";
@import (reference) "./NavigationMixins";

.ds-navigation-side-menu {
  .ds-navigation__focus-outline();
  list-style-type: none;
  width: 100%;
  margin-bottom: 3rem;
  
  ul {
    list-style-type: none;
  }

  li {
    border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-rows: 5.4rem;
    align-items: center;

    &:last-child {
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
    }
  }

  img {
    position: absolute;
    left: 0;
    width: 2rem;
    height: 2rem;
  }

  svg {
    stroke: @ds-black;
    width: 2.4rem;
    height: 2.4rem;
    transition: transform 340ms var(--bezierEaseInOutQuad);
  }

  a,
  button {
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.3;
    color: @ds-black;
    border: none;
    background: none;
    backface-visibility: hidden; /* to keep text from being blurred */

    &:focus-visible {
      border: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &--has-icons {
    a,
    button {
      padding-left: 3rem;
    }
  }

  li.active {
    > a,
    > button {
      font-weight: bold;
    }
  }

  &--white-theme {
    a,
    button {
      color: @ds-white;
    }

    li {
      border-color: rgba(255, 255, 255, 0.2);

      &:last-child {
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.2);
      }
    }

    &--sub-menu li {
      border-color: rgba(255, 255, 255, 0.2);
    }

    svg {
      stroke: @ds-white;
    }
  }

  &--large {
    li {
      grid-template-rows: 6rem;
    }

    a,
    button {
      font-size: 1.8rem;

    }
  }

  &--sub-menu {
    li {
      border-top: none;
      grid-template-rows: 3.4rem;

      &:last-child {
        border-bottom: unset;
      }
    }

    a,
    button {
      font-size: 1.4rem;
    }

    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.ds-navigation-side-menu__sub-items {
  ul {
    margin-top: 0;
    margin-bottom: 0;
    transition: margin 340ms var(--bezierEaseInOutQuad);
  }

  li {
    grid-template-rows: 3.4rem;
    border-top: none;

    &:last-child {
      border-bottom: none;
    }
  }

  a,
  button {
    font-size: 1.4rem;
    padding-left: 2rem;
  }

  .ds-navigation-side-menu--large & {
    li {
      grid-template-rows: 4rem;
    }

    a,
    button {
      font-size: 1.6rem;
      padding-left: 0;
    }
  }

  .ds-navigation-side-menu--has-icons & {
    a,
    button {
      padding-left: 3rem;
    }
  }
}

.ds-navigation-side-menu__sub-sub-items {
  a,
  button {
    padding-left: 3.5rem;
  }

  .ds-navigation-side-menu--has-icons & {
    a,
    button {
      padding-left: 4.5rem;
    }
  }

  .ds-navigation-side-menu--large & {
    a,
    button {
      font-size: 1.4rem;
    }

    li {
      grid-template-rows: 3.7rem;
    }
  }
}

.ds-navigation-side-menu__sub-items,
.ds-navigation-side-menu__sub-sub-items {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 340ms var(--bezierEaseInOutQuad);

  > ul {
    overflow: hidden;
  }
}

.ds-navigation-side-menu {
  > li.open {
    & > button svg {
      transform: rotateX(0.5turn);
    }

    .ds-navigation-side-menu__sub-items {
      grid-template-rows: 1fr;

      & > ul {
        margin-bottom: 2rem;
      }
    }
  }
}

.ds-navigation-side-menu--large {
  > li.open {
    .ds-navigation-side-menu__sub-items {
      & > ul {
        margin-top: 0.5rem;
        margin-bottom: 2.5rem;
      }
    }
  }
}


.ds-navigation-side-menu__sub-items {
  > ul > li.open {
    & > button svg {
      transform: rotateX(0.5turn);
    }

    .ds-navigation-side-menu__sub-sub-items {
      grid-template-rows: 1fr;

      & > ul {
        margin-bottom: 1rem;
      }
    }
  }

  .ds-navigation-side-menu--large & {
    .ds-navigation-side-menu__sub-sub-items {
      & > ul {
        margin-left: 2rem;
      }
    }
  }
}

.ds-navigation-side-menu--large {
  .ds-navigation-side-menu__sub-items {

    li.open {
      .ds-navigation-side-menu__sub-sub-items {
        & > ul {
          margin-top: 1.5rem;
        }
      }
    }
  }
}