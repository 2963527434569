﻿@import url("../variables.less");

@dli-dark: #1c2d56;
@dli-light: #263863;

@dlo-dark: #8f0b0f;
@dlo-light: #9a0000;

//global show/hide elements if on DLI/DLO or not
.is-logged-in.accountmenu {
  .choose-account-btn {
    display: none;
  }
}

.top-navigation-theme-playeraccountmanagement {

  .brand-nav__logo .pam-return-to-site-back {
    @media all and (max-width: (@nav-medium)) {
      display: none;
    }

    display: flex;
  }
  // The cube left of the logo should be hidden on PAM
  // Since we use the official DS logo which already has a cube
  .brand-nav__logo-cube {
    display: none;
  }

  .brand-nav__logo .brand-nav__logo-img,
  .brand-nav__container .pam-return-to-site-close .brand-nav__logo-img {
    @media all and (max-width: (@nav-medium)) {
      display: inline-block;
      padding-left: 0;
    }

    display: none;
  }

  .brand-nav__container .pam-return-to-site-close {
    display: block;
  }

  @media all and (max-width: (@nav-medium)) {
    .brand-nav__container {
      .menu-toggle-left {
        display: none;
      }

      .pam-return-to-site-close {
        left: 0vw;
        // rem for for portrait mode. copied from //avatar for PAM (.profile-btn.mobile-only)
        height: 4.4rem;
        width: 4.4rem;
        top: 1rem;
      }
    }
  }
}


//DLI specific
.dli {
  .top-navigation-theme-playeraccountmanagement {
    .corporate-nav {
      background-color: @dli-dark;

      &__list-item:not(.js-has-subnav) {
        .corporate-nav__list-link:hover {
          color: #fff;
          opacity: 0.6;
        }
      }
    }

    .menu-toggle-right {
      background-color: @dli-light;
    }

    .brand-nav {
      background-color: @dli-light;

      &__item:not(.js-has-subnav) {
        .brand-nav__link:hover {
          color: #fff;
          opacity: 0.6;
        }
      }

      &__logo-text.dlo {
        display: none;
      }

      .mobile-navigation-header {
        background-color: @dli-light;
      }
    }
  }

  &.is-logged-in {
    .accountmenu__item.dli {
      display: block !important;
    }
  }

  .header {
    .user-bar,
    .mobile-nav-right {
      .profile-btn:before {
        background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/icon_pam_user-dli.svg') center center no-repeat;
      }
    }
  }
}


//DLO specific
.dlo {
  .top-navigation-theme-playeraccountmanagement {
    .corporate-nav {
      background-color: @dlo-dark;

      &__list-item:not(.js-has-subnav) {
        .corporate-nav__list-link:hover {
          color: #fff;
          opacity: 0.6;
        }
      }
    }

    .menu-toggle-right {
      background-color: @dlo-light;
    }

    .brand-nav {
      background-color: @dlo-light;

      &__item:not(.js-has-subnav) {
        .brand-nav__link:hover {
          color: #fff;
          opacity: 0.6;
        }
      }

      &__logo-text.dli {
        display: none;
      }

      .mobile-navigation-header {
        background-color: @dlo-light;
      }
    }
  }

  &.is-logged-in {
    .accountmenu__item.dlo {
      display: block !important;
    }
  }

  .header {
    .user-bar,
    .mobile-nav-right {
      .profile-btn:before {
        background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/icon_pam_user-dlo.svg') center center no-repeat; 
      }
    }
  }
}
