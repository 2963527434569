.browser-requirements {
  display: none;
  position: relative;
  top: 0;
  height: auto;
  z-index: 1000;
  border-bottom: 1px solid #F7B506;
  padding: 20px 50px 38px 50px;
  background: #febc0e;
  -moz-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
}
.browser-requirements .browser-icon {
  display: inline-block;
}
.browser-requirements .browser-icon-image {
  display: inline-block;
  width: 25px;
  height: 25px;
  position: relative;
  top: 5px;
  margin-left: 3px;
  margin-right: 5px;
  background-size: 25px;
  background-position: center;
}
.browser-requirements .browser-icon[data-browser="Firefox"] .browser-icon-image {
  background-image: url('/Components/DanskeSpil/Project/Design/Graphics/firefox-color_50x51.png');
}
.browser-requirements .browser-icon[data-browser="Safari"] .browser-icon-image {
  background-image: url('/Components/DanskeSpil/Project/Design/Graphics/safari-color_50x50.png');
}
.browser-requirements .browser-icon[data-browser="Chrome"] .browser-icon-image {
  background-image: url('/Components/DanskeSpil/Project/Design/Graphics/chrome-color_50x50.png');
}
.browser-requirements .browser-icon[data-browser="Internet Explorer"] .browser-icon-image {
  background-image: url('/Components/DanskeSpil/Project/Design/Graphics/ie-color_50x55.png');
}
.browser-requirements:hover {
  background: rgba(254, 188, 14, 0.95);
}
.browser-requirements .info-content {
  position: relative;
  text-align: left;
  min-width: 980px;
  width: 980px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 12px;
  padding-right: 12px;
}
.browser-requirements .info-content:before,
.browser-requirements .info-content:after {
  content: " ";
  display: table;
}
.browser-requirements .info-content:after {
  clear: both;
}
.browser-requirements .info-content:after {
  content: "";
  display: table;
  clear: both;
}
.browser-requirements .info-content:before,
.browser-requirements .info-content:after {
  content: "";
  display: table;
}
.browser-requirements .info-content:after {
  clear: both;
}
.browser-requirements .info-content .browser-data {
  display: inline-block;
  font-size: 1.5rem;
  margin-right: 5px;
}
.browser-requirements .info-content .icon-wrapper {
  display: block;
  position: relative;
  top: -4px;
}
.browser-requirements .info-content .icon-wrapper a {
  color: #0d68b0;
  font-weight: bold;
  font-size: 1.5rem;
}
.browser-requirements .info-content p {
  text-align: left;
  margin: 0;
  display: inline;
  font-size: 1.5rem;
}
.browser-requirements .info-content p a {
  color: #0d68b0;
  font-weight: bold;
}
.browser-requirements .icon-close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 31%;
  right: 43px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.browser-requirements .icon-close .icon-close-inner {
  height: 30px;
  width: 30px;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 30px;
  display: inline-block;
}
.browser-requirements .icon-close .icon-close-inner:before,
.browser-requirements .icon-close .icon-close-inner:after {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.5px;
  margin-left: -15px;
  display: block;
  height: 3px;
  width: 30px;
  background-color: #1b3727;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.browser-requirements .icon-close .icon-close-inner:after {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.browser-requirements .icon-close .icon-close-inner:hover:before,
.browser-requirements .icon-close .icon-close-inner:hover:after {
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
