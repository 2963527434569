@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Variables/colors";
@import (reference) "./NavigationMixins";

.responsible-gaming__top {
  .ds-navigation__focus-outline();
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navigation-compliance-bar-height, 0rem);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  background: @ds-white;
  border-radius: 0 0 2rem 2rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 0.1rem 0.3rem 0;
  color: @ds-black;

  &.hide {
    display: none;
  }

  body:has(.ds-navigation.hide) &,
  body:not(:has(.ds-navigation)) & {
    border-radius: 0;
  }

  @media (orientation: landscape) {
    body.is-mobile-detected:has(.ds-navigation.hide-on-mobile-landscape) & {
      border-radius: 0;
    }
  }
}

.responsible-gaming__top-icon {
  padding: 0 0.8rem;
  height: 100%;
  display: grid;
  align-items: center;

  @media @sm {
    padding: 0 0.8rem 0 1.5rem;
  }

  img {
    width: 2rem;
    height: 2rem;
  }
}

.responsible-gaming__top-clock {
  line-height: 1;
  font-weight: normal;
}

.responsible-gaming__top-toggle {
  border: none;
  display: grid;
  gap: 0.8rem;
  align-items: center;
  grid-template-columns: 1fr auto;
  height: 100%;
  padding: 0.1rem 0.8rem 0;
  background: none;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1;
  color: currentColor;

  @media @sm {
    padding: 0.1rem 1.5rem 0;
  }
}

.responsible-gaming__top-toggle-icon {
  width: 2rem;
  height: 2rem;
  background: @ds-gray-200;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img,
  svg {
    color: currentColor;
    width: 1.6rem;
    height: 1.6rem;
  }
}

.responsible-gaming__top-link {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  padding: 0 1rem;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;

  @media @xl {
    display: flex;
  }

  img {
    height: 2rem;
  }
}

.responsible-gaming__menu {
  .ds-navigation__focus-outline();
  text-wrap: pretty;
  position: fixed;
  z-index: 130;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  transform: translateX(40rem);
  transition: transform 250ms;
  will-change: transform;
  background: @ds-white;
  max-width: 40rem;

  a:active,
  button:active {
    transform: translateY(0.1rem);
  }

  &.open {
    transition-delay: 100ms;
    transform: translateX(0);
  }

  @media @lg {
    transform: translateX(102.4rem);
    max-width: 102.4rem;
  }
}

.responsible-gaming__menu-close {
  position: absolute;
  z-index: 1;
  top: 2rem;
  right: 2rem;
  width: 3.6rem;
  height: 3.6rem;
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: @ds-black;

  svg {
    color: currentColor;
    stroke: @ds-white;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.responsible-gaming__menu-inner {
  position: absolute;
  inset: 0;
  padding: 3.8rem 2.7rem 0;
  overflow-y: auto;
  overscroll-behavior-y: contain;

  @media @lg {
    padding: 8.2rem 7.3rem 0 7.3rem;
  }
}

.responsible-gaming__menu-title {
  font-family: DanskeSpil, serif;
  font-size: 4.4rem;
  line-height: 1.15;
  color: @ds-green;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin-bottom: 0.2rem;

  @media @lg {
    font-size: 5.6rem;
    margin-bottom: 0.8rem;
  }
}

.responsible-gaming__menu-subtitle {
  font-size: 1.4rem;
  line-height: 1.57;
  color: @ds-green;
  margin-bottom: 2.4rem;
  max-width: 80ch;
}

.responsible-gaming__menu-read-more {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: @ds-white;
  height: 4.2rem;
  border-radius: 10rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  background-color: #003a26;
  border: none;
  padding: 0 3.8rem;
  margin-bottom: 5rem;

  &:hover {
    text-decoration: none;
  }

  @media @lg {
    margin-bottom: 4.3rem;
  }
}

.responsible-gaming__menu-lists {
  display: grid;
  gap: 4.8rem;
  grid-template-columns: 1fr;
  margin-bottom: 4.5rem;

  @media @lg {
    grid-template-columns: 1fr 1fr;
    gap: 7rem;
  }
}

.responsible-gaming__menu-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 3rem;
  align-self: start;
}

.responsible-gaming__menu-bullet {
  display: grid;
  grid-template-columns: 2.4rem 1fr;
  column-gap: 1.6rem;
  color: @ds-green;
}

.responsible-gaming__menu-bullet-icon {
  width: 2.4rem;
  height: 2.4rem;
}

.responsible-gaming__menu-bullet-title {
  grid-column-start: 2;
  font-family: DanskeSpil, serif;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1.333;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  a {
    color: currentColor;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-bottom: 0.2rem;
  }
}

.responsible-gaming__menu-bullet-text {
  color: @ds-green;
  grid-column-start: 2;
  font-size: 1.2rem;
  line-height: 1.666;

  a {
    color: currentColor;
    text-decoration: underline;
  }
}


.responsible-gaming__menu-infos {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 0.8rem;
  overflow: hidden;
  align-self: start;
}

.responsible-gaming__menu-info {
  position: relative;
  background: @ds-gray-100;
  font-size: 1.6rem;
  line-height: 1.5;

  &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      top: auto;
      bottom: 0.2rem;
      height: 0.1rem;
      background: rgba(0, 58, 39, 0.2);
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  label {
    cursor: pointer;
    padding: 1rem 5rem 1rem 2.7rem;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 7.2rem;
    font-family: DanskeSpil, serif;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.4;

    @media @lg {
      min-height: 6.4rem;
      font-size: 1.8rem;
    }

    svg {
      position: absolute;
      right: 2.7rem;
      width: 2rem;
      height: 2rem;
      transform: rotate(-45deg);
      transition: transform 340ms ease-out;
    }
  }

  > div {
    overflow: hidden;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 340ms ease-out;

    > div {
      transition: padding 340ms ease-out;
      padding: 0 2.7rem;
      overflow: hidden;
    }
  }

  a {
    color: currentColor;
    text-decoration: underline;
  }

  input[type="checkbox"]:checked {
    ~ label svg {
      transform: rotate(0);
    }

    ~ div {
      grid-template-rows: 1fr;

      > div {
        padding-bottom: 2.5rem;
      }
    }
  }
}

ul.responsible-gaming__menu-links {
  list-style-type: none;
  padding: 0;
  margin-bottom: 4.8rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media @lg {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 6.5rem;
  }
}

.responsible-gaming__menu-link {
  background: @ds-gray-200;
  border-radius: 1rem;
  transition: background .2s ease-in;

  &:hover {
    background: @ds-gray-100;
  }

  a {
    font-family: DanskeSpil, serif;
    font-size: 2rem;
    line-height: 1.5;
    letter-spacing: -0.02em;
    font-weight: 600;
    min-height: 13.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.2rem;
    color: @ds-green;

    &:hover {
      text-decoration: none;
    }

    @media @lg {
      min-height: 13rem;
      font-size: 2.4rem;
      gap: 1rem;
    }

    svg {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}

.responsible-gaming__menu-footer {
  padding: 4.8rem 2.7rem;
  margin: 0 -2.7rem;
  background: @ds-gray-75;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media @lg {
    padding: 6.5rem 7.3rem;
    margin: 0 -7.3rem;
  }
}

.responsible-gaming__menu-sticker {
  color: @ds-green;
  border-radius: 0.8rem;
  background: #FFE299;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 3rem;
  margin-bottom: 4.8rem;

  &:hover {
    text-decoration: none;
  }

  @media @lg {
    width: 64rem;
    padding: 4.4rem;
    margin-bottom: 3.6rem;
  }
}

.responsible-gaming__menu-sticker-label {
  position: relative;
  font-family: DanskeSpil, serif;
  font-size: 1.2rem;
  letter-spacing: -0.02em;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 3rem;

  &::after {
    content: "";
    position: absolute;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 5rem;
    height: 0.1rem;
    background: @ds-yellow;
  }

  @media @lg {
    margin-bottom: 2rem;
  }
}

.responsible-gaming__menu-sticker-text {
  font-family: DanskeSpil, serif;
  font-size: 2.4rem;
  letter-spacing: -0.02em;
  line-height: 1.2;
  font-weight: 600;

  @media @lg {
    font-size: 4rem;
    letter-spacing: -0.03em;
    max-width: 18ch;
    line-height: 1.15;
  }
}

.responsible-gaming__menu-sticker-cta {
  pointer-events: none;
  display: none;
  height: 5.6rem;
  border-radius: 10rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 800;
  color: @ds-green-700;
  border: 0.1rem solid rgba(0, 58, 39, .4);
  transition: border-color .2s ease-in;
  padding: 0 4rem;
  justify-content: center;
  align-items: center;
  margin: 2rem auto 0;

  .responsible-gaming__menu-sticker:hover & {
    border-color: @ds-green-700;
  }

  @media @lg {
    display: flex;
  }
}

.responsible-gaming__menu-footer-links {
  list-style-type: none;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 1rem;

  @media @lg {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0;
  }
}

.responsible-gaming__menu-footer-link {
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 0.8rem;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.5;
    color: @ds-green;

    img {
      height: 4.4rem;
      margin: 0 auto;
    }
  }
}
