.back-to-parent-link {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 17px;
  display: inline-block;
}
.back-to-parent-link:before {
  content: "‹ ";
}
.back-to-parent-link:hover {
  text-decoration: none;
}
