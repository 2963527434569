﻿.operations-messenger > .visible {

  .dark-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9990;
  }

  .operations-messenger-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9990;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .operations-messenger-content {
    width: 100%;
    max-width: 480px; // consistent with @media max-width
    min-height: 30rem;
    z-index: 10000;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
    border-radius: 0.4rem;

    @media only screen and (max-width: 480px) { // rem is not working here
      border-radius: 0;
    }

    &.slide-in {
      animation: slideIn .2s ease-out;
    }

    div.top-bar {
      width: 100%;
      height: 1.6rem;
      background-color: rgba(253, 224, 37, 1);
      border-radius: 0.4rem 0.4rem 0 0;
    }

    div.icon-circle {
      width: 4rem;
      height: 4rem;
      background: rgba(253, 224, 37, 1);
      margin: 2.5rem 0 1.5rem 0;
      border-radius: 50%;

      div.icon {
        width: 4rem;
        height: 4rem;
        background-image: url(/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#operation_messenger_exclamation_use)
      }
    }

    .operations-messenger-heading {
      text-align: center;
      font-weight: 700;
      font-style: normal;
      font-size: 16px;
      line-height: 2.4rem;
    }

    p {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.0rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .operations-messenger-text {
      text-align: center;
      margin: 0 auto 2.0rem auto;
    }

    .operations-messenger-brand-plugin {
      box-sizing: border-box;
      position: relative;
      text-align: center;
      width: 100%;
      padding: 1rem 0 2.5rem 0;

      p {
        font-weight: 700;
        margin-left: 2rem;
        margin-right: 2rem;
      }

      .arrow {
        position: absolute;
        top: 35%;
        right: 2rem;
        width: 2.7rem;
        height: 2.7rem;
        background-image: url(/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#operation_messenger_arrow_use)
      }

      .brand-logo {
        max-height: 7.5rem;
        max-width: 32rem;
      }
    }

    .operations-messenger-notification-plugin {
      box-sizing: border-box;
      text-align: center;
      background: #eee;
      width: 100%;
      padding: 1rem 0;

      h3 {
        font-weight: 700;
        font-size: 1.4rem;
        min-height: 1.6rem;
      }

      p {
        margin-bottom: .5rem;
      }

      .operations-messenger-notification-plugin__content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 1.8rem 2.6rem 1.7rem;
        
        @media only screen and (max-width: 480px) {
          flex-direction: column;
          margin-bottom: 0.3rem;
        }
      }

      input {
        font-weight: 700;
        font-size: 1.4rem;
        border-width: 1px;
        border-style: solid;
        border-color: #ccc;
        border-radius: 0.4rem;
        width: 31.5rem;
        height: 4rem;
        padding: 0 1rem;
        flex: 0 0 auto; // Flex fix for IE11
      }

      ::-webkit-input-placeholder {
        color: #999;
        font-weight: 400;
      }

      ::-moz-placeholder {
        color: #999;
        font-weight: 400;
      }

      :-ms-input-placeholder {
        color: #999;
        font-weight: 400;
      }

      button {
        background: #ffb800;
        font-weight: 700;
        font-size: 1.4rem;
        height: 4rem;
        width: 9.3rem;
        border-width: 0;
        border-radius: 0.4rem;
      }

      .validation-message {
        margin: 0;
        color: #c50000;
      }
    }

    .operations-messenger-notification-subscribed {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 11.7rem;
      padding: 1rem;
      background: #eee;

      h3 {
        font-weight: 700;
        font-size: 1.4rem;
      }
    }

    .unsubscribe-link-text {
      font-size: 1.2rem;
      margin-top: .6rem;

      a:link, a:visited {
        color: black;
      }

      a:hover, a:active {
        color: grey;
      }
    }

    .operations-messenger-button-close {
      text-align: center;
      padding: 1rem;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 1);
      border-radius: 0.4rem;
      cursor: pointer;
      background: white;
      margin: 1.5rem 2rem 2rem 2rem;
      font-weight: 700;
      font-size: 1.4rem;
    }
  }

  .operations-messenger-content-alternate {
    width: 100%;
    min-height: 4rem;
    z-index: 10000;
    background: white;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;

    &.slide-in {
      animation: slideIn .2s ease-out;
    }

    div.icon-circle {
      width: 30px;
      height: 30px;
      background: rgba(253, 224, 37, 1);
      margin: 20px 30px 20px 20px;
      border-radius: 50%;

      div.icon {
        width: 30px;
        height: 30px;
        background-image: url(/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#operation_messenger_exclamation_use)
      }
    }

    .operations-messenger-heading {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-style: normal;
      font-size: 16px;
      line-height: 1.4;
      margin-top: 20px;
    }


    .operations-messenger-button-close {
      text-align: center;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 1);
      border-radius: 0.4rem;
      cursor: pointer;
      background: white;
      font-weight: 700;
      font-size: 1.4rem;
      height: 4rem;
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      margin: auto 2rem;
    }
  }

  .operations-messenger-button {
    display: none;
  }
}
.operations-messenger>div:not(.visible) {

  .operations-messenger-content,
  .operations-messenger-content-alternate {
    display: none;
  }
  /* plus button is visible when the message is hidden */
  .operations-messenger-button {
    position: fixed;
    right: -1.5rem;
    top: 50%;
    width: 6rem;
    background: rgba(253, 224, 37, 1);
    z-index: 10000;
    font-size: 2rem;
    border-radius: 0.4rem 0 0 0.4rem;
    cursor: pointer;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.498039215686275);
    transition: all .15s ease-out;

    &:hover {
      right: 0;
    }

    div.icon {
      width: 4.5rem;
      height: 4.5rem;
      background-image: url(/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#operation_messenger_exclamation_use)
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(100rem);
  }
}


