@font-face {
  font-family: "DSCasinoMultima";
  src: url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Regular.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DSCasinoMultima";
  src: url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Medium.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DSCasinoMultima";
  src: url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Bold.woff2") format("woff2"), 
    url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DSCasinoMultima";
  src: url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Black.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/DSCasinoMultima/DSCasinoMultima-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
