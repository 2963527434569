@import url("./Mixins/_mixins.less");

.corporate-nav {
  position: relative;
  transition: transform 0.4s ease;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media all and (min-width: @nav-medium) {
    height: 4rem;
  }

  .js-prevlogintime span {
    &:not(:empty):before {
      content: 'Seneste login: ';
    }
  }

  &__container {
    height: 6.6rem;

    @media all and (min-width: @nav-medium) {
      max-width: 1440px;
      margin: 0 auto;
      min-width: 923px;
      overflow: hidden;
      height: auto;

      .is-logged-in & {
        min-width: @nav-medium;
      }
    }
  }

  &__logo {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    display: block;


    @media all and (min-width: @nav-medium) {
      transform: none;
      left: auto;
      top: 1.2rem;
      margin-left: 0;
      display: inline-block;

      &-img {
        height: 1.7rem;
        width: 10.6rem;
      }

      &.mobile {
        display: none;
      }
    }
  }

  &__navigation {
    width: auto;
    height: 100%;
    -ms-transform: none;
    transform: none;
    background-color: transparent;
    display: inline-block;
    margin-left: 180px;
    box-shadow: none;
  }

  &__list {
    margin: 0;
    padding: 0;
    max-height: 100%;
    width: 100%;

    &-item {
      list-style: none;
      font-size: 4vw;
      float: left;

      .is-mobile-detected &.desktop-only,
      .is-tablet-detected &.desktop-only {
        display: none;
      }

      &:not(.js-has-subnav) {
        .corporate-nav__list-link:hover {
          color: @ds-white;
          opacity: 0.7;
        }
      }

      //Show the sub-navigation
      &.has-shown-subnav {
        .sub-navigation {
          @media all and (min-width: @nav-medium) {
            transform: translateY(100%) scaleY(1);
            z-index: 1;
          }
        }

        .corporate-nav__list-link {
          //The triangel
          &:after {
            @media all and (min-width: @nav-medium) {
              transform: translateY(0) rotate(225deg);
              transform: translateY(0);
              border-bottom-color: #fff;
            }
          }
        }

        svg.corporate-nav__link-icon {
          transform: rotate(.5turn);
        }
      }
    }

    &-link {
      font-size: 1.4rem;
      padding: 2.3rem 1.8rem;
      line-height: 1.2;
      font-weight: 600;
      color: #fff;
      text-decoration: none;
      position: relative;
      display: block;

      @media all and (min-width: @nav-medium) {
        padding: 1.3rem 1.8rem 1.1rem;
      }

      svg.corporate-nav__link-icon {
        fill: white;
        height: 1.1rem;
        width: 1.1rem;
        margin-left: .6rem;
        display: none;
        transition: transform 150ms ease-out;

        @media all and (min-width: @nav-medium) {
          display: inline-block;
        }
      }

      //The triangel
      &:after {
        @media all and (min-width: @nav-medium) {
          height: 0;
          width: 0;
          content: ' ';
          display: block;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid transparent;
          position: absolute;
          bottom: -3px;
          left: 50%;
          margin-left: -8px;
          -ms-transform: translateY(1000%);
          transform: translateY(1000%);
          z-index: 0;
          transition: transform 200ms ease;
        }
      }
    }
  }

  //The sub-navigation that opens on mouseover or click
  .sub-navigation {
    @media all and (min-width: @nav-medium) {
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      max-height: none;
      transition: all 200ms ease;
      background-color: #FFF;
      overflow: hidden;
      transform-origin: top;
      transform: translateY(100%) scaleY(0);
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
      z-index: 0;
    }

    &__inner {
      max-width: @nav-large;
      margin: 0 auto;
    }

    &__list {
      padding: 0;
      margin: 0;
      box-sizing: content-box !important;
      //TODO: Fix this to border-box
      display: inline-block;
      padding-left: 235px;
    }

    &__item {
      font-size: 14px;
      display: inline-block;
      list-style: none;
      float: left;
    }

    &__link {
      padding: 15px 25px;
      color: #000;
      text-decoration: none;
      font-weight: 600;
      display: inline-block;
      min-height: 0;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.user-bar {
  float: right;
  display: inline-block;

  &__navigation {
    width: auto;
    height: 100%;
    display: inline-block;
  }

  &__list {
    margin: 0;
    padding: 0;
    max-height: 100%;
    list-style-type: none;
  }
}

.accountmenu {
  &__item {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 12.5px 10px 9px;
    line-height: 1.3;
    cursor: pointer;
    transition: .2s;
    color: #fff;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .signup-btn {
    position: relative;
  }

  .login-btn {
    &.mobile-only {
      display: none;
    }
  }

  .info-bar-item--ballance,
  .info-bar-item--username {
    display: none;
  }

  .profile-btn {
    position: relative;
    float: right;
    height: 40px;
    width: 40px;
    padding: 0;
    display: none;


    &.mobile-only {
      display: none;
      color: transparent;
      width: 16vw;
      height: 16vw;
      margin-right: 3vw;
      top: -1.7rem;
    }

    &:before {
      content: ' ';
      cursor: pointer;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      background-position: center;
      background-repeat: no-repeat;
      background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAxMC43IDEwLjgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwLjcgMTAuODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiNGRkYiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTAsOC42QzkuOSw4LjUsNy4zLDcuMiw1LjMsNy4yUzAuOCw4LjUsMC43LDguNkMwLjUsOC43LDAuMyw4LjksMC4zLDkuMXYwLjZjMCwwLjMsMC4zLDAuNiwwLjYsMC42aDguOGMwLjMsMCwwLjYtMC4zLDAuNi0wLjZWOS4xQzEwLjMsOC45LDEwLjIsOC43LDEwLDguNnogTTIuOCw0LjFWMi45YzAtMS40LDEuMS0yLjUsMi41LTIuNXMyLjUsMS4xLDIuNSwyLjV2MS4yYzAsMS40LTEuMSwyLjUtMi41LDIuNVMyLjgsNS41LDIuOCw0LjF6Ii8+PC9zdmc+DQo=');
    }
  }

  //Show the Account menu
  .has-shown-subnav & {
    .mega-menu {
      transform: translateY(100%) scaleY(1);
      z-index: 1;
    }
  }
}

//When we're logged in
.is-logged-in {

  .profile-btn,
  .info-bar-item--ballance,
  .info-bar-item--username {
    @media all and (min-width: @nav-medium) {
      display: inline-block;
    }
  }

  .login-btn,
  .signup-btn {
    display: none;
  }
  
  .accountmenu:hover .accountmenu__item {
    opacity: 0.7;
    cursor: pointer;
  }
}

.top-navigation {

  .sub-sub-sub-navigation {
    max-height: none;
    transition: max-height 0.4s ease;
  }

  //KFST split 2 konti link
  .corporate-nav__list-item.notification-item {
    a {
      padding-right: 2.5rem;
      position: relative;
      display: inline-block;

      @media all and (min-width: @nav-small) {
        display: block;
        padding-right: 5rem;
      }

      @media all and (min-width: @nav-medium) {
        display: block;
        padding-right: 2rem;
      }

      &:before {
        pointer-events: none;
        position: absolute;
        top: 0.6rem;
        right: 0;
        width: 1.8rem;
        height: 1.8rem;
        content: ' ';
        display: block;
        vertical-align: middle;
        font-size: 1.4rem;
        border-radius: 50%;
        background: #feb700 url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/icon_pam_exclamation-black.svg') center center no-repeat;
        background-size: 0.8rem;

        //IPAD
        @media all and (min-width: @nav-small) and (max-width: (@nav-medium - 1)) {
          top: 1rem;
          width: 4rem;
          height: 4rem;
          background-size: 2rem;
        }

        //DESKTOP
        @media all and (min-width: @nav-medium) {
          top: 0.6rem;
          width: 1.5rem;
          height: 1.5rem;
          background-size: 0.75rem;
        }
      }
    }
  }
}
