﻿@import (reference) "Mixins/_mixins.less";

.generic-overlay {

  .genovl-footer {
    position: relative;
    z-index: 20;
    height: 98px;
    padding: 40px 23px 0 30px;

    .genovl-left-logo {
      width: 145px;
      height: 23px;
      margin-top: 15px;
    }

    &:after {
      display: block;
      float: right;
      content: "";
    }

    .genovl-logo-box {
      position: absolute;
      bottom: 20px;
      right: 22px;
      text-align: right;

      img {
        max-width: 107px;
        max-height: 25px;
      }
      img[alt~="casino-white3"], img[alt~="casino-gold"] { margin-bottom: 4px; }
      img[alt~="dantoto-white"], img[alt~="dantoto-orange"] { max-width: 98px; margin-bottom: 5px; }
      img[alt~="keno-white2"], img[alt~="keno-blue"] { max-width: 71px; }
      img[alt~="live-casino-white"], img[alt~="live-casino-gold"] { max-width: 130px; margin-bottom: 6px; }
      img[alt~="lotto-white"], img[alt~="lotto-red"] { max-width: 78px; margin-bottom: 2px; }
      img[alt~="oddset-white"], img[alt~="oddset-blue"] { max-width: 90px; margin-bottom: 5px; }
      img[alt~="tips-white"], img[alt~="tips-green"] { max-width: 77px; margin-bottom: 4px; }
      img[alt~="spillehjoernet-logo"] { max-width: 170px; margin-bottom: -3px; }

      .genovl-logo-text {
        padding: 5px 6px 0 0;
        .cm-font-ds-bold-italic();
        .cm-font-size(1.0);
        color: #fff;
      }
    }
  }
}

