@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextThin.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextThin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextExtraLight.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextLight.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextRegular.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextMedium.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextSemibold.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextSemibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextBold.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Klasselotteriet";
  src: url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextBlack.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/Klasselotteriet/Klasselotteriet-TextBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
