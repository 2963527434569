.generic-overlay .genovl-footer {
  position: relative;
  z-index: 20;
  height: 98px;
  padding: 40px 23px 0 30px;
}
.generic-overlay .genovl-footer .genovl-left-logo {
  width: 145px;
  height: 23px;
  margin-top: 15px;
}
.generic-overlay .genovl-footer:after {
  display: block;
  float: right;
  content: "";
}
.generic-overlay .genovl-footer .genovl-logo-box {
  position: absolute;
  bottom: 20px;
  right: 22px;
  text-align: right;
}
.generic-overlay .genovl-footer .genovl-logo-box img {
  max-width: 107px;
  max-height: 25px;
}
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="casino-white3"],
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="casino-gold"] {
  margin-bottom: 4px;
}
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="dantoto-white"],
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="dantoto-orange"] {
  max-width: 98px;
  margin-bottom: 5px;
}
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="keno-white2"],
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="keno-blue"] {
  max-width: 71px;
}
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="live-casino-white"],
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="live-casino-gold"] {
  max-width: 130px;
  margin-bottom: 6px;
}
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="lotto-white"],
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="lotto-red"] {
  max-width: 78px;
  margin-bottom: 2px;
}
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="oddset-white"],
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="oddset-blue"] {
  max-width: 90px;
  margin-bottom: 5px;
}
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="tips-white"],
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="tips-green"] {
  max-width: 77px;
  margin-bottom: 4px;
}
.generic-overlay .genovl-footer .genovl-logo-box img[alt~="spillehjoernet-logo"] {
  max-width: 170px;
  margin-bottom: -3px;
}
.generic-overlay .genovl-footer .genovl-logo-box .genovl-logo-text {
  padding: 5px 6px 0 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 1rem;
  color: #fff;
}
