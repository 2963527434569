//INFO: Copy/paste all regions icomoon styling into this file


// ****** DS Global - icon styles below **********
// ******* CHANGE WITH PRECAUTION !!! ************
// ***********************************************
@icon-font-url: '/Components/Common/Framework/Design/Fonts/Icomoon/Icon-fonts/DanskeSpil-icons';
@icon-font-family: 'DanskeSpil-icons';
@icon-fp-date-stamp: '201410091337';

@font-face {
  font-family: @icon-font-family;
  src: url(~'@{icon-font-url}.woff') format('woff'),
    url(~'@{icon-font-url}.ttf') format('truetype'),
    url(~'@{icon-font-url}.svg?-@{icon-fp-date-stamp}#DanskeSpil-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-star, .icon-angle-right, .icon-angle-up {
  &:before,
  &:after {
    font-family: @icon-font-family;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// ******* DS Regions - icon styles below **********
// *********** ADD YOUR ICONS BELOW ****************
// ******** icons are used with LESS mixin *********

.icon-star:before {
  // ref found in DanskeSpil/Domain/Quick/Games/Presentation/GameSelectorSpotView.ascx + some CSS
  content: "\e301";
}

.icon-angle-right:before {
  // ref found in many files in Domain/Bingo, Domain/LiveCasion, Domain/quick
  content: "\e601";
}
.icon-angle-up:before {
  // ref found in:
  // DanskeSpil/Domain/Quick/Games/Presentation/GameSelectorSpotView.ascx
  // DanskeSpil/Project/Design/Styles/Regions/Quick/quick-elements.less
  // DanskeSpil/Project/Design/Styles/Regions/Quick/quick-game-selector.less
  content: "\e602";
}


