// from https://github.com/fontsource/font-files/tree/main/fonts/google/open-sans (Open Sans v34)

/* open-sans-latin-300-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-300-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-300-normal.woff) format('woff');
}
/* open-sans-latin-300-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-300-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-300-italic.woff) format('woff');
}
/* open-sans-latin-400-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-400-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-400-normal.woff) format('woff');
}
/* open-sans-latin-400-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-400-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-400-italic.woff) format('woff');
}
/* open-sans-latin-500-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-500-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-500-normal.woff) format('woff');
}
/* open-sans-latin-500-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-500-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-500-italic.woff) format('woff');
}
/* open-sans-latin-600-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-600-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-600-normal.woff) format('woff');
}
/* open-sans-latin-600-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-600-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-600-italic.woff) format('woff');
}
/* open-sans-latin-700-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-700-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-700-normal.woff) format('woff');
}
/* open-sans-latin-700-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-700-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-700-italic.woff) format('woff');
}
/* open-sans-latin-800-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-800-normal.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-800-normal.woff) format('woff');
}
/* open-sans-latin-800-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-800-italic.woff2) format('woff2'), url(/Components/Common/Framework/Design/Fonts/OpenSans/open-sans-latin-800-italic.woff) format('woff');
}
