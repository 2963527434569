body.overlay-enabled .generic-overlay {
  height: 100%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  overflow: auto;
}
.generic-overlay {
  width: 980px;
  min-height: 500px;
  max-height: calc(100vh - 120px);
  margin: 100px auto 60px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}
.color-bingo-lime .generic-overlay {
  background-color: #a0d323;
}
.color-blitz-bordeaux .generic-overlay {
  background-color: #6b0000;
}
.color-blitz-white .generic-overlay {
  background-color: #ffffff;
}
.color-casino-gold .generic-overlay {
  background-color: #a78952;
}
.color-ds-green .generic-overlay {
  background-color: #004b32;
}
.color-ds-yellow .generic-overlay {
  background-color: #feb700;
}
.color-dantoto-orange .generic-overlay {
  background-color: #dc5f11;
}
.color-eurojackpot-gold .generic-overlay {
  background-color: #e8a332;
}
.color-keno-lightblue .generic-overlay {
  background-color: #00a5eb;
}
.color-lotto-red .generic-overlay {
  background-color: #c50005;
}
.color-oddset-darkblue .generic-overlay {
  background-color: #003e99;
}
.color-oddset-grey .generic-overlay {
  background-color: #2b2b2b;
}
.color-oddset-orange .generic-overlay {
  background-color: #dc5f11;
}
.color-onsdagslotto-blue .generic-overlay {
  background-color: #0072ba;
}
.color-poker-black .generic-overlay {
  background-color: #000000;
}
.color-quick-violet .generic-overlay {
  background-color: #7f4488;
}
.color-tips-green .generic-overlay {
  background-color: #009a60;
}
.color-cego-greyblue .generic-overlay {
  background-color: #7d96b4;
}
.color-spillehjoernet-greyblue .generic-overlay {
  background-color: #7d96b4;
}
.generic-overlay.color-bingo-lime {
  background-color: #a0d323;
}
.generic-overlay.color-blitz-bordeaux {
  background-color: #6b0000;
}
.generic-overlay.color-blitz-white {
  background-color: #ffffff;
}
.generic-overlay.color-casino-gold {
  background-color: #a78952;
}
.generic-overlay.color-ds-green {
  background-color: #004b32;
}
.generic-overlay.color-ds-yellow {
  background-color: #feb700;
}
.generic-overlay.color-dantoto-orange {
  background-color: #dc5f11;
}
.generic-overlay.color-eurojackpot-gold {
  background-color: #e8a332;
}
.generic-overlay.color-keno-lightblue {
  background-color: #00a5eb;
}
.generic-overlay.color-live-casino-gold {
  background-color: #876839;
}
.generic-overlay.color-lotto-red {
  background-color: #c50005;
}
.generic-overlay.color-oddset-darkblue {
  background-color: #003e99;
}
.generic-overlay.color-oddset-grey {
  background-color: #2b2b2b;
}
.generic-overlay.color-oddset-orange {
  background-color: #dc5f11;
}
.generic-overlay.color-onsdagslotto-blue {
  background-color: #0072ba;
}
.generic-overlay.color-poker-black {
  background-color: #000000;
}
.generic-overlay.color-quick-violet {
  background-color: #7f4488;
}
.generic-overlay.color-tips-green {
  background-color: #009a60;
}
.generic-overlay.color-cego-greyblue {
  background-color: #7d96b4;
}
.generic-overlay.color-spillehjoernet-greyblue {
  background-color: #7d96b4;
}
.generic-overlay .genovl-header {
  position: relative;
  z-index: 10;
  height: 80px;
  padding: 13px 30px 0 31px;
}
.generic-overlay .genovl-header .genovl-title {
  float: left;
  margin: 0;
  padding-top: 12px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 2.7rem;
  color: #fff;
}
.generic-overlay .genovl-header .genovl-close {
  float: right;
  display: block;
  width: 42px;
  height: 43px;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  cursor: pointer;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.generic-overlay .genovl-header .genovl-close:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 18px inset;
}
.generic-overlay .genovl-header .genovl-close:before {
  display: block;
  position: absolute;
  width: 42px;
  height: 43px;
  margin: 0;
  content: "";
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.generic-overlay .genovl-header .genovl-close:after {
  display: block;
  width: 18px;
  height: 19px;
  margin: 11px auto 0;
  content: "";
  background: url(/Components/DanskeSpil/Project/Design/Graphics/regions/all-regions/sprite-global.png) no-repeat -300px -100px;
}
.generic-overlay .genovl-column-wrapper {
  padding: 0 30px;
}
.generic-overlay .genovl-column-wrapper:before,
.generic-overlay .genovl-column-wrapper:after {
  content: "";
  display: table;
}
.generic-overlay .genovl-column-wrapper:after {
  clear: both;
}
.generic-overlay .genovl-primary-column {
  float: right;
  position: relative;
  z-index: 20;
  width: 72.17391304%;
}
.generic-overlay .genovl-primary-column.full-size {
  width: 100%;
}
.generic-overlay .genovl-primary-column.full-size .genovl-video {
  height: 517px;
}
.generic-overlay .genovl-video {
  background: #808080;
  padding: 0;
  margin: 0;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 374px;
}
.generic-overlay .genovl-video iframe {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.generic-overlay .genovl-image {
  background: #808080;
  padding: 0;
  margin: 0;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5);
  width: 100%;
}
.generic-overlay .genovl-image img {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}
.generic-overlay.marketing-overlay .genovl-column-wrapper {
  padding: 0;
}
.generic-overlay.marketing-overlay .genovl-article {
  box-shadow: none;
}
.generic-overlay.marketing-overlay .genovl-headline {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 6rem;
  line-height: 6.5rem;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
  color: #000;
  margin-top: 0.4em;
  text-align: center;
  text-transform: uppercase;
}
.generic-overlay.marketing-overlay .genovl-image {
  box-shadow: none;
}
.generic-overlay.marketing-overlay.color-bingo-lime {
  background-color: #a0d323;
}
.generic-overlay.marketing-overlay.color-bingo-lime .genovl-headline {
  color: #a0d323;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-blitz-bordeaux {
  background-color: #6b0000;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-blitz-bordeaux .genovl-headline {
  color: #6b0000;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-blitz-white {
  background-color: #ffffff;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-blitz-white .genovl-headline {
  color: #ffffff;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-casino-gold {
  background-color: #a78952;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-casino-gold .genovl-headline {
  color: #a78952;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-ds-green {
  background-color: #004b32;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-ds-green .genovl-headline {
  color: #004b32;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-ds-yellow {
  background-color: #feb700;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-ds-yellow .genovl-headline {
  color: #feb700;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-dantoto-orange {
  background-color: #dc5f11;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-dantoto-orange .genovl-headline {
  color: #dc5f11;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-eurojackpot-gold {
  background-color: #e8a332;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-eurojackpot-gold .genovl-headline {
  color: #e8a332;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-keno-lightblue {
  background-color: #00a5eb;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-keno-lightblue .genovl-headline {
  color: #00a5eb;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-lotto-red {
  background-color: #c50005;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-lotto-red .genovl-headline {
  color: #c50005;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-oddset-darkblue {
  background-color: #003e99;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-oddset-darkblue .genovl-headline {
  color: #003e99;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-oddset-grey {
  background-color: #2b2b2b;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-oddset-grey .genovl-headline {
  color: #2b2b2b;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-oddset-orange {
  background-color: #dc5f11;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-oddset-orange .genovl-headline {
  color: #dc5f11;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-onsdagslotto-blue {
  background-color: #0072ba;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-onsdagslotto-blue .genovl-headline {
  color: #0072ba;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-poker-black {
  background-color: #000000;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-poker-black .genovl-headline {
  color: #000000;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-quick-violet {
  background-color: #7f4488;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-quick-violet .genovl-headline {
  color: #7f4488;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-tips-green {
  background-color: #009a60;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-tips-green .genovl-headline {
  color: #009a60;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-cego-greyblue {
  background-color: #7d96b4;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-cego-greyblue .genovl-headline {
  color: #7d96b4;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-spillehjoernet-greyblue {
  background-color: #7d96b4;
}
.generic-overlay.marketing-overlay.color-bingo-lime.color-spillehjoernet-greyblue .genovl-headline {
  color: #7d96b4;
}
