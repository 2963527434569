﻿@import (reference) "../../AvalonComponents/Styles/Colors/_colors";

.ds-overlay {
  position: fixed;
  inset: 0;
  background: fade(@ds-black, 60%);
  pointer-events: none;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition-property: opacity;
  transition-duration: 400ms;
  will-change: opacity;

  &--navigation {
    z-index: 120;
  }

  &--modal {
    z-index: 9999;
  }

  &.show {
    pointer-events: unset;
    transition-delay: 0ms;
    opacity: 1;
  }

  &.hide {
    transition-delay: 0ms;
    opacity: 0;
  }

  &.hide-after-delay {
    transition-delay: 120ms;
    opacity: 0;
  }
}
