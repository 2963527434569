﻿.rte-custom-classes() {
  .font-bold {
    .cm-font-ds-bold();
  }

  .font-black {
    .cm-font-ds-black();
  }

  .text-yellow {
    color: #fdc917;
  }

  .phone-icon {
    .cm-font-size(1.8);

    &:before {
      .cm-custom-icon();
      content: "\e0e4";
      color: #fff;
      .cm-font-size(1.8);
      padding-right: 8px;
    }
  }
}

.rte-styling() {
  p {
    margin: 0;
  }

  h1, h2, h3, h4 {
    margin: 0;
    .cm-font-size(1.5);
    font-weight: normal;
  }

  h1 {
    line-height: 2.0;
  }

  h2 {
    line-height: 1.0;
  }

  h3 {
    line-height: 0.8;
  }

  h4 {
    line-height: 0.6;
  }
}
