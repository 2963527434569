@import "./Mixins/_mixins.less";

//This is global styles for the header
.header.top-navigation {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  transition: transform 0.4s ease;
  -webkit-transition: transform 0.4s ease;
  font-size: 1.4rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;

  @media print {
    display: none;
  }

  &.header--not-fixed {
    position: absolute;
  }

  .mode-edit & {
    position: relative;
  }

  * {
    box-sizing: border-box;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

/* OVERLAY */
.header__cta-component__read-more-overlay {
  background: #fff;
  top: 10%;
  margin-top: 40%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  padding: 1em 4em;
  border-radius: .7em;
  text-align: center;
  width: 90%;
  max-width: calc(@nav-large / 3);

  @media screen and (max-width: @nav-medium) {
    margin-top: 10%;
  }

  &__title {
    .font-size(22);
  }

  &__paragraph {
    .font-size(14);
    line-height: 1.5;
  }

  &__close-btn {
    .btn(green);
    min-width: 100px;
    margin: 2em 0;
  }

  &__close-icon {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 0;
    margin: 20px 20px;
    .font-size(26);
    font-weight: 400;
    cursor: pointer;
  }
}

