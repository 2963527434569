body.cookie-info-active .cookie-info {
  display: block;
}
.cookie-info {
  display: none;
  min-height: 13rem;
  overflow-y: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  -moz-box-shadow: 0 -0.2rem 0.4rem 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 -0.2rem 0.4rem 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 -0.2rem 0.4rem 0 rgba(0, 0, 0, 0.5);
  background-color: #1a3726;
}
.cookie-info .cookie-info__container {
  max-width: 144rem;
  margin: 0 auto;
}
.cookie-info .cookie-info__content {
  color: #f0f0f0;
  padding: 2rem;
  line-height: 1.35;
  max-width: 767px;
}
.cookie-info .cookie-info__content h3 {
  font-size: 1.5rem;
  margin: 0 0 1rem;
}
.cookie-info .cookie-info__content p {
  font-size: 1.3rem;
  margin: 0 0 2rem;
}
.cookie-info .cookie-info__content .seen_button {
  display: inline-block;
  border-radius: 0.3rem;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1;
  padding: 1.3rem;
  text-decoration: none;
  text-shadow: 0 0.1rem 0 rgba(0, 0, 0, 0.4);
  text-align: center;
  background: #316446;
  cursor: pointer;
  border-top: .1rem solid #3b7451;
  -moz-box-shadow: 0 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.5);
  min-width: 16rem;
}
.cookie-info .cookie-info__content .seen_button:hover {
  background: #397552;
}
@media (max-width: 480px) {
  .cookie-info .cookie-info__content .seen_button {
    display: block;
  }
}
