.ds-navigation-burger-menu {
  position: fixed;
  z-index: 130;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 35rem;
  transform: translateX(35rem);
  transition: transform 250ms;
  will-change: transform;
}
.ds-navigation-burger-menu a:focus {
  outline: none;
}
.ds-navigation-burger-menu a:focus-visible,
.ds-navigation-burger-menu *:focus-visible {
  outline: #000000 dotted thin;
  outline-offset: 0.2rem;
}
.ds-navigation-burger-menu a:active,
.ds-navigation-burger-menu *:active {
  outline: none;
}
.ds-navigation-burger-menu a:active,
.ds-navigation-burger-menu button:active {
  transform: translateY(0.1rem);
}
.ds-navigation-burger-menu.open {
  transition-delay: 100ms;
  transform: translateX(0);
}
.ds-navigation-burger-menu.close {
  transform: translateX(35rem);
  transition: transform 200ms;
  transition-delay: 0ms;
}
@media screen and (min-width: 768px) {
  .ds-navigation-burger-menu.close {
    transform: translateX(45rem);
  }
}
@media screen and (min-width: 768px) {
  .ds-navigation-burger-menu {
    max-width: 45rem;
    transform: translateX(45rem);
  }
}
.ds-navigation-burger-menu__top {
  position: absolute;
  z-index: 1;
  inset: 0;
  bottom: auto;
  height: 7.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ds-navigation-burger-menu__top a {
  padding: 1rem;
}
.ds-navigation-burger-menu__top img {
  height: 5rem;
  max-width: 100%;
}
.ds-navigation-burger-menu__close {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 2rem;
  right: 2rem;
  width: 3.6rem;
  height: 3.6rem;
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 0.4rem transparent;
}
@media screen and (min-width: 768px) {
  .ds-navigation-burger-menu__close {
    top: 3rem;
    right: 4rem;
  }
}
.ds-navigation-burger-menu__close svg {
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  color: white;
  width: 2.4rem;
  height: 2.4rem;
}
@media screen and (min-width: 768px) {
  .ds-navigation-burger-menu__close {
    top: 2rem;
    right: 2rem;
  }
}
.ds-navigation-burger-menu__inner {
  position: absolute;
  inset: 0;
  padding: 0 2rem 6rem;
  margin-top: 7.6rem;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scrollbar-width: thin;
}
@media screen and (min-width: 768px) {
  .ds-navigation-burger-menu__inner {
    padding: 0 4rem 4rem;
    margin-top: 9rem;
    scrollbar-width: unset;
  }
}
.ds-navigation-burger-menu__quick-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.3;
  color: white;
  display: grid;
  justify-content: center;
  grid-row-gap: 1.3rem;
  text-align: center;
}
.ds-navigation-burger-menu__quick-nav a {
  color: white;
}
.ds-navigation-burger-menu__quick-nav li:has(img) {
  margin-bottom: 1rem;
}
.ds-navigation-burger-menu__quick-nav li:has(img) img {
  height: 2rem;
}
