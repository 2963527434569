/**
 * Text
 * Mobile font sizes have been halved
 */



.fs-header() {
  font-size: 5.9vw;
  line-height: 1.2;
  font-weight: bold;
  @media (min-width: @breakpoint-tablet) {
    font-size: 2.8rem;
  }
}

.fs-input-large() {
  font-size: 5.9vw;
  line-height: 1.2;
  font-weight: normal;
  @media (min-width: @breakpoint-tablet) {
    font-size: 2.8rem;
  }
}

.fs-header-small() {
  font-size: 4.6vw;
  line-height: 1.3;
  font-weight: bold;
  @media (min-width: @breakpoint-tablet) {
    font-size: 2rem;
  }
}

.fs-input-medium() {
  font-size: 3.8vw;
  line-height: 1.4;
  font-weight: normal;
  @media (min-width: @breakpoint-tablet) {
    font-size: 1.8rem;
  }
}

.fs-copy-bold() {
  font-size: 3.8vw;
  line-height: 1.4;
  font-weight: bold;
  @media (min-width: @breakpoint-tablet) {
    font-size: 1.5rem;
  }
}

.fs-copy() {
  //font-size: 1.4rem;
  font-size: 3.8vw;
  line-height: 1.4;
  font-weight: normal;
  @media (min-width: @breakpoint-tablet) {
    font-size: 1.5rem;
  }
  .is-desktop-detected & {
    font-size: 1.5rem;
  }
}

.fs-small() {
  font-size: 2.9vw;
  line-height: 1.4;
  font-weight: bold;
  @media (min-width: @breakpoint-tablet) {
    font-size: 1.2rem;
  }
}

.fs-legal-copy() {
  font-size: 2.9vw;
  line-height: 1.4;
  font-weight: normal;
  @media (min-width: @breakpoint-tablet) {
    font-size: 1.2rem;
  }
}
/**
 * Buttons
 * Mobile font sizes have been halved
 */
.fs-button-small() {
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1.4;
  @media (min-width: @breakpoint-tablet) {
    font-size: 1.2rem;
  }
}

.fs-button-medium() {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.3;
}

.fs-button-large() {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.3;
  @media (min-width: @breakpoint-tablet) {
    font-size: 1.6rem;
  }
}

.fs-button-sticky() {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.3;
  @media (min-width: @breakpoint-tablet) {
    font-size: 1.6rem;
  }
}
