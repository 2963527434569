// Raleway (https://gwfh.mranftl.com/fonts/raleway?subsets=latin)

/* raleway-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-500.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Note: Font was added for the casino project and may need to be added to danske spil as well */
/* raleway-500 italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-MediumItalic.woff2') format('woff2');
}

/* raleway-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-700.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Note: Font was added for the casino project and may need to be added to danske spil as well */
/* raleway-700 italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-BoldItalic.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-BoldItalic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Note: Font was added for the casino project and may need to be added to danske spil as well */
/* raleway-800 - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-ExtraBold.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-ExtraBold.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Note: Font was added for the casino project and may need to be added to danske spil as well */
/* raleway-800 italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-ExtraBoldItalic.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/Raleway-ExtraBoldItalic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* raleway-900italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  src: url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-900italic.woff2') format('woff2'), url('/Components/Common/Framework/Design/Fonts/Raleway/raleway-v28-latin-900italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
