﻿@import "_imports.less";

.onboardingspot-overlay {
  .onboardingspot-overlay__wrapper {
    position: relative;
    justify-content: center;

    .onboardingspot-overlay__closebutton {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      height: 2rem;
      width: 2rem;
    }

    .onboardingspot-overlay__dontshow {
      display: inline-block;
      font-weight: 600;
      position: relative;
      padding-left: 3rem;
      margin-bottom: 2rem;
      cursor: pointer;
      user-select: none;

      & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ span:after {
          display: block;
        }
      }

      & span {
        position: absolute;
        top: 0;
        left: 0;
        height: 2rem;
        width: 2rem;
        background-color: #eee;
        transition: 0.2s;

        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 0.7rem;
          top: 0.4rem;
          width: 0.4rem;
          height: 0.8rem;
          border: solid white;
          border-width: 0 0.3rem 0.3rem 0;
          transform: rotate(45deg);
        }
      }

      &:hover input ~ .checkmark {
        background-color: @color-gray-400;
      }
    }

    .onboardingspot-overlay__shadow {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      justify-content: center;
      background-color: black;
      z-index: 9988;
      opacity: 0;
      display: none;
      transition: opacity .3s ease-in;

      &.fade-in {
        display: block;
        opacity: 0.4;
      }
    }

    .onboardingspot-overlay__content {
      position: fixed;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 4rem;
      width: 80vw;
      height: auto;
      max-height: 99%;
      overflow: auto;
      border-radius: .2rem;
      text-align: center;
      font-size: 1.3rem;
      z-index: 9989; // operations-messenger z-index 9990 - 10000
      box-shadow: 0.3rem 0.3rem 4rem 0 rgba(0,0,0,0.5);
      background: white;
      opacity: 0;
      transition: opacity .5s ease-in;

      @media @xs {
        width: 60rem;
        top: 6%;
      }

      &.fade-in {
        opacity: 1;
        display: block;
      }

      .onboardingspot-overlay__list-element {
        & > img {
          height: auto;
          max-width: 100%;
          max-height: 43%;
          pointer-events: none;
          margin-top: 1rem;

          @media @xs {
            max-height: 29rem;
            margin-top: 2rem;
          }
        }
      }
    }

    .onboardingspot-overlay__list-element--link {
      display: inline-block;
      margin: 3rem 0 1rem 0;
    }

    .onboardingspot-overlay__button-container {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-bottom: 1.5rem;

      .onboardingspot-overlay__prev-cta {
        cursor: pointer;
      }

      .onboardingspot-overlay__prev-cta, .onboardingspot-overlay__flex-ghostblock {
        width: 3rem;
        height: 3rem;
        padding-bottom: 1.8rem;
      }
    }

    .onboardingspot-overlay__cta, .onboardingspot-overlay__next-cta {
      margin: 1rem 3rem;
      padding: 1.3rem 2rem;
      border-radius: 0.4rem;
      background-color: @color-green-200; // Corporate Primary
      color: white;
      display: inline-block;
      width: 10rem;
      text-decoration: none;
      font-weight: 600;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

// coausel styles
.onboardingspot-overlay__outer-wrapper {
  width: 100%;
  overflow: hidden;

  .onboardingspot-overlay__inner-wrapper {
    display: inline-flex;
    white-space: nowrap;
    transition: transform 0.2s linear;

    .onboardingspot-overlay__list-element {
      white-space: normal;
      display: inline-block;
      text-decoration: none;
      text-align: center;
      width: 80vw;

      @media @xs {
        width: 60rem;
      }

      & > div {
        padding: 0.8rem;

        @media @xs {
          padding: 2rem;
        }
      }
    }
  }
}

.dots-wrapper {
  display: flex;
  justify-content: center;
  padding: 2vw 0;
  background-color: white;

  @media @xs {
    padding: 1rem 0;
  }

  .dot {
    width: 2.2vw;
    height: 2.2vw;
    margin: 0.6vw;
    border-radius: 50%;
    background-color: black;
    opacity: 0.4;
    transition: opacity 0.15s linear;

    @media @xs {
      width: 1.2rem;
      height: 1.2rem;
      margin: 0.4rem;
      cursor: pointer;
    }

    &.active {
      opacity: 1;
    }
  }
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.15s linear, transform 0.25s linear;
  box-sizing: border-box;
  display: none;

  @media @xs {
    width: 2.6rem;
    height: 2.6rem;
    padding: 0.6rem;
    display: block;
    top: 15rem;
  }

  .arrow-icon {
    width: 100%;
    height: 100%;
  }

  &.left {
    left: 0;
    transform: translate(-150%, -50%);

    @media @xs {
      left: -3.2rem;
    }

    .arrow-icon {
      transform: rotate(180deg);
    }
  }

  &.right {
    right: 0;
    transform: translate(150%, -50%);

    @media @xs {
      right: -3.2rem;
    }
  }

  &.active {
    opacity: 1;
    transform: translate(0, -50%);

    @media @xs {
      cursor: pointer;
    }
  }
}
