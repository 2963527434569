.modal-wrapper {
  transition: all 0.3s;
  overflow: hidden;
}
.modal-wrapper:before {
  content: "";
  position: fixed;
  inset: 0;
  z-index: 21;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000000;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease;
}
.modal-wrapper.overlay-active {
  transition: all 0.3s;
}
.modal-wrapper.overlay-active:before {
  opacity: 0.7;
  pointer-events: all;
}
.is--hidden {
  display: none;
}
.ds-modal-frame {
  position: fixed;
  top: 50vh;
  left: 50vw;
  width: 50%;
  max-width: 80vw;
  min-width: 80vw;
  height: auto;
  z-index: 2000;
  backface-visibility: hidden;
  transform: translateX(-50%) translateY(-50%) scale(1);
  transition: all 0.3s ease;
  border-radius: 0.4rem;
  animation: modalIn;
  animation-duration: 0.3s;
  /* Fix IE bug (https://connect.microsoft.com/IE/feedback/details/796745/mouse-events-are-not-delivered-at-all-anymore-when-inside-an-svg-a-use-is-removed-from-the-dom) */
}
.ds-modal-frame--ios-position-fix {
  position: absolute;
}
@media (min-width: 768px) {
  .ds-modal-frame {
    max-width: 63rem;
    min-width: 31rem;
  }
}
@media (orientation: landscape) {
  .ds-modal-frame {
    max-height: 90vh;
  }
}
.ds-modal-frame svg use {
  pointer-events: none;
}
.ds-modal-frame.remove-overlay {
  transform: translateX(-50%) translateY(-50%) scale(0);
}
.ds-modal-frame .ds-modal-wrap {
  background: #f5f5f5;
  border-radius: 0.4rem;
  transition: all 0.3s;
  transform: translateY(-6%);
  opacity: 0;
}
.is--shown.ds-modal-frame .ds-modal-wrap {
  transform: translateY(0);
  opacity: 1;
}
.is--behind-other-modal.ds-modal-frame .ds-modal-wrap {
  pointer-events: none;
  filter: blur(2px);
}
.is--behind-other-modal.ds-modal-frame .ds-modal-wrap:before {
  background: #000000;
  content: " ";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 3;
  opacity: 0.3;
  border-radius: 0.6rem;
}
@media (min-width: 768px) {
  .ds-modal-frame {
    min-width: 52rem;
  }
}
.ds-modal-frame .ds-modal-html {
  padding-bottom: 3vw;
}
@media (min-width: 768px) {
  .ds-modal-frame .ds-modal-html {
    padding-bottom: 3.0rem;
  }
}
.ds-modal-frame .ds-modal-content {
  padding: 6vw 6vw 6vw;
  text-align: left;
  box-shadow: 0 0 1.3rem rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
}
@media (orientation: landscape) {
  .ds-modal-frame .ds-modal-content {
    max-height: 90vh;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .ds-modal-frame .ds-modal-content {
    padding: 2rem 2rem 2rem;
  }
}
.ds-modal-frame .ds-modal-content .sprite-symbol {
  width: 12vw;
  height: 12vw;
  margin: -1rem 1rem 0 0;
}
@media (min-width: 768px) {
  .ds-modal-frame .ds-modal-content .sprite-symbol {
    width: 5rem;
    height: 5rem;
  }
}
.ds-modal-frame .ds-modal-content .sprite-symbol--center {
  display: block;
  margin: 0 auto;
}
.ds-modal-frame .ds-modal-content .modal-header-icon {
  background-color: white;
  border-radius: 50%;
  padding: 1rem;
  border: 1px solid #cfcfcf;
}
.ds-modal-frame .ds-modal-content h1 {
  display: inline;
  font-size: 5.9vw;
  line-height: 1.2;
  font-weight: bold;
  color: #323232;
}
@media (min-width: 768px) {
  .ds-modal-frame .ds-modal-content h1 {
    font-size: 2.8rem;
  }
}
.ds-modal-frame .ds-modal-content .center {
  text-align: center;
  display: block;
  margin: 2rem 0 1rem;
}
.ds-modal-frame .ds-modal-content p {
  color: #6e6e6e;
  font-size: 3.8vw;
  line-height: 1.4;
  font-weight: normal;
}
.ds-modal-frame .ds-modal-content p:not(:last-of-type) {
  margin: 0 0 4vw;
}
@media (min-width: 768px) {
  .ds-modal-frame .ds-modal-content p {
    margin: 1.5rem 0;
  }
}
@media (min-width: 768px) {
  .ds-modal-frame .ds-modal-content p {
    font-size: 1.5rem;
  }
}
.is-desktop-detected .ds-modal-frame .ds-modal-content p {
  font-size: 1.5rem;
}
.ds-modal-frame .ds-modal-content small {
  display: block;
  margin-top: 1.0rem;
  font-size: 2.9vw;
  line-height: 1.4;
  font-weight: bold;
  font-weight: normal;
}
@media (min-width: 768px) {
  .ds-modal-frame .ds-modal-content small {
    font-size: 1.2rem;
  }
}
@media (min-width: 768px) {
  .ds-modal-frame .ds-modal-content {
    padding: 4.0rem 2.4rem;
  }
}
.ds-modal-frame .ds-modal-content .button-row {
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
}
.ds-modal-frame .ds-modal-content .button-row--stack {
  flex-direction: column !important;
}
.ds-modal-frame .ds-modal-content .button-row--stack .pam__button {
  width: 100%;
  margin: 0.5rem 0;
}
@media (min-width: 768px) {
  .ds-modal-frame .ds-modal-content .button-row {
    flex-direction: row;
  }
}
.ds-modal-frame .ds-modal-content .button-row .pam__button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  border-radius: 5rem;
  padding: 0 4vw;
  min-height: 12vw;
}
@media (min-width: 768px) {
  .ds-modal-frame .ds-modal-content .button-row .pam__button {
    border-radius: 0.4rem;
    padding: 0 3rem;
    min-height: 5rem;
  }
  .ds-modal-frame .ds-modal-content .button-row .pam__button--round {
    border-radius: 5rem !important;
  }
  .ds-modal-frame .ds-modal-content .button-row .pam__button:not(:last-child) {
    margin-right: 4rem;
  }
  .ds-modal-frame .ds-modal-content .button-row .pam__button__text {
    white-space: normal;
  }
}
.ds-modal-link {
  font-size: 3.8vw;
  color: #2f80ed;
  display: block;
  margin: 1.5rem 0 0 0;
  padding: 0.5rem;
  text-align: center;
  outline: none;
}
@media (min-width: 768px) {
  .ds-modal-link {
    font-size: 1.5rem;
  }
}
.ds-modal-link:hover,
.ds-modal-link:focus {
  text-decoration: none;
}
.ds-modal--close {
  position: absolute;
  right: 0;
  margin: 4vw;
}
@media (min-width: 768px) {
  .ds-modal--close {
    margin: 2.0rem;
  }
}
.ds-modal--close .sprite-symbol {
  width: 4vw;
  height: 4vw;
}
@media (min-width: 768px) {
  .ds-modal--close .sprite-symbol {
    width: 1.8rem;
    height: 1.8rem;
  }
}
.ds-modal--close:hover {
  cursor: pointer;
}
.ds-modal {
  margin: 0 auto;
}
.ds-modal-frame.is--shown ~ .ds-modal-overlay {
  opacity: 1;
  visibility: visible;
}
.ds-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
}
@keyframes modalIn {
  0% {
    transform: translate3d(-50%, -50%, 0) scale(0);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}
