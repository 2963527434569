
@media only print {

  #printable {
    // BEGIN Print styles:
    * {
      background: transparent !important;
      color: #000;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: " (" attr(href) ")";
    }

    abbr[title]:after {
      content: " (" attr(title) ")";
    }
    //Don't show links for images, or javascript/internal links
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
      content: "";
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
    // ****************** ****** ********* *********************
    // Show/Hide/Position custom elements: *********************
    // ****************** ****** ********* *********************
    body {
      //margin-top: 10mm;
    }

    .aside-column,
    .social-plugin,
    .quick-button {
      display: none !important;
    }

    .primary-column {
      width: 100%;
    }

    .article .date-label,
    .article .date {
      bottom: -40px;
      right: 0;
      height: auto;
      width: auto;

      span {
        display: inline;

        &:last-child {
          padding-left: 10px;
        }
      }
    }

    .overlay-enabled {
      padding: 0;
      overflow: visible;

      form {
        .page {
          display: none;
        }
      }

      .overlay-wrapper {
        overflow: visible;
        position: relative;
        width: 100%;
      }

      .overlay {
        margin-top: 0;
        width: 100% !important;
        overflow: hidden;
        position: relative;
        top: 15mm;
        left: 10mm;

        &:after {
          content: "";
          display: inherit;
          border-top: 900mm solid white;
          width: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: -1;
        }

        .header {
          height: auto;
          width: 100%;
          border-bottom: 2px solid #999;
          margin-bottom: 6mm;

          .title {
            position: relative;
            top: 0;
            margin-bottom: 3mm;
            color: #999;
          }
        }

        .article {
          padding: 0 0 10mm 0;
        }

        .close,
        .footer {
          display: none;
        }
      }
    }
    // END Print styles
    .region-dantoto {
      background: white !important;
      .no-print {
        display: none;

      }
      #wrapper {
        &:before {
          background: none;
        }

        .col-md-12,
        .col-md-8 {
          width: 100%;
          .style-article {
            img.top {
              width: 66.666666667%;
            }
          }
          .content {
            margin: 1em 0;
          }
        }
      }
    }
  }
}
