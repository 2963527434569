﻿.theme-background-colors() {

  &.color-bingo-lime {
    background-color: @cm-bingo-lime-primary;
  }
  &.color-blitz-bordeaux {
    background-color: @cm-blitz-bordeaux-primary;
  }
  &.color-blitz-white {
    background-color: @cm-blitz-white-primary;
  }
  &.color-casino-gold {
    background-color: @cm-casino-gold-primary;
  }
  &.color-ds-green {
    background-color: @cm-ds-green-primary;
  }
  &.color-ds-yellow {
    background-color: @cm-ds-yellow-primary;
  }
  &.color-dantoto-orange {
    background-color: @cm-dantoto-orange-primary;
  }
  &.color-eurojackpot-gold {
    background-color: @cm-eurojackpot-gold-primary;
  }
  &.color-keno-lightblue {
    background-color: @cm-keno-lightblue-primary;
  }
  &.color-live-casino-gold {
    background-color: @cm-live-casino-gold-primary;
  }
  &.color-lotto-red {
    background-color: @cm-lotto-red-primary;
  }
  &.color-oddset-darkblue {
    background-color: @cm-oddset-darkblue-primary;
  }
  &.color-oddset-grey {
    background-color: @cm-oddset-grey-primary;
  }
  &.color-oddset-orange {
    background-color: @cm-oddset-orange-primary;
  }
  &.color-onsdagslotto-blue {
    background-color: @cm-onsdagslotto-blue-primary;
  }
  &.color-poker-black {
    background-color: @cm-poker-black-primary;
  }
  &.color-quick-violet {
    background-color: @cm-quick-violet-primary;
  }
  &.color-tips-green {
    background-color: @cm-tips-green-primary;
  }
  &.color-cego-greyblue {
    background-color: @cm-cego-greyblue-primary;
  }
  &.color-spillehjoernet-greyblue {
    background-color: @cm-cego-greyblue-primary;
  }
}
