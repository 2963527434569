@import "../variables.less";

//Convert font-size to rem
.font-size(@sizeValue) {
  @remValue: (@sizeValue / 10);
  @pxValue: (@sizeValue);
  font-size: ~"@{pxValue}px";
  font-size: ~"@{remValue}rem";
}

.clear() {
  &:before,
  &:after {
    content: "";
    display: table; 
  }

  &:after {
    clear: both;
  }
}

//CTA button
.btn(@btn-color, @btn-text-color: #fff) {
  background: ~"@{color-@{btn-color}-100}";
  color: @btn-text-color;
  .font-size(10);
  display: inline-block;
  padding: 13px 16px 16px 16px;
  margin-bottom: 8px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    background: ~"@{color-@{btn-color}-200}";
    text-decoration: none;
  }

  /*&:active {
    background: @color-white-100;
    color: @color-gray-100;
  }

  &--inactive {
    background: @color-gray-400;
    color: @color-gray-300;
  }

  &--medium {
  }

  &-small {
  }

  @media screen and (min-width: @medium) {
    .font-size(14);
  }*/
}