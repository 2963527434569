.ds-navigation-choose-games {
  position: fixed;
  z-index: 130;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 35rem;
  background: white;
  transform: translateX(-35rem);
  transition: transform 250ms;
  will-change: transform;
}
.ds-navigation-choose-games a:focus {
  outline: none;
}
.ds-navigation-choose-games a:focus-visible,
.ds-navigation-choose-games *:focus-visible {
  outline: #000000 dotted thin;
  outline-offset: 0.2rem;
}
.ds-navigation-choose-games a:active,
.ds-navigation-choose-games *:active {
  outline: none;
}
.ds-navigation-choose-games a:active,
.ds-navigation-choose-games button:active {
  transform: translateY(0.1rem);
}
.ds-navigation-choose-games.open {
  transition-delay: 100ms;
  transform: translateX(0);
}
.ds-navigation-choose-games.close {
  transform: translateX(-35rem);
  transition: transform 200ms;
  transition-delay: 0ms;
}
@media screen and (min-width: 768px) {
  .ds-navigation-choose-games.close {
    transform: translateX(-65.2rem);
  }
}
@media screen and (min-width: 768px) {
  .ds-navigation-choose-games {
    max-width: 65.2rem;
    transform: translateX(-65.2rem);
  }
}
.ds-navigation-choose-games__close {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 2rem;
  right: 2rem;
  width: 3.6rem;
  height: 3.6rem;
  border: none;
  border-radius: 50%;
  background-color: #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 0.4rem white;
}
@media screen and (min-width: 768px) {
  .ds-navigation-choose-games__close {
    top: 3rem;
    right: 4rem;
  }
}
.ds-navigation-choose-games__close svg {
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  color: #000000;
  width: 2.4rem;
  height: 2.4rem;
}
.ds-navigation-choose-games__inner {
  position: absolute;
  inset: 0;
  padding: 6.6rem 2rem 4rem;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scrollbar-width: thin;
}
@media screen and (min-width: 768px) {
  .ds-navigation-choose-games__inner {
    padding: 8.5rem 4rem 4rem;
    scrollbar-width: unset;
  }
}
.ds-navigation-choose-games__region-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: DanskeSpil, serif;
  font-size: 2.4rem;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 0.024rem;
  color: #222222;
  margin-top: 0.4rem;
  margin-bottom: 1.2rem;
}
@media screen and (min-width: 768px) {
  .ds-navigation-choose-games__region-link {
    font-size: 2.8rem;
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 1216px) {
  .ds-navigation-choose-games__region-link {
    margin-bottom: 1.5rem;
  }
}
.ds-navigation-choose-games__region-link--dli {
  color: #002d70;
}
.ds-navigation-choose-games__region-link--dlo {
  color: #c50005;
}
.ds-navigation-choose-games__region-link:hover {
  text-decoration: none;
}
.ds-navigation-choose-games__region-link-icon {
  line-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  background-color: #FEB700;
  margin-left: 1.3rem;
  margin-bottom: 0.5rem;
}
.ds-navigation-choose-games__region-link-icon svg {
  stroke: #000000;
  width: 1.8rem;
  height: 1.8rem;
}
.ds-navigation-choose-games__brands {
  margin-right: 0.3rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 0.5rem;
  margin-bottom: 1.9rem;
}
@media screen and (min-width: 768px) {
  .ds-navigation-choose-games__brands {
    margin-right: 0;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 0.8rem;
    margin-bottom: 2.6rem;
  }
}
a.ds-navigation-choose-games__brand {
  aspect-ratio: 1.33333333;
  background-color: #ff030a;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 100ms ease 0ms, box-shadow 0s ease;
}
a.ds-navigation-choose-games__brand img {
  color: white;
  width: 70%;
}
a.ds-navigation-choose-games__brand:hover {
  transform: scale(1.1);
  box-shadow: 0 0.8rem 3.2rem rgba(0, 0, 0, 0.32);
}
a.ds-navigation-choose-games__brand:active {
  transform: scale(1.1) translateY(0.1rem);
}
.ds-navigation-choose-games__region-text {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
  color: #828282;
  letter-spacing: 0.01em;
  max-width: 55ch;
}
.ds-navigation-choose-games__other-region {
  background: #eeeeee;
  border-radius: 0.8rem;
  margin-top: 2.7rem;
  padding: 2.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
}
.ds-navigation-choose-games__other-region:hover {
  text-decoration: none;
}
body.is-desktop-detected .ds-navigation-choose-games__other-region {
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .ds-navigation-choose-games__other-region {
    padding: 4rem 10rem;
  }
}
.ds-navigation-choose-games__other-region-title {
  font-family: DanskeSpil, serif;
  font-size: 2.4rem;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 0.024rem;
}
@media screen and (min-width: 768px) {
  .ds-navigation-choose-games__other-region-title {
    font-size: 2.8rem;
  }
}
.ds-navigation-choose-games__other-region--dli .ds-navigation-choose-games__other-region-title {
  color: #002d70;
}
.ds-navigation-choose-games__other-region--dlo .ds-navigation-choose-games__other-region-title {
  color: #c50005;
}
.ds-navigation-choose-games__other-region-text {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
  color: #828282;
  letter-spacing: 0.01em;
}
.ds-navigation-choose-games__other-region-cta {
  margin-top: 1rem;
  color: #000000;
  border-radius: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.08em;
  border: 0.1rem solid rgba(0, 0, 0, 0.4);
  height: 3.6rem;
  min-width: 14rem;
  width: fit-content;
  pointer-events: auto;
}
@media screen and (min-width: 768px) {
  .ds-navigation-choose-games__other-region-cta {
    font-size: 1.4rem;
    padding: 0.5rem 3rem;
    height: 5.4rem;
    min-width: 17rem;
  }
}
.ds-navigation-choose-games__other-region--dli .ds-navigation-choose-games__other-region-cta {
  background-color: #003E99;
  border-color: #003E99;
  color: white;
}
.ds-navigation-choose-games__other-region--dlo .ds-navigation-choose-games__other-region-cta {
  background-color: #c50005;
  border-color: #c50005;
  color: white;
}
hr.ds-navigation-choose-games__separator {
  height: 0.1rem;
  border: none;
  border-top: 0.1rem solid #000000;
  opacity: 0.12;
  margin: 2.1rem 0;
  display: none;
}
hr.ds-navigation-choose-games__separator:has(+ a),
hr.ds-navigation-choose-games__separator:has(+ div) {
  display: block;
}
@media screen and (min-width: 768px) {
  hr.ds-navigation-choose-games__separator {
    margin: 3.5rem 0;
  }
}
@media screen and (min-width: 1216px) {
  hr.ds-navigation-choose-games__separator {
    margin: 3.2rem 0;
  }
}
.ds-navigation-choose-games__more-games {
  display: grid;
  justify-content: center;
  gap: 1rem;
  padding: 3.2rem 2.4rem;
  background-color: #eeeeee;
  border-radius: 0.8rem;
  text-align: center;
}
.ds-navigation-choose-games__more-games-title {
  font-family: DanskeSpil, serif;
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .ds-navigation-choose-games__more-games-title {
    font-size: 2.8rem;
  }
}
.ds-navigation-choose-games__more-games--dli .ds-navigation-choose-games__more-games-title {
  color: #003E99;
}
.ds-navigation-choose-games__more-games--dlo .ds-navigation-choose-games__more-games-title {
  color: #c50005;
}
.ds-navigation-choose-games__more-games-text {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
  color: #828282;
  letter-spacing: 0.01em;
  max-width: 55ch;
}
.ds-navigation-choose-games__more-games-cta {
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  height: 3.6rem;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 1.4rem auto 0;
  padding: 0 5rem;
  border-radius: 5.4rem;
}
@media screen and (min-width: 768px) {
  .ds-navigation-choose-games__more-games-cta {
    height: 5.4rem;
    font-size: 1.4rem;
  }
}
.ds-navigation-choose-games__more-games--dli .ds-navigation-choose-games__more-games-cta {
  background-color: #003E99;
}
.ds-navigation-choose-games__more-games--dlo .ds-navigation-choose-games__more-games-cta {
  background-color: #c50005;
}
