﻿@import (reference) "Mixins/_mixins.less";

.global-campaign-spot-button {
  .cm-button-box();
  display: inline-block;
  width: auto;
  margin: auto;
  padding-right: 20px;
  padding-left: 20px;
  -moz-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.5);
  .cm-corners-all(3px);
  .cm-font-ds-bold();

  &.corporate-yellow {
    border-top: 1px solid rgb(255,219,128);
    background-color: rgb(254,183,0);
    box-shadow: rgba(0,0,0,0.40) 0 2px 2px, rgb(94,39,103) 0 0 0 1px;
    color: rgb(0,75,50);
    text-shadow: 0 -1px 0 rgba(255,255,255,0.50);

    &:hover {
      border-top-color: rgb(255,226,151);
      background-color: rgb(255,197,46);
    }
  }

  &.bingo-green {
    border-top: 1px solid rgb(154,203,34);
    background-color: rgb(128,169,28);
    box-shadow: rgba(0,0,0,0.40) 0 3px 2px, rgb(96,127,21) 0 0 0 1px;
    color: rgb(255,255,255);
    text-shadow: 0 1px 0 rgba(96,127,21,1.00);

    &:hover {
      border-top-color: rgb(158,208,35);
      background-color: rgb(149,195,35);
    }
  }

  &.bingo-dark-green {
    background-color: rgb(58,80,6);
    color: rgb(255,255,255);
    &:hover {
      background-color: rgb(96,127,21);
    }
  }

  &.casino-brown {
    background: rgb(80,62,35);
    color: rgb(255, 255, 255);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
    &:hover {
      background: rgb(80,62,35) + 10%;
    }
  }

  &.casino-purple {
    border-top: 1px solid rgb(158,113,165);
    background-color: rgb(101,32,68);
    box-shadow: rgb(95,39,104) 0 0 0 1px, rgba(0,0,0,0.40) 0 3px 2px;
    color: #fff;
    text-shadow: 0 1px 0 #7c643b;
    &:hover {
      border-top-color: rgb(162,118,169);
      background-color: rgb(151,48,102);
    }
  }

  &.dantoto-green {
    border-top: 1px solid rgb(79,190,113);
    background-color: rgb(71,171,102);
    box-shadow: rgba(0,0,0,0.25) 0 0 0 1px, rgba(0,0,0,0.25) 0 3px 2px;
    color: rgb(255, 255, 255);
    text-shadow: 0 1px 0 rgb(25,72,43);
    &:hover {
      background-color: rgb(79,190,113);
    }
  }

  &.eurojackpot-black {
    .cm-button-color > .eurojackpot-black();
  }

  &.eurojackpot-yellow {
    .cm-button-color > .eurojackpot-yellow();
  }

  &.lotto-yellow {
    .cm-button-color > .lotto-yellow();
  }

  &.quick-purple {
    .cm-button-color > .quick-purple();
  }

  &.quick-dark-purple {
    background-color: rgb(67,21,75);
    color: rgb(255,255,255);
    &:hover {
      background-color: rgb(178,141,184);
    }
  }

  &.oddset-blue {
    background: rgba(0,62,153,1);
    background: linear-gradient(to top, rgba(0,47,113,1), rgba(0,62,153,1));
    color: rgb(255,255,255);
    &:hover {
      background: linear-gradient(to top, rgba(0,47,113,1) + 15%, rgba(0,62,153,1) + 15%);
    }
  }
}

.campaign-spot {
  //margin: 15px 24px 15px 0;
  overflow: hidden;
  position: relative;
  float: left;
  .cm-corners-all(3px);
  .cm-box-shadow();
  min-height: 44px; //temp

  &:last-child {
    //margin-right: 0;
  }

  .aside-column & {
    margin-right: 0;
  }
  //Campaign Spot (bottom)
  &.style-campaign {
    width: 232px;
    height: 310px;
    background-color: transparent;
    background-repeat: no-repeat;

    .footer {
      position: absolute;
      bottom: 20px;
      width: 100%;
      text-align: center;
    }

    .title {
      .cm-font-size(2.5);
      line-height: 28px;
      .cm-font-ds-black();
      width: 100%;
      padding: 0 20px;
      text-align: center;
      position: absolute;
      left: 0;
      top: 50px;
      margin: 0;
    }

    .text {
      .cm-font-size(1.3);
      line-height: 17px;
      .cm-font-ds-book();
      width: 100%;
      padding: 0 20px;
      text-align: center;
      position: absolute;
      left: 0;
      top: 165px;
      margin: 0;

      &.size-small {
        .cm-font-size(1.3);
        line-height: 17px;
        .cm-font-ds-book();
      }

      &.size-large {
        .cm-font-size(1.5);
        line-height: 18px;
        .cm-font-ds-bold();
      }
    }

    .title,
    .text {
      &.color-light {
        color: #fff;
      }

      &.color-quick {
        color: #43154b;
      }

      &.color-dark {
        color: #333333;
      }
    }
  }
}
