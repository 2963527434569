﻿// Defaults
@ds-green-500: #004b32;

@desktop-width: 960px;
@mobile-width: 768px;

@keyframes boostAi-loading {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

@keyframes boostAi-shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.aiboost-button {
  opacity: 0;
  display: none;
}

.aiboost-button--visible {
  transition: opacity 0.55s;
  opacity: 1;
  display: block;
}

.boostAi-circle {
  position: fixed;
  z-index: 20;
  bottom: 2rem;
  right: 2rem;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 1rem 0;
  height: 5rem;
  width: 5rem;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: @ds-green-500 url('/Components/DanskeSpil/Domain/AvalonKundecenter/Graphics/SpriteSheets/Icons/kc-chat.svg') no-repeat 50% 50%;
  background-size: 3rem;

  @media (min-width: @desktop-width) {
    height: 7.5rem;
    width: 7.5rem;
    background-size: unset;
  }

  &:active,
  &:hover {
    animation: boostAi-shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  }

  &[data-enabled="True"] {
    display: flex;
  }

  &.loading {
    animation-name: boostAi-loading;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}
