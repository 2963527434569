﻿@import url("Mixins/_mixins.less");

.modal-wrapper {
  transition: all 0.3s;
  overflow: hidden; //hides the menu on modal box
  
  &:before {
    content: "";
    position: fixed;
    inset: 0;
    z-index: 21;
    display: block;
    width: 100%;
    height: 100%;
    background-color: @ds-black;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &.overlay-active {
    transition: all 0.3s;

    &:before {
      opacity: 0.7;
      pointer-events: all;
    }
  }
}

.is--hidden {
  display: none;
}

.ds-modal-frame {
  position: fixed;
  top: 50vh;
  left: 50vw;
  width: 50%;
  max-width: 80vw;
  min-width: 80vw;
  height: auto;
  z-index: 2000;
  backface-visibility: hidden;
  transform: translateX(-50%) translateY(-50%) scale(1);
  transition: all 0.3s ease;
  border-radius: 0.4rem;
  animation: modalIn;
  animation-duration: 0.3s;

  // Issue with inputs in iOS 11.0 - 11.3 where inputs inside position: fixed had a major layout bug.
  &--ios-position-fix {
    position: absolute;
  }

  @media (min-width: @breakpoint-tablet) {
    max-width: 63rem;
    min-width: 31rem;
  }

  @media (orientation: landscape) {
    max-height: 90vh;
  }
  /* Fix IE bug (https://connect.microsoft.com/IE/feedback/details/796745/mouse-events-are-not-delivered-at-all-anymore-when-inside-an-svg-a-use-is-removed-from-the-dom) */

  svg use {
    pointer-events: none;
  }

  &.remove-overlay {
    transform: translateX(-50%) translateY(-50%) scale(0);
  }

  .ds-modal-wrap {
    background: #f5f5f5;
    border-radius: 0.4rem;
    transition: all 0.3s;
    transform: translateY(-6%);
    opacity: 0;

    .is--shown& {
      transform: translateY(0);
      opacity: 1;
    }

    .is--behind-other-modal& {
      pointer-events: none;
      filter: blur(2px);

      &:before {
        background: #000000;
        content: " ";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 3;
        opacity: 0.3;
        border-radius: 0.6rem;
      }
    }


  }

  @media (min-width: @breakpoint-tablet) {
    min-width: 52rem;
  }

  .ds-modal-html {
    padding-bottom: 3vw;
    @media (min-width: @breakpoint-tablet) {
      padding-bottom: 3.0rem;
    }
  }

  .ds-modal-content {
    padding: 6vw 6vw 6vw;
    text-align: left;
    box-shadow: 0 0 1.3rem rgba(0, 0, 0, 0.2);
    border-radius: 0.4rem;

    @media (orientation: landscape) {
      max-height: 90vh;
      overflow-y: auto;
    }

    @media (min-width: @breakpoint-tablet) {
      padding: 2rem 2rem 2rem;
    }

    .sprite-symbol {
      width: 12vw;
      height: 12vw;
      //transform: translateX(20px);
      margin: -1rem 1rem 0 0;
      @media (min-width: @breakpoint-tablet) {
        width: 5rem;
        height: 5rem;
      }

      &--center {
        display: block;
        margin: 0 auto;
      }
    }

    .modal-header-icon {
      background-color: white;
      border-radius: 50%;
      padding: 1rem;
      border: 1px solid #cfcfcf;
    }

    h1 {
      display: inline;
      .fs-header();
      color: @color-ds-green;
    }

    .center {
      text-align: center;
      display: block;
      margin: 2rem 0 1rem;
    }

    p {
      color: @color-grey-110;
      //margin: 0 0 4vw;
      &:not(:last-of-type) {
        margin: 0 0 4vw;
      }

      @media (min-width: @breakpoint-tablet) {
        margin: 1.5rem 0;
      }
      .fs-copy();

    }

    small {
      display: block;
      margin-top: 1.0rem;
      .fs-small();
      font-weight: normal;
    }

    @media (min-width: @breakpoint-tablet) {
      padding: 4.0rem 2.4rem;
    }

    .button-row {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;

      &--stack {
        flex-direction: column !important;

        .pam__button {
          width: 100%;
          margin: 0.5rem 0;
        }
      }

      @media (min-width: @breakpoint-tablet) {
        flex-direction: row;
      }

      .pam__button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        border-radius: 5rem;
        padding: 0 4vw;
        min-height: 12vw;

        @media (min-width: @breakpoint-tablet) {
          border-radius: 0.4rem;
          padding: 0 3rem;
          min-height: 5rem;

          &--round {
            border-radius: 5rem !important;
          }

          &:not(:last-child) {
            margin-right: 4rem;
          }

          &__text {
            white-space: normal;
          }
        }
      }
    }
  }
}

.ds-modal-link {
  font-size: 3.8vw;
  color: #2f80ed;
  display: block;
  margin: 1.5rem 0 0 0;
  padding: 0.5rem;
  text-align: center;
  outline: none;

  @media (min-width: @breakpoint-tablet) {
    font-size: 1.5rem;
  }

  &:hover, &:focus {
    text-decoration: none;
  }
}

.ds-modal--close {
  position: absolute;
  right: 0;
  margin: 4vw;
  @media (min-width: @breakpoint-tablet) {
    margin: 2.0rem;
  }


  .sprite-symbol {
    width: 4vw;
    height: 4vw;
    @media (min-width: @breakpoint-tablet) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }


  &:hover {
    cursor: pointer;
  }
}

.ds-modal {
  margin: 0 auto;
}

.ds-modal-frame.is--shown ~ .ds-modal-overlay {
  opacity: 1;
  visibility: visible;
}

.ds-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
}


@keyframes modalIn {
  0% {
    transform: translate3d(-50%, -50%, 0) scale(0);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}
