@import (reference) "./Mixins/_mixins.less";

@brand-nav-height-phone: 6.6rem;
@brand-nav-height-phone-with-dli-session-small: 8.4rem;
@brand-nav-height-phone-with-dli-session-medium: 8.8rem;
@brand-nav-height-desktop: 11.6rem;

// for most regions
.header-static-fix {
  height: @brand-nav-height-phone;

  @media (min-width: @nav-medium) {
    height: @brand-nav-height-desktop;
  }
}

// for most regions, when logged in
.is-logged-in:not(.is-context-neutral) .header-static-fix {
  height: @brand-nav-height-phone-with-dli-session-small;

  @media (min-width: @nav-small) {
    height: @brand-nav-height-phone-with-dli-session-medium;
  }

  @media (min-width: @nav-medium) {
    height: @brand-nav-height-desktop;
  }
}

// regions who needs a little space under the navbar (maybe there's no hero or something)
.region-kundecenter {
  .header-static-fix {
    height: (@brand-nav-height-phone + 2rem);

    @media (min-width: @nav-medium) {
      height: (@brand-nav-height-desktop + 2rem);
    }
  }
}

.region-avalon-kundecenter {
  .header-static-fix {
    @media (min-width: @nav-medium) {
      height: 2.6rem;
    }

    @media (min-width: @nav-large) {
      height: 4rem;
    }
  }
}

// for front page regions (desktop and mobile), 
// should not show dli session on phone/tablet, and disappear on desktop 
.region-frontpage {
  .header-static-fix {
    height: @brand-nav-height-phone;

    @media (min-width: @nav-medium) {
      height: 28.6rem; // (corp header + dli/dlo subnav)
    }
  }
}

.region-avalon-frontpage {
  .header-static-fix {
    height: @brand-nav-height-phone;

    @media (min-width: @nav-medium) {
      height: 4.2rem; // matches compliance header
    }
  }
}

.region-retail-account {
  &.PlayerAccountManagementContentPage {
    .header-static-fix {
      @media (min-width: @nav-medium) {
        height: 12rem; // matches compliance header
      }
    }
  }

  &.RetailAccountHiddenMenu {
    .header-static-fix {
      display: none;
    }
  }

  .is-logged-in:not(.is-context-neutral) .header-static-fix {
    height: 6.6rem;

    @media (min-width: @nav-medium) {
      height: 7.6rem; // matches compliance header
    }
  }
  
  .header-static-fix {
    height: @brand-nav-height-phone;

    @media (min-width: @nav-medium) {
      height: 7.6rem !important; // matches compliance header
    }
  }
}
