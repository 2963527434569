﻿@import "./Mixins/_DanskeSpilVariables.less";
@import "./Mixins/_DanskeSpilColorVariables.less";
@import "./Mixins/BreakPoints.less";

@cube-loader-yellow: #feb700;
@cube-loader-green: #004b32;

.ds-cube-loader-wrapper--halfsize {
  transform: scale(0.50,0.50);
}

.ds-cube-loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: 300ms ease-in-out all;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,.7);
    z-index: 1;
  }

  @cube-size: 30rem;
  @cube-face-Z-offset: @cube-size/2;
  @cube-innerFace-Z-offset: @cube-size/2 - .1;

  @keyframes giro {
    0% {
      transform: rotateX(-45deg) rotateY(-20deg);
    }

    50% {
      transform: rotateX(45deg) rotateY(360deg);
    }

    100% {
      transform: rotateX(-45deg) rotateY(700deg);
    }
  }

  @keyframes cubeRotationStop {
    0% {
      transform: rotateX(-45deg) rotateY(700deg);
    }

    100% {
      transform: translateY(-50%) rotateX(-15deg) rotateY(-10deg) scale(.8);
    }
  }

  .ds-cube-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    perspective: 1000px;
    perspective-origin: 50% 50%;
    transform: translateY(-50%) scale(0.25);
    z-index: 1;

    .ds-cube, .cube-face, .cube-inner-face {
      height: @cube-size - 1;
      width: @cube-size - 1;
      box-sizing: content-box;
    }

    .ds-cube {
      transform-style: preserve-3d;
      transform: rotateX(-45deg) rotateY(-20deg);
      margin: @cube-size/2 auto;
      animation: giro 5s .0s infinite linear;

      .cube-inner-face, .cube-face {
        border: 5px solid @cube-loader-yellow;
      }

      .cube-face {
        position: absolute;
        border-radius: 30px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          background: @cube-loader-yellow;
        }

        &:nth-child(1) {
          transform: translateZ(@cube-face-Z-offset);

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            z-index: 1;
          }
        }

        &:nth-child(2) {
          transform: rotateY(180deg) translateZ(@cube-face-Z-offset);
        }

        &:nth-child(3) {
          transform: rotateY(-90deg) translateZ(@cube-face-Z-offset);
        }

        &:nth-child(4) {
          transform: rotateY(90deg) translateZ(@cube-face-Z-offset);
        }

        &:nth-child(5) {
          transform: rotateX(90deg) translateZ(@cube-face-Z-offset);
        }

        &:nth-child(6) {
          transform: rotateX(-90deg) translateZ(@cube-face-Z-offset);
        }

        .square, .diamond, .circle, .triangle {
          position: absolute;
        }

        .square {
          width: 5.8rem;
          height: 5.8rem;
          left: 2.5rem;
          bottom: 2.5rem;
          background-color: @cube-loader-green;
        }

        .diamond {
          width: 5.8rem;
          height: 10rem;
          left: 5.8rem;
          bottom: 10rem;

          &:before, &:after {
            content: ' ';
            width: 0;
            height: 0;
            border-left: 2.9rem solid transparent;
            border-right: 2.9rem solid transparent;
            position: absolute;
            left: 0;
          }

          &:before {
            border-bottom: 5rem solid @cube-loader-green;
            top: 0;
          }

          &:after {
            border-top: 5rem solid @cube-loader-green;
            top: 50%;
          }
        }

        .circle {
          width: 6.7rem;
          height: 6.7rem;
          background-color: @cube-loader-green;
          border-radius: 50%;
          bottom: 17.7rem;
          left: 12rem;
        }

        .triangle {
          width: 0;
          height: 0;
          border-left: 3.5rem solid transparent;
          border-right: 3.5rem solid transparent;
          border-top: 6rem solid @cube-loader-green;
          top: 2.5rem;
          right: 2.5rem;
        }
      }

      .cube-inner-face {
        background: @cube-loader-yellow;
        position: absolute;
        border-radius: 19px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          background: transparent;
        }

        &:nth-child(7) {
          transform: translateZ(@cube-innerFace-Z-offset);
        }

        &:nth-child(8) {
          transform: rotateY(180deg) translateZ(@cube-innerFace-Z-offset);
        }

        &:nth-child(9) {
          transform: rotateY(-90deg) translateZ(@cube-innerFace-Z-offset);
        }

        &:nth-child(10) {
          transform: rotateY(90deg) translateZ(@cube-innerFace-Z-offset);
        }

        &:nth-child(11) {
          transform: rotateX(90deg) translateZ(@cube-innerFace-Z-offset);
        }

        &:nth-child(12) {
          transform: rotateX(-90deg) translateZ(@cube-innerFace-Z-offset);
        }

        &:nth-child(13) {
          transform: rotateX(180deg);
        }

        &:nth-child(14) {
          transform: rotateY(90deg);
        }

        &:nth-child(15) {
          transform: rotateX(90deg);
        }
      }
    }

    @keyframes loaderFacesFadeAnimation {
      100% {
        transform: scale(0);
        opacity: 0;
      }
    }

    @keyframes loaderSadCubeShake {
      0% {
        transform: translateY(-50%) rotateX(-15deg) rotateY(-10deg) scale(.8);
      }

      10% {
        transform: translateY(-50%) rotateX(-35deg) rotateY(-10deg) scale(0.8);
      }

      11% {
        transform: translateY(-50%) rotateX(-35deg) rotateY(-10deg) scale(0.8);
      }

      30% {
        transform: translateY(-50%) rotateX(-35deg) rotateY(30deg) scale(0.8);
      }

      32% {
        transform: translateY(-50%) rotateX(-35deg) rotateY(33deg) scale(0.8);
      }

      60% {
        transform: translateY(-50%) rotateX(-35deg) rotateY(-30deg) scale(0.8);
      }

      62% {
        transform: translateY(-50%) rotateX(-35deg) rotateY(-33deg) scale(0.8);
      }

      68% {
        transform: translateY(-50%) rotateX(-35deg) rotateY(-13deg) scale(0.8);
      }

      70% {
        transform: translateY(-50%) rotateX(-35deg) rotateY(-10deg) scale(0.8);
      }

      80% {
        transform: translateY(-50%) rotateX(-15deg) rotateY(-10deg) scale(.8);
      }

      85% {
        transform: translateY(-50%) rotateX(-10deg) rotateY(-10deg) scale(.8);
      }

      100% {
        transform: translateY(-50%) rotateX(-15deg) rotateY(-10deg) scale(.8);
      }
    }

    @keyframes emojiEyesBlink {
      10% {
        transform: scaleY(1);
      }

      11% {
        transform: scaleY(.2);
      }

      12% {
        transform: scaleY(1);
      }

      13% {
        transform: scaleY(.2);
      }

      14% {
        transform: scaleY(1);
      }

      30% {
        transform: scaleY(1);
      }

      31% {
        transform: scaleY(.2);
      }

      32% {
        transform: scaleY(1);
      }

      70% {
        transform: scaleY(1);
      }

      71% {
        transform: scaleY(.2);
      }

      72% {
        transform: scaleY(1);
      }

      90% {
        transform: scaleY(1);
      }

      91% {
        transform: scaleY(.2);
      }

      92% {
        transform: scaleY(1);
      }
    }

    &.error {
      @keyframes loadeEmojiFadeIn {
        0% {
          transform: scale(.8);
          opacity: 0;
        }

        100% {
          transform: scale(1);
          opacity: 1;
        }
      }

      @keyframes sadEmojiMouthUpperLip {
        0% {
          border-radius: 50%;
          opacity: 1;
        }

        99.9% {
          opacity: 1;
        }

        100% {
          border-radius: 0%;
          opacity: 0;
        }
      }

      @keyframes sadEmojiMouthLowerLip {
        0% {
          border-radius: 0%;
          opacity: 0;
        }

        0.1% {
          opacity: 1;
        }

        100% {
          border-radius: 50%;
          opacity: 1;
        }
      }

      @keyframes sadEmojiMouthLowerLipPulse {
        5% {
          padding-top: 100%;
        }

        7% {
          padding-top: 300%;
        }

        15% {
          padding-top: 300%;
        }

        17% {
          padding-top: 100%;
        }

        20% {
          padding-top: 100%;
        }

        22% {
          padding-top: 300%;
        }

        28% {
          padding-top: 300%;
        }

        30% {
          padding-top: 100%;
        }

        50% {
          padding-top: 100%;
        }

        52% {
          padding-top: 200%;
        }

        72% {
          padding-top: 200%;
        }

        74% {
          padding-top: 100%;
        }
      }

      @keyframes sadEmojiEyes {
        100% {
          transform: translateY(50%);
        }
      }

      @keyframes sadLeftEyebrow {
        100% {
          width: 5rem;
          transform: rotate(15deg) translate(30%, -50%);
        }
      }

      @keyframes sadRightEyebrow {
        100% {
          width: 5rem;
          transform: rotate(-15deg) translate(-30%, -50%);
        }
      }

      @keyframes sadEmojiEyebrowsPulse {
        9% {
          transform: translateY(0);
        }

        11% {
          transform: translateY(200%);
        }

        12% {
          transform: translateY(100%);
        }

        13% {
          transform: translateY(200%);
        }

        15% {
          transform: translateY(0);
        }

        28% {
          transform: translateY(0);
        }

        31% {
          transform: translateY(200%);
        }

        33% {
          transform: translateY(0);
        }

        68% {
          transform: translateY(0);
        }

        71% {
          transform: translateY(200%);
        }

        73% {
          transform: translateY(0);
        }

        89% {
          transform: translateY(0);
        }

        91% {
          transform: translateY(200%);
        }

        93% {
          transform: translateY(0);
        }
      }

      .ds-cube {
        transform: translateY(-50%) rotateX(-15deg) rotateY(-10deg) scale(.8);
        animation: cubeRotationStop .25s forwards ease-out, loaderSadCubeShake 3s .5s infinite linear;

        .cube-face {
          &:nth-child(1) {
            .square, .diamond, .circle, .triangle {
              animation: loaderFacesFadeAnimation .25s forwards linear;
            }
          }

          .emoji-face {
            animation: loadeEmojiFadeIn .15s .15s forwards linear;

            .eyes {
              animation: sadEmojiEyes .1s .5s forwards linear;

              &:before, &:after {
                animation: emojiEyesBlink 20s .6s infinite linear;
              }

              .eyebrows {
                animation: sadEmojiEyebrowsPulse 20s .6s infinite linear;

                &:before {
                  animation: sadRightEyebrow .15s .65s forwards linear;
                }

                &:after {
                  animation: sadLeftEyebrow .15s .65s forwards linear;
                }
              }
            }

            .mouth {
              &:before {
                animation: sadEmojiMouthLowerLip .15s .55s forwards linear, sadEmojiMouthLowerLipPulse 15s .6s infinite linear;
              }

              &:after {
                animation: sadEmojiMouthUpperLip .1s .45s forwards linear;
              }
            }
          }
        }
      }
    }
  }

  .ds-cube-loader-text {
    width: 80%;
    text-align: center;
    color: @rgba-black-050;
    margin: auto;
    margin-top: 1.5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 70%;
    opacity: 0;

    .ds-cube-loader-text-headline {
      font-size: 2rem;
      font-weight: bold;
    }

    .ds-cube-loader-text-description {
      margin-top: 1rem;
      font-size: 1.6rem;
      font-weight: normal;
    }

    &.error {
      transition: opacity .5s ease-in-out;
      opacity: 1;
    }
  }

  .emoji-face {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    .eyes {
      width: 60%;
      height: 5rem;
      position: absolute;
      top: 25%;
      left: 20%;

      &:before, &:after {
        content: '';
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: @cube-loader-green;
        position: absolute;
        top: 0;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }

      .eyebrows {
        width: 100%;
        height: 1rem;
        position: absolute;
        top: -60%;
        left: 0;

        &:before, &:after {
          content: '';
          width: 4rem;
          height: 1rem;
          border-radius: 1rem;
          background-color: @cube-loader-green;
          position: absolute;
          top: 0;
        }

        &:before {
          left: .5rem;
        }

        &:after {
          right: .5rem;
        }
      }
    }

    .mouth {
      width: 40%;
      padding-top: 40%;
      position: absolute;
      bottom: 5%;
      left: 30%;
      overflow: hidden;

      &:before, &:after {
        content: ' ';
        width: 200%;
        padding-top: 100%;
        border-radius: 0;
        border-width: 1.5rem;
        border-style: solid;
        border-color: @cube-loader-green;
        position: absolute;
        left: 50%;
      }

      &:before {
        top: 50%;
        transform: translateX(-50%) translateY(-.75rem);
        opacity: 0;
      }

      &:after {
        border-radius: 50%;
        bottom: 50%;
        transform: translateX(-50%) translateY(.75rem);
      }
    }
  }
}
