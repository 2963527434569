@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";

:root {
  --header-height: calc(~"var(--navigation-compliance-bar-height, 0rem) + var(--navigation-main-menu-height, 0rem) + var(--navigation-sub-menu-height, 0rem)");
}

:root:has(.responsible-gaming__top) {
  --navigation-compliance-bar-height: 3.2rem;

  @media @sm {
    --navigation-compliance-bar-height: 3.6rem;
  }
}

:root:has(.ds-navigation) {
  --navigation-main-menu-height: 6.8rem;

  @media @sm {
    --navigation-main-menu-height: 7.2rem;
  }

  @media @xl {
    --navigation-main-menu-height: 7.6rem;
  }
}

:root:has(.ds-navigation-sub-menu) {
  @media @xl {
    --navigation-sub-menu-height: 5.6rem;
  }
}

:root:has(.responsible-gaming__top.hide) {
  --navigation-compliance-bar-height: 0rem;
}

:root:has(.ds-navigation.hide) {
  --navigation-main-menu-height: 0rem;
  --navigation-sub-menu-height: 0rem;
}

@media (orientation:landscape) {
  :root:has(body.is-mobile-detected):has(.ds-navigation.hide-on-mobile-landscape) {
    --navigation-main-menu-height: 0rem;
    --navigation-sub-menu-height: 0rem;
  }
}
