@top-navigation-small: 950px;
@notifications-small: 768px;
@warning: #d81818;
@grey: #888;
@link-blue: #007dc5;
@amountGreen: #080;
@orange: #fb0;
@white: white;
@black: black;

.region-avalon-livecasino, .spillehjoernet-gamelauncher, .region-avalon-spillehjoernet {
  .reality-check-notification-fallback {
    position: fixed;
    z-index: 1000;
    width: 100%;
    top: 3.6rem;

    @media (min-width: @notifications-small) and (max-width: (@top-navigation-small - 1)) { //tablet
      top: 3.2rem;
    }

    @media all and (max-width: (@notifications-small - 1)) {
      top: 0;
    }
  }
}

.region-avalon-bingo, .region-avalon-bingo-gvc, .region-avalon-bingo-slingo {
  .reality-check-notification-fallback {
    position: fixed;
    z-index: 1000;
    width: 100%;
    top: 3rem;

    @media (min-width: @notifications-small) and (max-width: (@top-navigation-small - 1)) { //tablet
      top: 3.2rem;
    }

  }
}


.region-frontpage .reality-check-static-fix {
  height: 0;
}

// This is a change to Spillehjoernet game launcher
// to move top-menu with name and icons down under notification 
.game-overlay {
  position: relative;
}

header.top-navigation .reality-check-notification {
  transition: all 0.4s ease;
  z-index: 2;
  position: relative;
}

// Units in this file are in px to keep same size across different places, such as:
// 1) numbergames use font-size 50%, when user has windows zoom 125%
// 2) non-numbergames use font-size 62.5%, also when user has windows zoom 125%
// 3) gamelauncher use font-size 100%
// Pixels don't care about scaling like rem and vw.

.reality-check-notification__content {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0;
  box-sizing: border-box;
  background: @white;
  color: @black;
  justify-content: center;
  font-size: 11px;
  line-height: 1.2;

  @media all and (min-width: @top-navigation-small) {
    flex-direction: row;
    align-items: center;
    font-size: 13px;
  }

  .reality-check-notification__text {
    margin: 0.8rem 0;
    position: relative;

    .reality-check-notification__content--normal&,
    .reality-check-notification__content--warning& {
      padding-left: 45px;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        content: "";
        width: 40px;
        height: 100%;
        background-image: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/info.svg');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 40px 40px;
      }
    }

    a {
      color: @link-blue;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .amount-plus {
      color: @amountGreen;
    }

    .amount-minus {
      color: @warning;
    }
  }

  .reality-check-notification__button-container {
    display: flex;
    flex-direction: row-reverse;

    @media (min-width: @top-navigation-small) {
      flex-direction: row;
      margin-right: 10px;
    }
  }

  .reality-check-notification__button {
    box-sizing: border-box;
    min-height: 30px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    font-weight: bold;
    border-radius: 0;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid transparent;
    color: @black;

    @media (min-width: @top-navigation-small) {
      margin-left: 1rem;
      padding: 5px 10px;
      border: 1px solid transparent;
      border-radius: 4px;
    }
  }

  .reality-check-notification__button--continue {
    text-decoration: none;
    background: @orange;
    border-color: @orange;

    @media (min-width: @top-navigation-small) {
      font-weight: bold;
    }

    .reality-check-notification__content--warning& {
      background: @white;
      border-color: @grey;
    }
  }

  .reality-check-notification__button--cancel {
    background: @white;
    border-color: @grey;

    @media (min-width: @top-navigation-small) {
      text-transform: none;
      font-weight: normal;

      > div {
        white-space: nowrap;
      }
    }
  }
}
