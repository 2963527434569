@font-face {
  font-family: "AvenirNext";
  src: url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextLTW05-HeavyCond.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextLTW05-HeavyCond.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir-Next';
  src: url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextW05-Regular.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextLTW05-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNextCondensed';
  src: url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextLTW05-HeavyCond.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextLTW05-HeavyCond.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir-Next';
  src: url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextW05-Italic.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/AvenirNext/AvenirNextW05-Italic.woff") format("woff");
  font-style: italic;
}