﻿@import (reference) "../../../../DanskeSpil/Domain/AvalonComponents/Styles/_imports";

.blocked-cookies-handler {
  position: fixed;
  z-index: 1000000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: @ds-white;
  padding: 1.5rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.3;
  background-size: cover;
}

.blocked-cookies-handler__cta {
  cursor: pointer;
  min-height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 2rem;
  background: @ds-green;
  color: @ds-white;
  font-size: 1.3rem;
  font-weight: 700;
  border-radius: 0.4rem;
}
