.ds-navigation-account-menu {
  position: fixed;
  z-index: 130;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 35rem;
  background-color: white;
  transform: translateX(35rem);
  transition: transform 250ms;
  will-change: transform;
}
.ds-navigation-account-menu a:focus {
  outline: none;
}
.ds-navigation-account-menu a:focus-visible,
.ds-navigation-account-menu *:focus-visible {
  outline: #000000 dotted thin;
  outline-offset: 0.2rem;
}
.ds-navigation-account-menu a:active,
.ds-navigation-account-menu *:active {
  outline: none;
}
@media screen and (min-width: 768px) {
  .ds-navigation-account-menu {
    max-width: 52rem;
    transform: translateX(65.2rem);
  }
}
.ds-navigation-account-menu.open {
  transition-delay: 100ms;
  transform: translateX(0);
}
.ds-navigation-account-menu.close {
  transform: translateX(35rem);
  transition: transform 200ms;
  transition-delay: 0ms;
}
@media screen and (min-width: 768px) {
  .ds-navigation-account-menu.close {
    transform: translateX(65.2rem);
  }
}
.ds-navigation-account-menu a:active,
.ds-navigation-account-menu button:active {
  transform: translateY(0.1rem);
}
.ds-navigation-account-menu__top {
  position: absolute;
  z-index: 1;
  inset: 0;
  bottom: auto;
  height: 7.6rem;
  background: white;
}
.ds-navigation-account-menu__close {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 2rem;
  right: 2rem;
  width: 3.6rem;
  height: 3.6rem;
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 0.4rem transparent;
}
@media screen and (min-width: 768px) {
  .ds-navigation-account-menu__close {
    top: 3rem;
    right: 4rem;
  }
}
.ds-navigation-account-menu__close svg {
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  color: #000000;
  width: 2.4rem;
  height: 2.4rem;
}
.ds-navigation-account-menu__inner {
  position: absolute;
  z-index: 0;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2rem 4rem;
  margin-top: 7.6rem;
  overflow-y: auto;
  scrollbar-width: thin;
}
@media screen and (min-width: 768px) {
  .ds-navigation-account-menu__inner {
    padding: 0 4rem 4rem;
    margin-top: 9rem;
    scrollbar-width: unset;
  }
}
.ds-navigation-account-menu__buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
}
.ds-navigation-account-menu__buttons--stacked {
  flex-direction: column;
}
.ds-navigation-account-menu__button {
  color: #000000;
  width: 100%;
  height: 5.6rem;
  border-radius: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.08em;
  border: 0.1rem solid rgba(0, 0, 0, 0.4);
}
.ds-navigation-account-menu__button:hover {
  text-decoration: none;
}
.ds-navigation-account-menu__buttons--small .ds-navigation-account-menu__button {
  height: 3.6rem;
}
.ds-navigation-account-menu__buttons--narrow .ds-navigation-account-menu__button {
  max-width: 20rem;
}
@media screen and (min-width: 768px) {
  .ds-navigation-account-menu__button {
    height: 5.4rem;
    font-size: 1.4rem;
  }
  .ds-navigation-account-menu__buttons--small .ds-navigation-account-menu__button {
    height: 5.4rem;
  }
  .ds-navigation-account-menu__buttons--narrow .ds-navigation-account-menu__button {
    max-width: 24rem;
  }
}
.ds-navigation-account-menu__button--primary {
  background-color: #FEB700;
  border: 0.1rem solid #FEB700;
  color: #000000;
}
.ds-navigation-account-menu__region--dli .ds-navigation-account-menu__button--primary {
  background-color: #003E99;
  border-color: #003E99;
  color: white;
}
.ds-navigation-account-menu__region--dlo .ds-navigation-account-menu__button--primary {
  background-color: #c50005;
  border-color: #c50005;
  color: white;
}
