﻿@import (reference) "Mixins/_mixins.less";

.generic-overlay {

  .genovl-aside-column {
    float: left;
    position: relative;
    z-index: 20;
    width: (216/(980 - 2*30)) * 100%; 
  }

  .aside-navigation {
    margin: 0;
    padding: 0;
    list-style-type: none;
    .cm-corners-all(3px);

    .item {
      border-top: 1px solid #d6d6d6;

      &:first-child {
        border-top: none;
        .cm-corners-top(3px);
      }

      &:last-child {
        .cm-corners-bottom(3px);
      }

      &:nth-child(odd) {
        background-color: white;
      }

      &:nth-child(even) {
        background-color: white;
      }

      .link {
        position: relative;
        display: block;
        padding: 13px 32px 14px 20px;
        .cm-font-ds-bold();
        .cm-font(1.5, 1.8);
        color: black;

        &:hover {
          text-decoration: none;
        }

        &:after {
          content: "";
          position: absolute;
          right: 10px;
          top: 50%;
          width: 12px;
          height: 21px;
          margin-top: -11px;
          .ds-global-sprite(-300px, -200px);
          .cm-opacity(.4); 
        }

        &.selected {
          color: white;
        }
      }
    }
  }

  &.color-bingo-lime {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #e8f8c2;
        }

        &.selected {
          background-color: #3a500d;
        }
      }
    }
  }

  &.color-casino-gold {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #e6dac4;
        }

        &.selected {
          background-color: #4f3f26;
        }
      }
    }
  }

  &.color-ds-green {
    .header .close {
      background-color: #feb700;
    }

    .aside-navigation {
      .item {
        &:hover {
          background-color: #c7e3d9;
        }

        &.selected {
          background-color: #feb700;
        }
      }
    }
  }

  &.color-dantoto-orange {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #ddc8bb;
        }

        &.selected {
          background-color: #792c08;
        }
      }
    }
  }

  &.color-eurojackpot-gold {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #e3c7c8;
        }

        &.selected {
          background-color: #430003;
        }
      }
    }
  }

  &.color-keno-lightblue {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #bcddeb;
        }

        &.selected {
          background-color: #004d8f;
        }
      }
    }
  }

  &.color-live-casino-gold {
    .aside-navigation {
      .item {
        background-color: #a78952;
        border-top: 1px solid #846b3f;

        &:hover {
          background-color: #bea069;
        }

        &.selected {
          background-color: #503e23;
        }
      }
    }
  }

  &.color-lotto-red {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #f1b6b7;
        }

        &.selected {
          background-color: #640003;
        }
      }
    }
  }

  &.color-oddset-darkblue {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #b2c9eb;
        }

        &.selected {
          background-color: #001c47;
        }
      }
    }
  }

  &.color-poker-black {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #d2d2d2;
        }

        &.selected {
          background-color: #4d4d4d;
        }
      }
    }
  }

  &.color-quick-violet {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #dcc0e0;
        }

        &.selected {
          background-color: #3a1f3f;
        }
      }
    }
  }

  &.color-tips-green {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #ade6d0;
        }

        &.selected {
          background-color: #00472c;
        }
      }
    }
  }

  &.color-cego-greyblue {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #c4d2e3;
        }

        &.selected {
          background-color: #3a4657;
        }
      }
    }
  }
  &.color-spillehjoernet-greyblue {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #c4d2e3;
        }

        &.selected {
          background-color: #3a4657;
        }
      }
    }
  }

  &.color-onsdagslotto-blue {
    .aside-navigation {
      .item {
        &:hover {
          background-color: #b2c9eb;
        }

        &.selected {
          background-color: #001c47;
        }
      }
    }
  }

}
