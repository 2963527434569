@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Variables/colors";
@import (reference) "./NavigationMixins";

.ds-navigation-main-menu {
  .ds-navigation__focus-outline();
  padding: 0 0.4rem;
  display: grid;
  grid-template-columns: minmax(5.5rem, auto) 1fr auto;
  grid-template-rows: var(--navigation-main-menu-height, 6.8rem);
  grid-template-areas: "games logo buttons";
  gap: 0.2rem;
  align-items: center;

  a:active,
  button:active {
    transform: translateY(0.1rem);
  }

  @media @sm {
    gap: 0.4rem;
    padding: 0 1.4rem;
    grid-template-rows: var(--navigation-main-menu-height, 7.2rem);
  }

  @media @xl {
    padding: 0 1.5rem 0 1.4rem;
    grid-template-columns: 7.8rem 16.5rem 1fr minmax(calc(7.8rem + 0.4rem + 16.5rem), auto);
    grid-template-rows: var(--navigation-main-menu-height, 7.6rem);
    grid-template-areas: "games logo top-menu buttons";
  }

  &--no-choose-games {
    grid-template-areas: "logo buttons";
    grid-template-columns: 1fr auto;

    @media @xl {
      grid-template-areas: "logo top-menu buttons";
      grid-template-columns: 16.5rem 1fr 16.5rem;
    }
  }

  &--no-account-menu {
    grid-template-columns: minmax(5.5rem, auto) 1fr auto;

    @media @xl {
      grid-template-areas: "games logo top-menu buttons";
      grid-template-columns: 7.8rem 16.5rem 1fr calc(7.8rem + 0.4rem + 16.5rem);
    }
  }

  &--no-account-menu&--no-choose-games {
    grid-template-columns: 1fr auto;

    @media @xl {
      grid-template-areas: "logo top-menu buttons";
      grid-template-columns: 16.5rem 1fr 16.5rem;
    }
  }

  &--account-page {
    grid-template-columns: minmax(5.5rem, auto) 1fr auto;
    grid-template-areas: "games account-brand buttons";

    @media @xl {
      grid-template-columns: 7.8rem 1fr 7.8rem;
      grid-template-areas: "games account-brand buttons";
    }
  }
}

.ds-navigation-main-menu__account-brand {
  grid-area: account-brand;
  display: grid;
  gap: 0.5rem;
  margin: auto;
  white-space: nowrap;
}

.ds-navigation-main-menu__account-brand-title {
  padding: 0 0.8rem;
  background: @ds-white;
  border-radius: 0.6rem;
  height: 2.6rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
}

.ds-navigation-main-menu__account-brand-subtitle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 400;
  color: @ds-white;
}

.ds-navigation-main-menu__buttons {
  grid-area: buttons;
  display: flex;
  justify-content: flex-end;
  gap: 0.2rem;

  @media @sm {
    gap: 0.4rem;
  }
}

.ds-navigation-main-menu__toggle {
  font-size: 1.2rem;
  line-height: 1.333;
  font-weight: 600;
  color: @ds-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  height: 6rem;
  width: 100%;
  border: none;
  border-radius: 0.6rem;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 200ms ease-out;
  white-space: nowrap;
  padding: 0 0.6rem;

  &:hover {
    text-decoration: none;
  }

  @media @sm {
    padding: 0.1rem 1.2rem 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  @media @xl {
    font-size: 1.4rem;
    gap: 0.3rem;
  }


  &--account {
    grid-area: account;

    @media @xl {
      width: 7.8rem;
    }

    .ds-navigation-main-menu--no-account-menu & {
      display: none;
    }
  }
  
  body.is-logged-in &--account {
    @media @sm {
      &:hover {
        background-color: transparent;
      }
    }
  }

  &--burger {
    grid-area: burger;

    @media @xl {
      display: none;
    }
  }

  &--games {
    grid-area: games;

    @media @sm {
      padding: 0 0.6rem;
    }

    @media @xl {
      a {
        font-size: 1.2rem;
      }
    }

    .ds-navigation-main-menu--no-choose-games & {
      display: none;
    }
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    stroke: @ds-white;
  }
}

.ds-navigation-main-menu__logo {
  grid-area: logo;
  overflow: hidden;
  display: grid;
  align-items: center;
  width: fit-content;
  padding: 0.5rem 1rem;
  height: 6rem;
  border-radius: 0.6rem;

  @media @xl {
    margin-left: 0;
  }

  picture, img {
    line-height: 0;
    height: 5rem;
  }
}
