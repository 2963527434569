﻿.cm-lotteri-text() {
  content: "Udbydes af Danske Lotteri Spil A/S";
}
.cm-licens-text() {
  content: "Udbydes af Danske Licens Spil A/S";
}

.cm-font(@fontSizeValueInREM, @lineHeightValueInREM) {
  font-size: ~"@{fontSizeValueInREM}rem";
  line-height: ~"@{lineHeightValueInREM}rem";
}

.cm-font(@fontSizeValueInREM, @lineHeightValueInREM, @FFlineHeightValueInREM) {
  @media screen and (-moz-images-in-menus:0) {
    line-height: ~"@{FFlineHeightValueInREM}rem";
  }
}

.cm-font-size(@sizeValueInREM) {
  font-size: ~"@{sizeValueInREM}rem";
}

.cm-line-height(@sizeValueInREM) {
  line-height: ~"@{sizeValueInREM}rem";
}

.cm-line-height(@sizeValueInREM, @FFsizeValueInREM) {
  line-height: ~"@{sizeValueInREM}rem";

  @media screen and (-moz-images-in-menus:0) {
    line-height: ~"@{FFsizeValueInREM}rem";
  }
}

@openSansFamily: "Open Sans", Helvetica, Arial, sans-serif;

.cm-text-shadow(@x: 1px, @y: 1px, @blur: 1px, @color: rgba(0, 0, 0, 0.50)) {
  text-shadow: @x @y @blur @color;
}

// Fonts

.cm-font-anton() {
  font-family: 'Anton', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.cm-font-ds-book() {
  font-family: @openSansFamily;
  font-weight: normal;
  font-style: normal;
}

.cm-font-ds-book-italic() {
  font-family: @openSansFamily;
  font-weight: normal;
  font-style: italic;
}

.cm-font-ds-bold() {
  font-family: @openSansFamily;
  font-weight: 700;
  font-style: normal;
}

.cm-font-ds-bold-italic() {
  font-family: @openSansFamily;
  font-weight: 700;
  font-style: italic;
}

.cm-font-ds-black() {
  font-family: @openSansFamily;
  font-weight: 800;
  font-style: normal;
}

.cm-font-ds-black-italic() {
  font-family: @openSansFamily;
  font-weight: 800;
  font-style: italic;
}

.cm-font-ds-sans-bold() {
  // OLD Roboto Condensed, now just fallback to DS default font (Open Sans)
  font-weight: 700;
  font-style: normal;
}

.cm-font-impact() {
  font-family: AvenirNext, Impact, 'Anton Regular', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.cm-font-avenir() {
  font-family: AvenirNext, Impact, 'Anton Regular', Helvetica, Arial, sans-serif;
  font-weight: 900;
  font-style: normal;
}


// Custom Icons:
.cm-custom-icon() {
  font-family: 'DanskeSpil-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// Input placeholder color

.cm-placeholder-color(@color) {
  &:-moz-placeholder {
    color: @color;
  }
  &:-ms-input-placeholder {
    color: @color;
  }
  &::-webkit-input-placeholder {
    color: @color;
  }
}

.cm-rte-styling() { // ritch text style from campaign
  p {
    margin: 0 0 0.8em;
  }

  h1, h2, h3, h4 {
    margin: 0;
  }

  h1 {
    .cm-font-size(2.0);
    margin-bottom: 1.0em;
  }

  h2 {
    .cm-font-size(1.8);
    margin-bottom: .5em;
  }

  h3 {
    .cm-font-size(1.5);
  }

  h4 {
    .cm-font-size(1.5);
    font-style: italic;
  }

  a {
    color: #333;
    text-decoration: underline;

    .cm-brand-buttons();

  }
}
