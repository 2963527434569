:root {
  --bezierEaseInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body {
  padding-top: var(--header-height, 0rem);
}
body.no-scroll {
  overflow: hidden;
  width: 100%;
}
.ds-navigation {
  position: fixed;
  inset: 0;
  bottom: unset;
  z-index: 100;
  overflow: visible;
  transform: unset;
  will-change: transform;
  transition-property: transform;
  transform-style: preserve-3d;
  padding-top: var(--navigation-compliance-bar-height, 0rem);
}
.ds-navigation a:focus {
  outline: none;
}
.ds-navigation a:focus-visible,
.ds-navigation *:focus-visible {
  outline: #000000 dotted thin;
  outline-offset: 0.2rem;
}
.ds-navigation a:active,
.ds-navigation *:active {
  outline: none;
}
.ds-navigation--scroll-down {
  transition-delay: 100ms;
  transition-duration: 500ms;
  /* When scrolling down, hiding the nav bar. */
  transform: translateY(-100%);
  transition-timing-function: var(--bezierEaseInOutQuad);
}
.ds-navigation--scroll-up {
  transition-delay: 200ms;
  transition-duration: 450ms;
  /* When scrolling up, showing the nav bar. */
  transform: translateY(0);
  transition-timing-function: var(--bezierEaseInOutQuad);
}
.ds-navigation.hide {
  display: none;
}
@media (orientation: landscape) {
  body.is-mobile-detected .ds-navigation.hide-on-mobile-landscape {
    display: none;
  }
}
