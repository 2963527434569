﻿@import url("variables.less");

.simple-menu {
  // DESKTOP overwrites and adjustments:
  @media all and (min-width: @nav-medium) { 
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
    padding-left: 20px;
    box-sizing: border-box !important;

    html[class] & {
      .sub-navigation-inner {
        max-width: 1440px;
        margin: 0 auto;
        box-sizing: border-box !important;
      }
      .navigation-list {
        padding-left: 0;
      }
      
    }
  }

  @media all and (min-width: @nav-medium) and (max-width: (@nav-large - 1)) {
    html[class] & {
      .sub-navigation-inner {
        padding-left: 172px; // align simple-menu with corporate- and brand-navigation
      }
    }
  }
  @media all and (min-width: @nav-medium) and (min-width: @nav-large) {
    html[class] & {
      .sub-navigation-inner {
        padding-left: 166px; // align simple-menu with corporate- and brand-navigation
      }
    }
  }
  
}
