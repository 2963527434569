@font-face {
  font-family: 'DanskeSpil-icons';
  src: url(/Components/Common/Framework/Design/Fonts/Icomoon/Icon-fonts/DanskeSpil-icons.woff) format('woff'), url(/Components/Common/Framework/Design/Fonts/Icomoon/Icon-fonts/DanskeSpil-icons.ttf) format('truetype'), url(/Components/Common/Framework/Design/Fonts/Icomoon/Icon-fonts/DanskeSpil-icons.svg?-201410091337#DanskeSpil-icons) format('svg');
  font-weight: normal;
  font-style: normal;
}
.icon-star:before,
.icon-angle-right:before,
.icon-angle-up:before,
.icon-star:after,
.icon-angle-right:after,
.icon-angle-up:after {
  font-family: 'DanskeSpil-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-star:before {
  content: "\e301";
}
.icon-angle-right:before {
  content: "\e601";
}
.icon-angle-up:before {
  content: "\e602";
}
