@import url("./Mixins/_mixins.less");

// If context neutral (such as page https://town23.danskespil.dk/spil-med-omtanke), then always hide user-bar
body.is-context-neutral .user-bar  {
  display: none !important;
}

//This is global styles for the header
.header.top-navigation {
  .user-bar {
    float: right;
    display: inline-block;

    .is-logged-in & {
      .has-shown-subnav {
        .mega-menu {
          @media all and (min-width: @nav-medium) {
            transform: translateY(100%) scaleY(1);
            z-index: 1;
          }
        }
      }
    }

    &__navigation {
      width: auto;
      height: 100%;
      display: inline-block;
    }

    &__list {
      margin: 0;
      padding: 0;
      max-height: 100%;
    }

    .choose-account {
      display: none;
    }
  }

  .accountmenu {
    @media all and (min-width: @nav-medium) {
      .mobile-only {
        display: none;
      }
    }

    &__group {
      display: inline-block;
    }

    &__item {
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 600;
      padding: 1.25rem 1rem .9rem;
      line-height: 1.3;
      transition: .2s;
      color: #fff;
      text-decoration: none;
    }

    a.accountmenu__item {
      cursor: pointer;
    }

    .choose-account-btn {
      cursor: pointer;
      display: none;

      @media all and (max-width: (@nav-medium - 1)) {
        float: right;
        font-size: 3vw;
        padding-top: 6vw;
        padding-right: 3.5vw;
      }
    }

    .info-bar-item--username {
      display: block;
      font-size: 1.2rem;
      padding: 0 1rem 0;
    }

    .info-bar-item--account {
      display: none;
      font-size: 1.2rem;
      padding: .45rem 1rem 0;
    }

    .info-bar-item--ballance {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1px;
        height: 2.5rem;
        background: #fff;
        opacity: 0.1;
        display: none;

        .is-logged-in & {
          display: block;
        }
      }
    }

    .signup-btn {
      position: relative;
      background-color: @color-corporate-yellow;
      color: black;
      border-radius: 0.3rem;
      margin-right: 1rem;
      padding: 0.5rem 1rem;
      margin-top: 0.6rem;
    }

    .login-btn {
      color: white;
      border: 0.2rem solid white;
      border-radius: 0.3rem;
      padding: 0.3rem 0.8rem;
      margin-top: 0.6rem;
    }

    .profile-btn {
      position: relative;
      top: 0.6rem;
      float: left;
      height: 2.8rem;
      width: 2.8rem;
      padding: 0;
      display: none;
      background: #fff;
      border-radius: 50%;
      padding-top: 1rem;

      &.mobile-only {
        display: none;
      }

      &:before {
        content: ' ';
        cursor: pointer;
        width: 1.3rem;
        height: 1.6rem;
        position: absolute;
        top: 50%;
        left: 49.5%;
        transform: translate(-50%,-50%);
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        //background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAxMC43IDEwLjgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwLjcgMTAuODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiNGRkYiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTAsOC42QzkuOSw4LjUsNy4zLDcuMiw1LjMsNy4yUzAuOCw4LjUsMC43LDguNkMwLjUsOC43LDAuMyw4LjksMC4zLDkuMXYwLjZjMCwwLjMsMC4zLDAuNiwwLjYsMC42aDguOGMwLjMsMCwwLjYtMC4zLDAuNi0wLjZWOS4xQzEwLjMsOC45LDEwLjIsOC43LDEwLDguNnogTTIuOCw0LjFWMi45YzAtMS40LDEuMS0yLjUsMi41LTIuNXMyLjUsMS4xLDIuNSwyLjV2MS4yYzAsMS40LTEuMSwyLjUtMi41LDIuNVMyLjgsNS41LDIuOCw0LjF6Ii8+PC9zdmc+DQo=');
      }
    }
  }
}

//When we're logged in
.is-logged-in .header.top-navigation {

  .profile-btn {
    display: inline-block;
  }

  .login-btn,
  .signup-btn,
  .choose-account-btn {
    display: none;
  }
}


//Colorizing
.mobile-user-info {
  .account-name {
    font-weight: bold;
    display: none;
  }
}

.dli {
  .mobile-user-info {
    .account-name.dli {
      color: #263863;
      display: inline-block;
    }
  }
}

.dlo {
  .mobile-user-info {
    .account-name.dlo {
      color: #8f0b0f;
      display: block;
    }
  }
}


//The bar under topnavigation in mobile showing user account information
.mobile-user-info {
  clear: both;
  width: 100%;
  display: none;
  background-color: #fff;
  padding: 0 0.8rem;
  position: relative;

  @media all and (min-width: @nav-small) and (max-width: (@nav-medium - 1)) {
    padding: 0 1rem;
  }

  .is-logged-in & {
    display: block;

    @media all and (min-width: @nav-medium) {
      display: none;
    }
  }

  body.is-context-neutral &  {
    display: none !important;
  }

  &:after {
    display: table;
    content: ' ';
    clear: both;
  }

  .js-balance-user {
    font-weight: bold;
    text-align: right;
    white-space: nowrap;
  }

  &__center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__left,
  &__right,
  &__center {
    display: inline-block;
    vertical-align: middle;

    p,
    a {
      margin: 0;
      padding: 0.4rem 0;
      color: #000;
      font-size: 1rem;
      line-height: 1;

      @media all and (min-width: @nav-small) and (max-width: (@nav-medium - 1)) {
        font-size: 14px;
      }
    }
  }

  &__left {
    float: left;
    @media all and (max-width: (@nav-small - 1)) {
      line-height: 0.8;
    }
  }

  &__right {
    float: right;
  }
}


body.region-retail-account {
  &.is-logged-in {
    .user-bar {
      display: none !important;
    }
  }

  // Don't show default account register flow button
  .create-btn {
    display: none;
  }
}