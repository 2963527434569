﻿@import (reference) "Mixins/_mixins.less";

.generic-overlay {

  .genovl-article {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5);
    border-radius: 0 0 3px 3px;

    .genovl-content {
      padding: 30px;
    }

    .genovl-headline {
      margin: 5px 0 21px;
      .cm-font-ds-black();
      .cm-font-size(3.0);
    }

    .genovl-text {
      .cm-font-size(1.4);
      .rte-styling();
    }

    &:first-child {
      min-height: 400px;
      border-radius: 3px;
    }
  }
}
