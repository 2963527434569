@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Variables/colors";
@import (reference) "./NavigationMixins";

.ds-navigation-account-menu {
  .ds-navigation__focus-outline();
  position: fixed;
  z-index: 130;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 35rem;
  background-color: @ds-white;
  transform: translateX(35rem);
  transition: transform 250ms;
  will-change: transform;

  @media @sm {
    max-width: 52rem;
    transform: translateX(65.2rem);
  }

  &.open {
    transition-delay: 100ms;
    transform: translateX(0);
  }

  &.close {
    transform: translateX(35rem);
    transition: transform 200ms;
    transition-delay: 0ms;

    @media @sm {
      transform: translateX(65.2rem);
    }
  }

  a:active,
  button:active {
    transform: translateY(0.1rem);
  }
}

.ds-navigation-account-menu__top {
  position: absolute;
  z-index: 1;
  inset: 0;
  bottom: auto;
  height: 7.6rem;
  background: @ds-white;
}

.ds-navigation-account-menu__close {
  .ds-navigation__menu-close();
}

.ds-navigation-account-menu__inner {
  position: absolute;
  z-index: 0;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2rem 4rem;
  margin-top: 7.6rem;
  overflow-y: auto;
  scrollbar-width: thin;

  @media @sm {
    padding: 0 4rem 4rem;
    margin-top: 9rem;
    scrollbar-width: unset;
  }
}

.ds-navigation-account-menu__buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;

  &--stacked {
    flex-direction: column;
  }
}

.ds-navigation-account-menu__button {
  color: @ds-black;
  width: 100%;
  height: 5.6rem;
  border-radius: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.08em;
  border: 0.1rem solid rgba(0, 0, 0, 0.40);

  &:hover {
    text-decoration: none;
  }

  .ds-navigation-account-menu__buttons--small & {
    height: 3.6rem;
  }

  .ds-navigation-account-menu__buttons--narrow & {
    max-width: 20rem;
  }

  @media @sm {
    height: 5.4rem;
    font-size: 1.4rem;

    .ds-navigation-account-menu__buttons--small & {
      height: 5.4rem;
    }

    .ds-navigation-account-menu__buttons--narrow & {
      max-width: 24rem;
    }
  }

  &--primary {
    background-color: @ds-yellow;
    border: 0.1rem solid @ds-yellow;
    color: @ds-black;

    .ds-navigation-account-menu__region--dli & {
      background-color: @ds-dark-blue;
      border-color: @ds-dark-blue;
      color: @ds-white;
    }

    .ds-navigation-account-menu__region--dlo & {
      background-color: @ds-red;
      border-color: @ds-red;
      color: @ds-white;
    }
  }
}
