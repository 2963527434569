﻿@import url("variables.less");
@import url("./Mixins/_mixins.less");

// (Game-overview is taylor made for the Bingo brand)
.nav-game-overview {
  .clear();
  width: 100%;

  @media all and (min-width: @nav-medium) {
    // Overwrite script and mobile legacy styling:
    padding-left: 0 !important;
    margin-left: auto !important;
    margin-right: auto;
    display: block;
    max-width: @nav-large;
    padding-top: 2.2rem;
    padding-bottom: 1.7rem !important;
    min-width: @nav-medium;
  }

  &:after {
    @media all and (min-width: @nav-medium) {
      content: "";
      display: table;
      clear: both;
    }
  }

  &__item {
    float: left;
    text-align: center;
    overflow: hidden;
    position: relative;

    @media all and (min-width: @nav-medium) {
      width: 16.6666%;
      min-width: 170px;
      height: 150px;
    }
  }

  &__link {
    text-align: center;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1;
    outline: none;
    color: #000;
    padding: 15px 25px;
    display: inline-block;

    @media all and (min-width: @nav-medium) {
      &:hover {
        opacity: 1 !important;

        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &__image {
    position: relative;
    transition: opacity .5s linear;

    @media all and (min-width: @nav-medium) {
      display: block;
      margin: 0 auto 5px;
      height: 99px;
      width: auto;
      transition: .2s;
    }

    &.countdown-inactive {
      opacity: .4;
    }
  }

  &__container {
    position: relative;
  }

  &__title {
    font-weight: 100;

    &.countdown-inactive {
      @media all and (max-width: @nav-medium - 1) {
        opacity: .5;
      }
    }
  }

  @media all and (max-width: @nav-medium - 1) {
    &__item {
      font-size: 4vw;
      display: block;
      width: 100%;
      text-align: left;
    }

    &__link {
      padding: 4vw 4vw 4vw 6vw;
      color: #000;
      font-weight: 600;
      display: inline-block;
    }

    .nav-game-overview__title {
      font-weight: 600;
      font-size: 4vw;
    }

    &__image {
      display: none;
    }
  }

  @media all and (min-width: @nav-small) and (max-width: (@nav-medium - 1)) {
    &__item {
      font-size: 3vw;
    }

    &__link {
      padding: 3vw 3vw 3vw 6vw;
    }

    .nav-game-overview__title {
      font-size: 3vw;
    }
  }

  .countdown-timer {
    position: absolute;
    top: 60%;
    right: -15%;
    transform: translateX(0) translateY(-40%);
    text-align: right;
    font-size: .85em;
    width: auto;
    display: block;
    font-weight: 900;
    opacity: .5;

    @media all and (min-width: @nav-medium) {
      left: 50%;
      top: 50%;
      right: auto;
      text-align: center;
      font-size: 1em;
      width: 100%;
      transform: translateX(-50%) translateY(-50%);
      opacity: 1;
    }

    &:empty {
      display: none;
    }
  }
}
