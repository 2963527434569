.global-campaign-spot-button {
  display: block;
  min-width: 35%;
  height: 40px;
  padding-top: 11px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-block;
  width: auto;
  margin: auto;
  padding-right: 20px;
  padding-left: 20px;
  -moz-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}
@media screen and (-moz-images-in-menus: 0) {
  .global-campaign-spot-button {
    padding-top: 11px;
  }
}
.global-campaign-spot-button:hover {
  text-decoration: none;
}
.global-campaign-spot-button.corporate-yellow {
  border-top: 1px solid #ffdb80;
  background-color: #feb700;
  box-shadow: rgba(0, 0, 0, 0.4) 0 2px 2px, #5e2767 0 0 0 1px;
  color: #004b32;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.5);
}
.global-campaign-spot-button.corporate-yellow:hover {
  border-top-color: #ffe297;
  background-color: #ffc52e;
}
.global-campaign-spot-button.bingo-green {
  border-top: 1px solid #9acb22;
  background-color: #80a91c;
  box-shadow: rgba(0, 0, 0, 0.4) 0 3px 2px, #607f15 0 0 0 1px;
  color: #ffffff;
  text-shadow: 0 1px 0 #607f15;
}
.global-campaign-spot-button.bingo-green:hover {
  border-top-color: #9ed023;
  background-color: #95c323;
}
.global-campaign-spot-button.bingo-dark-green {
  background-color: #3a5006;
  color: #ffffff;
}
.global-campaign-spot-button.bingo-dark-green:hover {
  background-color: #607f15;
}
.global-campaign-spot-button.casino-brown {
  background: #503e23;
  color: #ffffff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
}
.global-campaign-spot-button.casino-brown:hover {
  background: #5a482d;
}
.global-campaign-spot-button.casino-purple {
  border-top: 1px solid #9e71a5;
  background-color: #652044;
  box-shadow: #5f2768 0 0 0 1px, rgba(0, 0, 0, 0.4) 0 3px 2px;
  color: #fff;
  text-shadow: 0 1px 0 #7c643b;
}
.global-campaign-spot-button.casino-purple:hover {
  border-top-color: #a276a9;
  background-color: #973066;
}
.global-campaign-spot-button.dantoto-green {
  border-top: 1px solid #4fbe71;
  background-color: #47ab66;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 0 1px, rgba(0, 0, 0, 0.25) 0 3px 2px;
  color: #ffffff;
  text-shadow: 0 1px 0 #19482b;
}
.global-campaign-spot-button.dantoto-green:hover {
  background-color: #4fbe71;
}
.global-campaign-spot-button.eurojackpot-black {
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background: #363636;
  /* Old browsers */
  background: -moz-linear-gradient(top, #363636 0%, #000000 100%);
  /* FF3.6+ */
  background: -webkit-linear-gradient(top, #363636 0%, #000000 100%);
  /* Chrome10+,Safari5.1+ */
  background: -ms-linear-gradient(top, #363636 0%, #000000 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #363636 0%, #000000 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#363636', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  color: #fec115;
}
.global-campaign-spot-button.eurojackpot-black:after {
  font-family: 'DanskeSpil-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e601";
  padding-left: 5px;
}
.global-campaign-spot-button.eurojackpot-yellow {
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background: #cf8c1a;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2NmOGMxYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ5JSIgc3RvcC1jb2xvcj0iI2RhYTUzMyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2JhOGMyYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNiMDc3MTYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #cf8c1a 0%, #daa533 49%, #ba8c2b 50%, #b07716 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #cf8c1a), color-stop(49%, #daa533), color-stop(50%, #ba8c2b), color-stop(100%, #b07716));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #cf8c1a 0%, #daa533 49%, #ba8c2b 50%, #b07716 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #cf8c1a 0%, #daa533 49%, #ba8c2b 50%, #b07716 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #cf8c1a 0%, #daa533 49%, #ba8c2b 50%, #b07716 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #cf8c1a 0%, #daa533 49%, #ba8c2b 50%, #b07716 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cf8c1a', endColorstr='#b07716', GradientType=0);
  /* IE6-8 */
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
.global-campaign-spot-button.eurojackpot-yellow:hover {
  background: #d08f1d;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIxJSIgc3RvcC1jb2xvcj0iI2QwOGYxZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ5JSIgc3RvcC1jb2xvcj0iI2U3YzE1MCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2M0YTM0MiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNiMTdhMTkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #d08f1d 1%, #e7c150 49%, #c4a342 50%, #b17a19 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #d08f1d), color-stop(49%, #e7c150), color-stop(50%, #c4a342), color-stop(100%, #b17a19));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #d08f1d 1%, #e7c150 49%, #c4a342 50%, #b17a19 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #d08f1d 1%, #e7c150 49%, #c4a342 50%, #b17a19 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #d08f1d 1%, #e7c150 49%, #c4a342 50%, #b17a19 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #d08f1d 1%, #e7c150 49%, #c4a342 50%, #b17a19 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d08f1d', endColorstr='#b17a19', GradientType=0);
  /* IE6-8 */
}
.global-campaign-spot-button.lotto-yellow {
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background-color: #feb804;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #fff;
}
.global-campaign-spot-button.lotto-yellow:hover {
  text-decoration: none;
}
.global-campaign-spot-button.lotto-yellow:after {
  font-family: 'DanskeSpil-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e601";
  line-height: 20px;
  padding-left: 8px;
  vertical-align: top;
}
.global-campaign-spot-button.quick-purple {
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-top: 1px solid #9e71a5;
  background-color: #7f4488;
  box-shadow: #5e2767 0 0 0 1px, rgba(0, 0, 0, 0.4) 0 3px 2px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #ffffff;
  text-shadow: 0 1px 0 #7f4488;
}
.global-campaign-spot-button.quick-purple:hover {
  border-top-color: #a276a9;
  background-color: #8e5d95;
  color: #ffffff;
}
.global-campaign-spot-button.quick-dark-purple {
  background-color: #43154b;
  color: #ffffff;
}
.global-campaign-spot-button.quick-dark-purple:hover {
  background-color: #b28db8;
}
.global-campaign-spot-button.oddset-blue {
  background: #003e99;
  background: linear-gradient(to top, #002f71, #003e99);
  color: #ffffff;
}
.global-campaign-spot-button.oddset-blue:hover {
  background: linear-gradient(to top, #0f3e80, #0f4da8);
}
.campaign-spot {
  overflow: hidden;
  position: relative;
  float: left;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -moz-box-shadow: 0 15px 35px 2px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 15px 35px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 15px 35px 2px rgba(0, 0, 0, 0.5);
  min-height: 44px;
}
.aside-column .campaign-spot {
  margin-right: 0;
}
.campaign-spot.style-campaign {
  width: 232px;
  height: 310px;
  background-color: transparent;
  background-repeat: no-repeat;
}
.campaign-spot.style-campaign .footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}
.campaign-spot.style-campaign .title {
  font-size: 2.5rem;
  line-height: 28px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50px;
  margin: 0;
}
.campaign-spot.style-campaign .text {
  font-size: 1.3rem;
  line-height: 17px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 165px;
  margin: 0;
}
.campaign-spot.style-campaign .text.size-small {
  font-size: 1.3rem;
  line-height: 17px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}
.campaign-spot.style-campaign .text.size-large {
  font-size: 1.5rem;
  line-height: 18px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.campaign-spot.style-campaign .title.color-light,
.campaign-spot.style-campaign .text.color-light {
  color: #fff;
}
.campaign-spot.style-campaign .title.color-quick,
.campaign-spot.style-campaign .text.color-quick {
  color: #43154b;
}
.campaign-spot.style-campaign .title.color-dark,
.campaign-spot.style-campaign .text.color-dark {
  color: #333333;
}
