﻿@import (reference) "Mixins/_mixins.less";

@cookie-info__background-color: #1a3726;
@cookie-info__text-color: #f0f0f0;
@cookie-info__button-color: #316446;
@cookie-info__button-color--hover: lighten(@cookie-info__button-color, 5%);

body.cookie-info-active .cookie-info {
  display: block;
}

.cookie-info {
  display: none;
  min-height: 13rem;
  overflow-y: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  .cm-box-shadow(0, -.2rem, .4rem, 0, @rgba-black-050);
  background-color: @cookie-info__background-color;

  .cookie-info__container {
    max-width: 144rem;
    margin: 0 auto;
  }

  .cookie-info__content {
    color: @cookie-info__text-color;
    padding: 2rem;
    line-height: 1.35;
    max-width: @medium;

    h3 {
      font-size: 1.5rem;
      margin: 0 0 1rem;
    }

    p {
      font-size: 1.3rem;
      margin: 0 0 2rem;
    }

    .seen_button {
      display: inline-block;
      border-radius: 0.3rem;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1;
      padding: 1.3rem;
      text-decoration: none;
      text-shadow: 0 .1rem 0 @rgba-black-040;
      text-align: center;
      background: @cookie-info__button-color;
      cursor: pointer;
      border-top: .1rem solid #3b7451;
      .cm-box-shadow(0, .1rem, .2rem, .1rem, @rgba-black-050);
      min-width: 16rem;

      &:hover {
        background: @cookie-info__button-color--hover;
      }

      @media (max-width: @small) {
        display: block;
      }
    }
  }
}
