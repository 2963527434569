@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Variables/colors";

.ds-navigation-account-menu__logged-in-details {
  width: 100%;

  body.is-logged-in & {
    display: block;
  }

  body:not(.is-logged-in) & {
    display: none;
  }

  body.is-context-neutral & {
    display: none;
  }
}

.ds-navigation-account-menu__account {
  display: none;
  position: absolute;
  top: 2.5rem;
  left: 2rem;
  width: fit-content;
  margin-bottom: 0.8rem;
  padding: 0.7rem 0.8rem;
  background-color: @ds-dark-blue;
  border-radius: 0.6rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: bold;
  color: @ds-white;

  @media @sm {
    top: 3.5rem;
    left: 4rem;
  }

  .ds-navigation-account-menu--dli & {
    background-color: @ds-dark-blue;
  }

  .ds-navigation-account-menu--dlo & {
    background-color: @ds-red;
  }

  body.is-logged-in & {
    display: block;
  }

  body.is-context-neutral & {
    display: none;
  }
}

.ds-navigation-account-menu__user-info {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  width: 100%;
  row-gap: 2rem;
  margin-bottom: 3.8rem;
}

.ds-navigation-account-menu__greeting {
  display: block;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.3;
  color: @ds-black;
}

.ds-navigation-account-menu__amount-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.ds-navigation-account-menu__balance {
  font-size: 1.4rem;
  line-height: 1.4;
  color: @ds-black;
  display: grid;
  width: 100%;
  padding: 2.6rem 1.8rem 2rem;
  background-color: @ds-gray-100;
  border-radius: 0.8rem;
  gap: 0.2rem;
  grid-template-rows: repeat(5, auto);
  grid-template-columns: repeat(2, 1fr);
}

.ds-navigation-account-menu__balance-label {
  grid-row: 1;
  grid-column: 1 / span 2;
  font-size: 1.3rem;
  font-weight: normal;
  color: @ds-black;
  text-align: center;
}

.ds-navigation-account-menu__balance-amount {
  grid-row: 2;
  grid-column: 1 / span 2;
  font-size: 2rem;
  font-weight: bold;
  color: @ds-black;
  text-align: center;
}

.ds-navigation-account-menu__balance-separator {
  grid-row: 3;
  grid-column: 1 / span 2;
  margin: 2rem 0 1.6rem;
  height: 1px;
  background-color: @ds-black;
  opacity: 0.2;
}

.ds-navigation-account-menu__balance-available-label {
  grid-row: 4;
  grid-column: 1;
}

.ds-navigation-account-menu__balance-available-amount {
  grid-row: 4;
  grid-column: 2;
  place-self: end;
}

.ds-navigation-account-menu__balance-reserved-label {
  grid-row: 5;
  grid-column: 1;
}

.ds-navigation-account-menu__balance-reserved-amount {
  grid-row: 5;
  grid-column: 2;
  place-self: end;
}
