﻿@import "./Mixins/_mixins.less";

.header {

  // TODO: This block - ".games-frontpage" - contains CSS for a header that never saw the light of day (see: FrontpageGamesStaticViewNew.ascx a.o.)
  .games-frontpage {
    max-width: 144rem;

    .games-frontpage__section {
      display: inline-flex;
      flex-direction: column;
      padding: 1rem;
      border-radius: 0.8rem;

      .games-frontpage__section-header {
        margin-bottom: 0.5rem;
        padding: 0 0.5rem;
      }

      .games-frontpage__section-sublist {
        font-size: 1.2rem;
        font-weight: normal;
        font-style: italic;
        color: @ds-gray-600;
        margin: 1.1rem 0;
        padding: 0 0.5rem;
      }

      .games-frontpage__section-create-account {
        font-weight: 700;
        margin-bottom: 0.5rem;
        padding: 0 0.5rem;

        .games-frontpage__section-create-account-text,
        .games-frontpage__section-create-account-cta {
          display: inline-block;
        }

        .games-frontpage__section-create-account-cta {
          color: @color-link-blue;
        }
      }

      .games-frontpage__section-bottom-text {
        font-size: 1.1rem;
        padding: 0 0.5rem;
      }

      .games-frontpage__section-sikkert-spil {
        display: none;
      }

      &--1 {
        width: calc(~'50% - 1.5rem');
        background-color: rgba(15, 64, 150, 0.05);
        margin: 1rem;

        .games-frontpage__section-header {
          color: @color-dli-header;
        }
      }

      &--2 {
        width: calc(~'50% - 1.5rem');
        background-color: rgba(194, 22, 23, 0.05);
        margin: 1rem 1rem 1rem 0;

        .games-frontpage__section-header {
          color: @color-dlo-header;
        }
      }

      &--3 {
        width: calc(~'100% - 2rem');
        background-color: rgba(1, 75, 50, 0.05);
        margin: 0 1rem 1rem 1rem;
        flex-direction: row;
        flex-wrap: nowrap;

        .games-frontpage__section-header {
          display: none;
        }

        .games-frontpage__section-sublist {
          display: none;
        }

        .games-frontpage__section-create-account {
          display: none;
        }

        .games-frontpage__section-bottom-text {
          display: none;
        }

        .games-frontpage__section-sikkert-spil {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 0.5rem 1rem;
          font-size: 1.4rem;

          &-text {
            color: @color-sikkertspil-header;
          }

          &-cta {
            font-weight: 700;
            color: @color-link-blue;
          }
        }

      }

    }


    .games-frontpage__list {
      display: inline-flex;
      flex-wrap: wrap;
      padding: 0;

      .games-frontpage__list-item {
        list-style: none;
        display: block;
        border-radius: 0.5rem;
        margin: 0.5rem;
        width: calc(~'20% - 1rem');
        height: 3.5rem;
        transition: transform 0.2s ease;

        &:hover {
          transform: scale(1.1);
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 0.9rem 1rem;
          position: relative;

          &:focus {
            outline: none;
          }
        }

        &.altellerintet {
          a {
            padding: 0 1rem 0.5rem 1rem;
          }
        }

        &.bingo {
          a {
            padding: 0.5rem 1rem;
          }
        }

        &.virtuel a,
        &.Virtuel a {
          padding: 0 1rem 0.9rem;
        }

        img {
          max-height: 100%;
          width: 100%;
          object-fit: contain;

          // IE specific style.
          // IE is not rendering images with max-height proper. This is a fix.
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            height: 3rem;
          }
        }
      }
    }
  }

  .games {
    display: flex;
    flex-flow: column nowrap;

    @media (min-width: @nav-medium) {
      flex-direction: row;
    }

    &__group {
      position: relative;
      padding: 1rem;
      width: 100%;

      @media all and (min-width: @nav-medium) {
        width: var(--games__group_width-desktop, auto);
      }

      &--xxxlarge {
        --games__group_width-desktop: 100%;
      }

      &--xxlarge {
        --games__group_width-desktop: 50%;
      }

      &--xlarge {
        --games__group_width-desktop: 40%;
      }

      &--large {
        --games__group_width-desktop: 30%;
      }

      &--medium {
        --games__group_width-desktop: 20%;
      }

      &--4sec-3col {
        --games__list-item--manually-sized: 51%;
      }

      &--3sec-3col {
        --games__list-item--manually-sized: 69%;
      }

      &--3sec-5col {
        --games__list-item--manually-sized: 52%;
      }

      &:nth-child(n+2) {

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 0.1rem;
          background: @ds-gray-175;
          position: absolute;
          left: -0.1rem;
          bottom: 0;
          top: 0;

          @media (min-width: @nav-medium) {
            width: 0.1rem;
            height: 100%;
          }
        }
      }
    }

    &__headline {
      font-family: var(--font-family-danskespil);
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 1.2;
      font-style: italic;
      text-align: left;
      color: @ds-gray-600;
      margin: 0.7rem 0;
      padding: 0 0.5rem;

      @media all and (min-width: @nav-small) {
        min-height: calc(1.2rem * 1.2 * 2);
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      margin: 0;
      padding: 0;

      --games__list-item_width: 33.333%;

      @media (min-width: @nav-medium) {
        flex-direction: row;

        &--wide {
          --games__list-item_width: 20%;
        }

        &--narrow {
          --games__list-item_width: 33.333%;
        }
      }
    }

    &__list-item {
      list-style: none;
      margin: 0;
      padding: 0.5rem;
      width: var(--games__list-item_width, auto);
      transition: transform 0.2s ease;
      -webkit-backface-visibility: hidden;

      &:hover {
        transform: scale(1.1);
      }

      &.sikkertspil,
      &.klasselotteriet {
        @media (min-width: @nav-medium) {
          width: var(--games__list-item--manually-sized, 55%);
        }
      }

      &.virtuel,
      &.Virtuel {
        --games__list-item-link_padding: 0 1rem 0.9rem;
      }
    }

    &__list-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      height: 5rem;
      padding: var(--games__list-item-link_padding, 0.9rem 1rem);
      position: relative;
      border-radius: .5rem;

      &:focus {
        outline: none;
      }
    }

    &__list-item-image {
      max-height: 100%;
      width: 100%;
      object-fit: contain;
      font-size: 1rem;

      // IE specific style.
      // IE is not rendering images with max-height proper. This is a fix.
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 3rem;
      }
    }

    &[data-games-section-count="1"] {

      // TODO: Kept this layout despite it currently is not an option
      .games__list-item {
        @media (min-width: @nav-small) {
          width: 10%;
        }

        @media all and (min-width: @nav-large) {
          margin-bottom: 3.1rem;
        }
      }
    }

  }

  &.has-cta-component {
    .no-readmore-link {
      width: 100%;

      @media all and (min-width: @nav-medium) {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.header__cta-component {
  .font-size(12);
  text-align: center;

  .games__group & {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;

    @media (min-width: @nav-medium) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__description {
    padding: 0 2em;
    text-align: center;
    color: @ds-black;
    .font-size(12);
    font-weight: 600;
    font-style: normal;
    margin: 0 auto 2rem;
    white-space: normal;
  }

  &__read-more-link {
    display: block;
    text-decoration: underline;
    color: @ds-black;
  }

  &__cta-home {
    .btn(green);
  }
}
