﻿@import url("../../../Domain/NumberGames/Styles/Mixins/_mixins.less");

body {
  --survey-primary-color: @ds-green;
  --survey-secondary-color: fade(@ds-green, 10%);
  --survey-tertiary-color: fade(@ds-green, 25%);
  --survey-label-font-color: @ds-white;
}

.region-lotto {
  --survey-primary-color: @ds-red;
  --survey-secondary-color: fade(@ds-red, 10%);
  --survey-tertiary-color: fade(@ds-red, 25%);
  --survey-label-font-color: @ds-white;
}

.region-eurojackpot {
  --survey-primary-color: @ds-honey;
  --survey-secondary-color: fade(@ds-honey, 10%);
  --survey-tertiary-color: fade(@ds-honey, 25%);
  --survey-label-font-color: @ds-white;
}

.region-vikinglotto {
  --survey-primary-color: @ds-blue;
  --survey-secondary-color: fade(@ds-blue, 10%);
  --survey-tertiary-color: fade(@ds-blue, 25%);
  --survey-label-font-color: @ds-white;
}

.region-avalon-quick {
  --survey-primary-color: #9B51E0;
  --survey-secondary-color: fade(#9B51E0, 10%);
  --survey-tertiary-color: fade(#9B51E0, 25%);
  --survey-label-font-color: @ds-white;
}

.region-allornothing {
  --survey-primary-color: @ds-neongreen;
  --survey-secondary-color: fade(@ds-neongreen, 10%);
  --survey-tertiary-color: fade(@ds-neongreen, 25%);
  --survey-label-font-color: @ds-white;
}

.region-keno {
  --survey-primary-color: #2F80ED;
  --survey-secondary-color: fade(#2F80ED, 10%);
  --survey-tertiary-color: fade(#2F80ED, 25%);
  --survey-label-font-color: @ds-white;
}

.survey-container {
  position: fixed;
  inset: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 110;
  font-size: 1.6rem;

  & * {
    box-sizing: border-box;
  }
}

.survey__form {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: white;
  padding: 2.4rem 1.6rem;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  width: 42rem;
  max-width: 94%;
  text-align: left;

  @media @sm {
    width: 65.2rem;
    padding: 3.2rem 3.2rem;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    text-align: left;
    padding-right: 4rem
  }

  h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin-bottom: 1.6rem;
    text-align: left;
  }
}


.survey__close {
  position: absolute;
  right: 1.6rem;
  top: 2.4rem;
  width: 24px;
  height: 24px; 
  z-index: 1;
  cursor: pointer;
  @media @sm {
    right: 3.2rem;
    top: 3.2rem;
  }
}


.survey__container-low-text {
  order: 2;
  max-width:22%;
  line-height: 2rem;
  font-size: 1.4rem;
  @media @sm {
    order: unset;
    max-width: unset;
    font-size: 1.6rem;
    line-height: inherit;
  }
}

.survey__container-radio{
  position: relative;
  display: flex;
  padding: 2.4rem 1.6rem;
  line-height: 4rem;
  gap: 2.4rem;
  font-weight: 600;
  margin: 2.4rem 0;
  flex-wrap: wrap;
  order: 1;
  width: 100%;
  justify-content: space-between;
  border-radius: 0.8rem;
  background-color: var(--survey-secondary-color);

  
  @media @sm {
    flex-wrap: nowrap;
    order: unset;
    width: auto;
    margin: 4rem 0; 
  }
}

.survey__container-hight-text {
  order: 3;
  max-width: 22%;
  line-height: 2rem;
  text-align: right;
  font-size: 1.4rem;
  @media @sm {
    order: unset;
    max-width: unset;
    font-size: 1.6rem;
    line-height: inherit;
  }
}


.survey__container-radio-options {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  @media @sm {
    order: unset;
    max-width: unset;
    width: auto;
  }
}

.survey__container-radio {
  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label {
    background-color: var(--survey-primary-color);
    color: var(--survey-label-font-color);
  }

  input[type="radio"]:not(:checked) + label {
    background-color: var(--survey-tertiary-color);
  }
  /* Ensure that all labels have the primary color initially */
  input[type="radio"].hidden-radio:checked ~ label {
    background-color: var(--survey-primary-color);
    color: white;
  }

  label {
    display: block;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    font-size: 1.8rem;
    background-color: var(--survey-primary-color);
    color: var(--survey-label-font-color);

    @media @sm {
      width: 4rem;
      height: 4rem;
    }
  }
}

.survey__comment {
  display: block;
  padding: 16px;
  width: 100%;
  border-radius: 4px;
  resize: none;
  border: 1px solid var(--Neutral-Grey---Light, #EBEBEB);
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
  @media @sm {
    margin-bottom: 4rem;
  }
}

.survey__cta-container{
  position: relative;
  text-align: center;
}

.survey__cta-button {
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: @ds-black;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  height: 4.8rem;
  max-width: 24rem;
  width:100%;  
  background: transparent;
  border: none;


  &:not(:disabled):hover::after {
    transform: scale(1.03);
  }
  &:after {
    border-radius: 2.8rem;
    transition: transform 200ms ease-out;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0.1rem solid transparent;
    background-color: @ds-yellow; 
    border-color: @ds-yellow;
  }
  &[disabled]:after{
    opacity: 0.25;

  }
}


.survey__form-submitted-container{
  position: relative;
  display: flex;
  align-items: center; 
  justify-content: flex-start;
  gap: 2rem;
}
.survey__form-submitted-check-icon{
  position: relative;
  width:  4rem;
  height: 4rem;
  background-color: var(--survey-primary-color);
  border-radius: 50%;
  flex-shrink: 0;
  
  & > svg{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
}

.survey__form-submitted-text {
  position: relative;
  font-size: 1.6rem;
  font-weight: 700;
  flex-grow: 1;
  padding-right: 4rem;
}
