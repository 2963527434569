@font-face {
  font-family: "DanskeSpil";
  src: url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Light.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DanskeSpil";
  src: url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Regular.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DanskeSpil";
  src: url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Medium.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DanskeSpil";
  src: url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Bold.woff2") format("woff2"), url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DanskeSpil";
  src: url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Black.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/DanskeSpil/DanskeSpil-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
