@import url("variables.less");

.mega-menu__account-details {
  @media all and (max-width: (@nav-medium - 1)) {
    display: none;
  }

  display: block;
  color: @nav-black-text;

  &__title {
    &__inner {
      margin-bottom: 0;
      font-weight: 300; 
    }
  }

  &__logout-btn {
    float: right;
    color: inherit;
    font-weight: 300;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    position: relative;
    z-index: 0;
    text-decoration: none;
    border-bottom: 1px solid black;
    border-color: lightgray;
    padding-top: 0.4rem;

    &:hover {
      text-decoration: none;

      &:after {
        background: @nav-black-text;
      }
    }
  }

  &__details-btn {
    float: right;
    color: inherit;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    position: relative;
    z-index: 0;
    text-decoration: none;
    border-bottom: 1px solid black;
    border-color: lightgray;

    &:hover {
      text-decoration: none;

      &:after {
        background: @nav-black-text;
      }
    }
  }

  &__row {
    margin-top: 0.4rem;

    &--button-container {
      clear: both;
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  &--dli-only {
    display:none;
  }

  &__row-buttons {
    display: flex;
    width: 100%;
  }

  &__divider {
    border: none;
    border-top: 1px solid #cacaca;
    margin: 2rem 0;
  }

  &__small-text {
    font-size: 1.3rem;
  }

  &__large-text {
    font-size: 1.6rem;
    font-weight: 600;
  }

  &__larger-text {
    font-size: 2rem;
    font-weight: bold;

    .is-tablet-detected & {
      font-size: 2rem;
    }

  }

  &--margin-bottom {
    margin-bottom: 1.3rem;
  }

  &__left {
    float: left;
  }

  &__right {
    float: right;
  }

  &__button {
    display: inline-block;
    color: @nav-black-text;
    background: @nav-button-color;
    border-radius: 0.4rem;
    font-size: 1.6rem;
    font-weight: bold;
    padding: .8em 1em;
    line-height: 1.2;
    text-decoration: none;
    margin: 2.5rem 2rem 0 0;

    &:last-child {
      margin-right: 0;
    }

    transition: .3s;

    &:hover {
      text-decoration: none;
      background: lighten(@nav-button-color, 8%);
    }
  }
}
