/* ===========================================
   imports : 
   Import colors from avalon.
   Import breakpoints from avalon.
   Import global from avalon.
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ********* Variables ********* */
/* ===========================================
   settings.global
   ========================================= */
/**
 * High-level settings and variables,
 * that are accessible in whole Avalon project
*/
/* ===========================================
   settings.breakpoints
   ========================================= */
/* ===========================================
   settings.colors
   ========================================= */
/* New theming colors (use these in future, convert old colors along the way)
   ========================================================================== */
/* Color variables used in AvalonComponents, Poker and Casino projects
/* (Old theming colors, should be slowly removed)
   ========================================================================== */
/* Corporate colors
   ========================================================================== */
/* Poker colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Bingo colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Oddset colors
   ========================================================================== */
/* Virtuel colors
   ========================================================================== */
/* EuroJackpot colors
   ========================================================================== */
/* Set Color Variables
   ========================================================================== */
/* Components
   ========================================================================== */
/* *********** MIXINS ********** */
/* ===========================================
   tools.mixins
   ========================================= */
.ppu .ppu_wrapper--hide {
  opacity: 0;
  pointer-events: none;
}
.ppu .ppu_wrapper--show {
  opacity: 1;
  pointer-events: all;
}
.ppu .ppu__wrapper {
  position: relative;
  justify-content: center;
  overflow: hidden;
  transition: 1s;
}
.ppu .ppu__wrapper .ppu__closebutton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
}
.ppu .ppu__wrapper .ppu__shadow {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  background-color: #000000;
  z-index: 9987;
  opacity: 0.4;
}
.ppu .ppu__wrapper .ppu__content {
  margin-left: auto;
  margin-right: auto;
  max-height: 99%;
  overflow: hidden;
  z-index: 9988;
  text-align: center;
  background: #ffffff;
}
.ppu .ppu__wrapper .ppu__content .ppu__element--show {
  top: 2vh;
}
.ppu .ppu__wrapper .ppu__content .ppu__element--hide {
  top: -100%;
}
.ppu .ppu__wrapper .ppu__content .ppu__element {
  position: fixed;
  width: 90vw;
  margin: 0 auto;
  /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  border-radius: 0.1rem;
  transition: 1.2s;
  transition-timing-function: ease-out;
  z-index: 9989;
  max-height: 96vh;
  background-color: inherit;
  padding: 5vw;
  font-size: 1rem;
}
@media screen and (min-width: 950px) {
  .ppu .ppu__wrapper .ppu__content .ppu__element {
    padding: 2rem 10rem;
    width: 80rem;
    max-width: 100%;
    font-size: 1.5rem;
  }
}
.ppu .ppu__wrapper .ppu__content .ppu__element p {
  font-size: 0.9em;
}
.ppu .ppu__wrapper .ppu__content .ppu__element h1 {
  font-size: 2.3em;
}
.ppu .ppu__wrapper .ppu__content .ppu__element h2 {
  font-size: 2.2em;
}
.ppu .ppu__wrapper .ppu__content .ppu__element h3 {
  font-size: 1.8em;
}
.ppu .ppu__wrapper .ppu__content .ppu__element h4 {
  font-size: 1.5em;
}
.ppu .ppu__wrapper .ppu__content .ppu__element h5 {
  font-size: 1.3em;
}
.ppu .ppu__wrapper .ppu__content .ppu__element h6 {
  font-size: 1.1em;
}
.ppu .ppu__wrapper .ppu__content .ppu__element a {
  font-size: 0.9em;
}
.ppu .ppu__wrapper .ppu__loader {
  opacity: .7;
  background-color: black;
}
.ppu .ppu__wrapper .ppu__cta-overflow {
  border-radius: 2rem;
  border: solid 2px transparent;
  height: 4.2rem;
  overflow: hidden;
  width: 21rem;
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  margin-top: 2rem;
}
@media screen and (min-width: 950px) {
  .ppu .ppu__wrapper .ppu__cta-overflow--left {
    float: left;
  }
  .ppu .ppu__wrapper .ppu__cta-overflow--right {
    float: right;
  }
}
.ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta {
  display: inline-table;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #000000;
}
.ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta:hover {
  text-decoration: none;
}
.ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta .ppu__cta-container {
  display: table-cell;
  vertical-align: middle;
}
.ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta .ppu__cta-container p {
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
}
.ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta .ppu__cta-container .ppu__cta-loader-box {
  position: absolute;
  transform: translateY(1rem) translateX(1rem) scale(1);
  opacity: 1;
  transition: transform .25s, opacity .15s;
}
.ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta .ppu__cta-container .ppu__cta-loader-box .loader-text {
  display: inline-block;
}
.ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta .ppu__cta-container .ppu__cta-loader-box .loader-animation-box {
  margin-left: .5rem;
  display: inline-block;
}
@keyframes loader-dot-animation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100%);
  }
}
.ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta .ppu__cta-container .ppu__cta-loader-box .loader-animation-box .loader-dot {
  width: 1.2vw;
  height: 1.2vw;
  margin-left: .3rem;
  border-radius: 50%;
  background-color: #000000;
  display: inline-block;
}
.ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta .ppu__cta-container .ppu__cta-loader-box .loader-animation-box .loader-dot:nth-child(1n) {
  animation: loader-dot-animation 0.5s ease-in-out -0.66s infinite;
}
.ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta .ppu__cta-container .ppu__cta-loader-box .loader-animation-box .loader-dot:nth-child(2n) {
  animation: loader-dot-animation 0.5s ease-in-out -0.33s infinite;
}
.ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta .ppu__cta-container .ppu__cta-loader-box .loader-animation-box .loader-dot:nth-child(3n) {
  animation: loader-dot-animation 0.5s ease-in-out -1s infinite;
}
@media screen and (min-width: 950px) {
  .ppu .ppu__wrapper .ppu__cta-overflow .ppu__cta .ppu__cta-container .ppu__cta-loader-box .loader-animation-box .loader-dot {
    width: .8rem;
    height: .8rem;
  }
}
