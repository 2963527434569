@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Variables/colors";
@import (reference) "./NavigationMixins";

.ds-navigation-choose-games {
  .ds-navigation__focus-outline();
  position: fixed;
  z-index: 130;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 35rem;
  background: @ds-white;
  transform: translateX(-35rem);
  transition: transform 250ms;
  will-change: transform;

  a:active,
  button:active {
    transform: translateY(0.1rem);
  }

  &.open {
    transition-delay: 100ms;
    transform: translateX(0);
  }

  &.close {
    transform: translateX(-35rem);
    transition: transform 200ms;
    transition-delay: 0ms;

    @media @sm {
      transform: translateX(-65.2rem);
    }
  }

  @media @sm {
    max-width: 65.2rem;
    transform: translateX(-65.2rem);
  }
}

.ds-navigation-choose-games__close {
  .ds-navigation__menu-close(@ds-black, @ds-white, @ds-gray-175);
}

.ds-navigation-choose-games__inner {
  position: absolute;
  inset: 0;
  padding: 6.6rem 2rem 4rem;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scrollbar-width: thin;

  @media @sm {
    padding: 8.5rem 4rem 4rem;
    scrollbar-width: unset;
  }
}

.ds-navigation-choose-games__region-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: DanskeSpil, serif;
  font-size: 2.4rem;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 0.024rem;
  color: @ds-gray-900;
  margin-top: 0.4rem;
  margin-bottom: 1.2rem;

  @media @sm {
    font-size: 2.8rem;
    margin-bottom: 2rem;
  }

  @media @xl {
    margin-bottom: 1.5rem;
  }

  &--dli {
    color: @ds-dark-blue-700;
  }

  &--dlo {
    color: @ds-red;
  }

  &:hover {
    text-decoration: none;
  }
}

.ds-navigation-choose-games__region-link-icon {
  line-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  background-color: @ds-yellow;
  margin-left: 1.3rem;
  margin-bottom: 0.5rem;

  svg {
    stroke: @ds-black;
    width: 1.8rem;
    height: 1.8rem;
  }
}

.ds-navigation-choose-games__brands {
  margin-right: 0.3rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 0.5rem;
  margin-bottom: 1.9rem;

  @media @sm {
    margin-right: 0;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 0.8rem;
    margin-bottom: 2.6rem;
  }
}

a.ds-navigation-choose-games__brand {
  aspect-ratio: 4 / 3;
  background-color: @ds-red-100;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 100ms ease 0ms, box-shadow 0s ease;

  img {
    color: @ds-white;
    width: 70%;
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0.8rem 3.2rem rgba(0, 0, 0, 0.32);
  }

  &:active {
    transform: scale(1.1) translateY(0.1rem);
  }
}

.ds-navigation-choose-games__region-text {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
  color: @ds-gray-525;
  letter-spacing: 0.01em;
  max-width: 55ch;
}

.ds-navigation-choose-games__other-region {
  background: @ds-gray-100;
  border-radius: 0.8rem;
  margin-top: 2.7rem;
  padding: 2.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;

  &:hover {
    text-decoration: none;
  }

  body.is-desktop-detected & {
    pointer-events: none;
  }

  @media @sm {
    padding: 4rem 10rem;
  }
}

.ds-navigation-choose-games__other-region-title {
  font-family: DanskeSpil, serif;
  font-size: 2.4rem;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 0.024rem;

  @media @sm {
    font-size: 2.8rem;
  }

  .ds-navigation-choose-games__other-region--dli & {
    color: @ds-dark-blue-700;
  }

  .ds-navigation-choose-games__other-region--dlo & {
    color: @ds-red;
  }
}

.ds-navigation-choose-games__other-region-text {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
  color: @ds-gray-525;
  letter-spacing: 0.01em;
}

.ds-navigation-choose-games__other-region-cta {
  margin-top: 1rem;
  color: @ds-black;
  border-radius: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.08em;
  border: 0.1rem solid rgba(0, 0, 0, 0.40);
  height: 3.6rem;
  min-width: 14rem;
  width: fit-content;
  pointer-events: auto;

  @media @sm {
    font-size: 1.4rem;
    padding: 0.5rem 3rem;
    height: 5.4rem;
    min-width: 17rem;
  }

  .ds-navigation-choose-games__other-region--dli & {
    background-color: @ds-dark-blue;
    border-color: @ds-dark-blue;
    color: @ds-white;
  }

  .ds-navigation-choose-games__other-region--dlo & {
    background-color: @ds-red;
    border-color: @ds-red;
    color: @ds-white;
  }
}

hr.ds-navigation-choose-games__separator {
  height: 0.1rem;
  border: none;
  border-top: 0.1rem solid @ds-black;
  opacity: 0.12;
  margin: 2.1rem 0;
  display: none;

  &:has(+ a),
  &:has(+ div) {
    display: block;
  }

  @media @sm {
    margin: 3.5rem 0;
  }

  @media @xl {
    margin: 3.2rem 0;
  }
}

.ds-navigation-choose-games__more-games {
  display: grid;
  justify-content: center;
  gap: 1rem;
  padding: 3.2rem 2.4rem;
  background-color: @ds-gray-100;
  border-radius: 0.8rem;
  text-align: center;
}

.ds-navigation-choose-games__more-games-title {
  font-family: DanskeSpil, serif;
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 500;

  @media @sm {
    font-size: 2.8rem;
  }

  .ds-navigation-choose-games__more-games--dli & {
    color: @ds-dark-blue;
  }

  .ds-navigation-choose-games__more-games--dlo & {
    color: @ds-red;
  }
}

.ds-navigation-choose-games__more-games-text {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
  color: @ds-gray-525;
  letter-spacing: 0.01em;
  max-width: 55ch;
}

.ds-navigation-choose-games__more-games-cta {
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  height: 3.6rem;
  color: @ds-white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 1.4rem auto 0;
  padding: 0 5rem;
  border-radius: 5.4rem;

  @media @sm {
    height: 5.4rem;
    font-size: 1.4rem;
  }

  .ds-navigation-choose-games__more-games--dli & {
    background-color: @ds-dark-blue;
  }

  .ds-navigation-choose-games__more-games--dlo & {
    background-color: @ds-red;
  }
}
