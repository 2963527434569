.cm-box-sizing(@box-model: border-box) {
  -webkit-box-sizing: @box-model;
  -moz-box-sizing: @box-model;
  box-sizing: @box-model;
}

.cm-clearfix() {
  &:before,
  &:after {
    content: "";
    display: table; 
  }

  &:after {
    clear: both;
  }
  //&:extend(.clearfixing all);
}

.cm-opacity(@opacity: 1) {
  @ieOpacity: @opacity * 100;
  // IE 8
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=@{ieOpacity})";
  // Good browsers
  opacity: @opacity;
}

.cm-corners-all(@radius: 5px) {
  border-radius: @radius;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.cm-corners-top(@radius: 5px) {
  border-radius: @radius @radius 0 0;
}
.cm-corners-bottom(@radius: 5px) {
  border-radius: 0 0 @radius @radius;
}
.cm-corners-left(@radius: 5px) {
  border-radius: @radius 0 0 @radius;
}
.cm-corners-right(@radius: 5px) {
  border-radius: 0 @radius @radius 0;
}

.cm-rotate(@deg-value: 45) {
  -moz-transform: rotate(~"@{deg-value}deg");
  -webkit-transform: rotate(~"@{deg-value}deg");
  -o-transform: rotate(~"@{deg-value}deg");
  -ms-transform: rotate(~"@{deg-value}deg");
  transform: rotate(~"@{deg-value}deg");
}

.cm-box-shadow(@horizontalOffset: 0, @verticalOffset: 15px, @blurRadius: 35px, @spreadRadius: 2px, @shadowColor: rgba(0, 0, 0, 0.50)) {
  -moz-box-shadow: @horizontalOffset @verticalOffset @blurRadius @spreadRadius @shadowColor;
  -webkit-box-shadow: @horizontalOffset @verticalOffset @blurRadius @spreadRadius @shadowColor;
  box-shadow: @horizontalOffset @verticalOffset @blurRadius @spreadRadius @shadowColor;
}

.cm-inner-shadow(@color: #084631) {
  -moz-box-shadow: inset 0 0 10px @color;
  -webkit-box-shadow: inset 0 0 10px @color;
  box-shadow: inset 0 0 10px @color;
}

.cm-visuallyhidden() {
  //Hide only visually, but not for screenreaders
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
}