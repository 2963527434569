.ds-navigation-main-menu {
  padding: 0 0.4rem;
  display: grid;
  grid-template-columns: minmax(5.5rem, auto) 1fr auto;
  grid-template-rows: var(--navigation-main-menu-height, 6.8rem);
  grid-template-areas: "games logo buttons";
  gap: 0.2rem;
  align-items: center;
}
.ds-navigation-main-menu a:focus {
  outline: none;
}
.ds-navigation-main-menu a:focus-visible,
.ds-navigation-main-menu *:focus-visible {
  outline: #000000 dotted thin;
  outline-offset: 0.2rem;
}
.ds-navigation-main-menu a:active,
.ds-navigation-main-menu *:active {
  outline: none;
}
.ds-navigation-main-menu a:active,
.ds-navigation-main-menu button:active {
  transform: translateY(0.1rem);
}
@media screen and (min-width: 768px) {
  .ds-navigation-main-menu {
    gap: 0.4rem;
    padding: 0 1.4rem;
    grid-template-rows: var(--navigation-main-menu-height, 7.2rem);
  }
}
@media screen and (min-width: 1216px) {
  .ds-navigation-main-menu {
    padding: 0 1.5rem 0 1.4rem;
    grid-template-columns: 7.8rem 16.5rem 1fr minmax(calc(24.7rem), auto);
    grid-template-rows: var(--navigation-main-menu-height, 7.6rem);
    grid-template-areas: "games logo top-menu buttons";
  }
}
.ds-navigation-main-menu--no-choose-games {
  grid-template-areas: "logo buttons";
  grid-template-columns: 1fr auto;
}
@media screen and (min-width: 1216px) {
  .ds-navigation-main-menu--no-choose-games {
    grid-template-areas: "logo top-menu buttons";
    grid-template-columns: 16.5rem 1fr 16.5rem;
  }
}
.ds-navigation-main-menu--no-account-menu {
  grid-template-columns: minmax(5.5rem, auto) 1fr auto;
}
@media screen and (min-width: 1216px) {
  .ds-navigation-main-menu--no-account-menu {
    grid-template-areas: "games logo top-menu buttons";
    grid-template-columns: 7.8rem 16.5rem 1fr calc(24.7rem);
  }
}
.ds-navigation-main-menu--no-account-menu.ds-navigation-main-menu--no-choose-games {
  grid-template-columns: 1fr auto;
}
@media screen and (min-width: 1216px) {
  .ds-navigation-main-menu--no-account-menu.ds-navigation-main-menu--no-choose-games {
    grid-template-areas: "logo top-menu buttons";
    grid-template-columns: 16.5rem 1fr 16.5rem;
  }
}
.ds-navigation-main-menu--account-page {
  grid-template-columns: minmax(5.5rem, auto) 1fr auto;
  grid-template-areas: "games account-brand buttons";
}
@media screen and (min-width: 1216px) {
  .ds-navigation-main-menu--account-page {
    grid-template-columns: 7.8rem 1fr 7.8rem;
    grid-template-areas: "games account-brand buttons";
  }
}
.ds-navigation-main-menu__account-brand {
  grid-area: account-brand;
  display: grid;
  gap: 0.5rem;
  margin: auto;
  white-space: nowrap;
}
.ds-navigation-main-menu__account-brand-title {
  padding: 0 0.8rem;
  background: white;
  border-radius: 0.6rem;
  height: 2.6rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
}
.ds-navigation-main-menu__account-brand-subtitle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 400;
  color: white;
}
.ds-navigation-main-menu__buttons {
  grid-area: buttons;
  display: flex;
  justify-content: flex-end;
  gap: 0.2rem;
}
@media screen and (min-width: 768px) {
  .ds-navigation-main-menu__buttons {
    gap: 0.4rem;
  }
}
.ds-navigation-main-menu__toggle {
  font-size: 1.2rem;
  line-height: 1.333;
  font-weight: 600;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  height: 6rem;
  width: 100%;
  border: none;
  border-radius: 0.6rem;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 200ms ease-out;
  white-space: nowrap;
  padding: 0 0.6rem;
}
.ds-navigation-main-menu__toggle:hover {
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .ds-navigation-main-menu__toggle {
    padding: 0.1rem 1.2rem 0;
  }
  .ds-navigation-main-menu__toggle:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
@media screen and (min-width: 1216px) {
  .ds-navigation-main-menu__toggle {
    font-size: 1.4rem;
    gap: 0.3rem;
  }
}
.ds-navigation-main-menu__toggle--account {
  grid-area: account;
}
@media screen and (min-width: 1216px) {
  .ds-navigation-main-menu__toggle--account {
    width: 7.8rem;
  }
}
.ds-navigation-main-menu--no-account-menu .ds-navigation-main-menu__toggle--account {
  display: none;
}
@media screen and (min-width: 768px) {
  body.is-logged-in .ds-navigation-main-menu__toggle--account:hover {
    background-color: transparent;
  }
}
.ds-navigation-main-menu__toggle--burger {
  grid-area: burger;
}
@media screen and (min-width: 1216px) {
  .ds-navigation-main-menu__toggle--burger {
    display: none;
  }
}
.ds-navigation-main-menu__toggle--games {
  grid-area: games;
}
@media screen and (min-width: 768px) {
  .ds-navigation-main-menu__toggle--games {
    padding: 0 0.6rem;
  }
}
@media screen and (min-width: 1216px) {
  .ds-navigation-main-menu__toggle--games a {
    font-size: 1.2rem;
  }
}
.ds-navigation-main-menu--no-choose-games .ds-navigation-main-menu__toggle--games {
  display: none;
}
.ds-navigation-main-menu__toggle svg {
  width: 2.4rem;
  height: 2.4rem;
  stroke: white;
}
.ds-navigation-main-menu__logo {
  grid-area: logo;
  overflow: hidden;
  display: grid;
  align-items: center;
  width: fit-content;
  padding: 0.5rem 1rem;
  height: 6rem;
  border-radius: 0.6rem;
}
@media screen and (min-width: 1216px) {
  .ds-navigation-main-menu__logo {
    margin-left: 0;
  }
}
.ds-navigation-main-menu__logo picture,
.ds-navigation-main-menu__logo img {
  line-height: 0;
  height: 5rem;
}
