@font-face {
  font-family: "OliveVillage";
  src: url("/Components/Common/Framework/Design/Fonts/OliveVillage/OliveVillage-Regular.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/OliveVillage/OliveVillage-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OliveVillage";
  src: url("/Components/Common/Framework/Design/Fonts/OliveVillage/OliveVillage-Italic.woff2") format("woff2"),
    url("/Components/Common/Framework/Design/Fonts/OliveVillage/OliveVillage-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
