/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
body {
  --survey-primary-color: #004b32;
  --survey-secondary-color: rgba(0, 75, 50, 0.1);
  --survey-tertiary-color: rgba(0, 75, 50, 0.25);
  --survey-label-font-color: white;
}
.region-lotto {
  --survey-primary-color: #c50005;
  --survey-secondary-color: rgba(197, 0, 5, 0.1);
  --survey-tertiary-color: rgba(197, 0, 5, 0.25);
  --survey-label-font-color: white;
}
.region-eurojackpot {
  --survey-primary-color: #e8a332;
  --survey-secondary-color: rgba(232, 163, 50, 0.1);
  --survey-tertiary-color: rgba(232, 163, 50, 0.25);
  --survey-label-font-color: white;
}
.region-vikinglotto {
  --survey-primary-color: #0000ff;
  --survey-secondary-color: rgba(0, 0, 255, 0.1);
  --survey-tertiary-color: rgba(0, 0, 255, 0.25);
  --survey-label-font-color: white;
}
.region-avalon-quick {
  --survey-primary-color: #9B51E0;
  --survey-secondary-color: rgba(155, 81, 224, 0.1);
  --survey-tertiary-color: rgba(155, 81, 224, 0.25);
  --survey-label-font-color: white;
}
.region-allornothing {
  --survey-primary-color: #56F4AD;
  --survey-secondary-color: rgba(86, 244, 173, 0.1);
  --survey-tertiary-color: rgba(86, 244, 173, 0.25);
  --survey-label-font-color: white;
}
.region-keno {
  --survey-primary-color: #2F80ED;
  --survey-secondary-color: rgba(47, 128, 237, 0.1);
  --survey-tertiary-color: rgba(47, 128, 237, 0.25);
  --survey-label-font-color: white;
}
.survey-container {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
  font-size: 1.6rem;
}
.survey-container * {
  box-sizing: border-box;
}
.survey__form {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2.4rem 1.6rem;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 42rem;
  max-width: 94%;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .survey__form {
    width: 65.2rem;
    padding: 3.2rem 3.2rem;
  }
}
.survey__form h2 {
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  text-align: left;
  padding-right: 4rem;
}
.survey__form h3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 1.6rem;
  text-align: left;
}
.survey__close {
  position: absolute;
  right: 1.6rem;
  top: 2.4rem;
  width: 24px;
  height: 24px;
  z-index: 1;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .survey__close {
    right: 3.2rem;
    top: 3.2rem;
  }
}
.survey__container-low-text {
  order: 2;
  max-width: 22%;
  line-height: 2rem;
  font-size: 1.4rem;
}
@media screen and (min-width: 768px) {
  .survey__container-low-text {
    order: unset;
    max-width: unset;
    font-size: 1.6rem;
    line-height: inherit;
  }
}
.survey__container-radio {
  position: relative;
  display: flex;
  padding: 2.4rem 1.6rem;
  line-height: 4rem;
  gap: 2.4rem;
  font-weight: 600;
  margin: 2.4rem 0;
  flex-wrap: wrap;
  order: 1;
  width: 100%;
  justify-content: space-between;
  border-radius: 0.8rem;
  background-color: var(--survey-secondary-color);
}
@media screen and (min-width: 768px) {
  .survey__container-radio {
    flex-wrap: nowrap;
    order: unset;
    width: auto;
    margin: 4rem 0;
  }
}
.survey__container-hight-text {
  order: 3;
  max-width: 22%;
  line-height: 2rem;
  text-align: right;
  font-size: 1.4rem;
}
@media screen and (min-width: 768px) {
  .survey__container-hight-text {
    order: unset;
    max-width: unset;
    font-size: 1.6rem;
    line-height: inherit;
  }
}
.survey__container-radio-options {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .survey__container-radio-options {
    order: unset;
    max-width: unset;
    width: auto;
  }
}
.survey__container-radio {
  /* Ensure that all labels have the primary color initially */
}
.survey__container-radio input[type="radio"] {
  display: none;
}
.survey__container-radio input[type="radio"]:checked + label {
  background-color: var(--survey-primary-color);
  color: var(--survey-label-font-color);
}
.survey__container-radio input[type="radio"]:not(:checked) + label {
  background-color: var(--survey-tertiary-color);
}
.survey__container-radio input[type="radio"].hidden-radio:checked ~ label {
  background-color: var(--survey-primary-color);
  color: white;
}
.survey__container-radio label {
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  font-size: 1.8rem;
  background-color: var(--survey-primary-color);
  color: var(--survey-label-font-color);
}
@media screen and (min-width: 768px) {
  .survey__container-radio label {
    width: 4rem;
    height: 4rem;
  }
}
.survey__comment {
  display: block;
  padding: 16px;
  width: 100%;
  border-radius: 4px;
  resize: none;
  border: 1px solid var(--Neutral-Grey---Light, #EBEBEB);
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
}
@media screen and (min-width: 768px) {
  .survey__comment {
    margin-bottom: 4rem;
  }
}
.survey__cta-container {
  position: relative;
  text-align: center;
}
.survey__cta-button {
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 0 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 1.2rem;
  line-height: 1.375;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 800;
  text-decoration: none;
  height: 4.8rem;
  max-width: 24rem;
  width: 100%;
  background: transparent;
  border: none;
}
.survey__cta-button:not(:disabled):hover::after {
  transform: scale(1.03);
}
.survey__cta-button:after {
  border-radius: 2.8rem;
  transition: transform 200ms ease-out;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0.1rem solid transparent;
  background-color: #FEB700;
  border-color: #FEB700;
}
.survey__cta-button[disabled]:after {
  opacity: 0.25;
}
.survey__form-submitted-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
.survey__form-submitted-check-icon {
  position: relative;
  width: 4rem;
  height: 4rem;
  background-color: var(--survey-primary-color);
  border-radius: 50%;
  flex-shrink: 0;
}
.survey__form-submitted-check-icon > svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.survey__form-submitted-text {
  position: relative;
  font-size: 1.6rem;
  font-weight: 700;
  flex-grow: 1;
  padding-right: 4rem;
}
