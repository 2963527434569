﻿@import url("variables.less");

.mega-menu {

  &__account {
    padding-left: 2rem;

    .dli & {
      &-name.dlo,
      &-meta.dlo,
      &-details__left.dlo {
        display:none;
      }
    }

    .dlo & {
      &-name.dli,
      &-meta.dli,
      &-details__left.dli {
        display:none;
      }
    }

    &-name {
      margin-bottom:0;

      &.dli {
        color: #1c2d56;
      }

      &.dlo {
        color: #9a0000;
      }
    }

    &-meta{ 
      margin:0;
      font-size:1.2rem;
    }
  }

  &__inner {
    max-width: 1440px;
    margin: 0 auto;
  }

  .sub-sub-navigation__section-link {
    font-weight: bold;
  }
  // MOBILE/TABLET overwrites and adjustments:
  @media all and (max-width: (@nav-medium - 1)) {
    .navigation-list {
      display: block !important;
    }

    .sub-sub-navigation {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .sub-sub-sub-navigation {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      background: #e3e3e3 !important;

      li {
        a {
          border-bottom: none !important;
          font-weight: 100;
        }
      }
    }
  }

  // DESKTOP overwrites and adjustments:
  @media all and (min-width: @nav-medium) {
    padding: 0 !important;
    width: 100% !important;
    min-width: @nav-medium;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);

    .navigation-list {
      padding-left: 0 !important;
    }

    &__inner {
      padding: 0 0 15px 0 !important;
      width: 100% !important;
      overflow: hidden;

      &:after {
        content: "";
        display: table !important;
        clear: both;
      }
    }
  }
  // Mega menu section styling
  &__account-details {
    @media all and (min-width: @nav-medium) {
      margin-top: -55px;
      padding-top: 0 !important;
    }
  }
  &__section {
    
    @media all and (min-width: @nav-medium) {
      float: left;
      padding-top: 22px !important;
      padding-right: 20px !important;
      padding-bottom: 10px;
      position: relative;
      overflow: visible !important;
      box-sizing: border-box;
      list-style: none;

      html[class] & {
        padding-left: 2rem !important;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      &.navigation-list.account-section {
        &:first-child {
          &:after {
            // Hide the list divider in account mega-menu
            display: none;
          }
        }
      }
      // one item
      &:first-child:nth-last-child(1) {
        width: 100%;
      }
      // two items
      &:first-child:nth-last-child(2),
      &:first-child:nth-last-child(2) ~ & {
        width: 50%;
      }
      // three items
      &:first-child:nth-last-child(3),
      &:first-child:nth-last-child(3) ~ & {
        width: 33.3333%;
      }
      // four items
      &:first-child:nth-last-child(4),
      &:first-child:nth-last-child(4) ~ & {
        width: 25%;
      }
      // five items
      &:first-child:nth-last-child(5),
      &:first-child:nth-last-child(5) ~ & {
        width: 25%;
      }
    }

    &__title {
      @media all and (min-width: @nav-medium) {
        color: @nav-black-text;
        font-size: 16px;
        margin: 0;
        padding: 0;
        width: 100%;

        & > a,
        &__inner {
          display: block;
          color: inherit;
          padding: 0;
          margin-bottom: 15px;
          cursor: default;
          outline: none;
          text-decoration:none; 
          font-weight:500;
        }
      }
    }
  }
}
