@keyframes boostAi-loading {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
@keyframes boostAi-shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.aiboost-button {
  opacity: 0;
  display: none;
}
.aiboost-button--visible {
  transition: opacity 0.55s;
  opacity: 1;
  display: block;
}
.boostAi-circle {
  position: fixed;
  z-index: 20;
  bottom: 2rem;
  right: 2rem;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 1rem 0;
  height: 5rem;
  width: 5rem;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #004b32 url('/Components/DanskeSpil/Domain/AvalonKundecenter/Graphics/SpriteSheets/Icons/kc-chat.svg') no-repeat 50% 50%;
  background-size: 3rem;
}
@media (min-width: 960px) {
  .boostAi-circle {
    height: 7.5rem;
    width: 7.5rem;
    background-size: unset;
  }
}
.boostAi-circle:active,
.boostAi-circle:hover {
  animation: boostAi-shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.boostAi-circle[data-enabled="True"] {
  display: flex;
}
.boostAi-circle.loading {
  animation-name: boostAi-loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
