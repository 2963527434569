@import url("Mixins/_mixins.less");

.pam-quick-overlay {
  width: 100%;
  max-width: 116rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5000;
}

.pam-quick-overlay--hidden {
  display: none;
}

.pam-quick-overlay__blurred-background {
  position: fixed;
  left: 0;
  top: 0;
  outline: 0;
  border: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.4);
  backdrop-filter: blur(2px);
  z-index: -1;
}

.pam-quick-overlay__content {
  background-color: @color-grey-245;
  padding: 2rem;
  top: 0;
  width: 90vw;
  right: 0;
  max-width: 116rem;
  position: absolute;
  z-index: 5000;
  overflow: hidden;

  @media (min-width: @breakpoint-mobile) {
    position: fixed;
    top: 10rem;
    width: 100%;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    max-height: calc(90vh - 5rem);
    overflow-y: auto;
  }

  @media (min-width: @breakpoint-tablet) {
    border-radius: 5px;
    padding: 3rem 5rem 5rem;
    margin: 0 auto;
  }
}

.pam-quick-overlay__iframe {
  border: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.pam-quick-overlay__close-button {
  margin-bottom: 2rem;
  margin-left: auto;
  background: transparent;
  outline: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}