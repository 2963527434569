﻿@import "../../../../../../DanskeSpil/Framework/Design/Styles/Mixins/DanskeSpilMixins.less";

// ### Back to parent link
.back-to-parent-link {
  .cm-font-ds-bold();
  .cm-font-size(1.5);
  color: #fff;
  margin-bottom: 17px;
  display: inline-block;

  &:before {
    content: "‹ ";
  }

  &:hover {
    text-decoration: none;
  }
}
