.u-hidden {
  display: none !important;
}
.seo-text-container {
  width: 100%;
  padding: 0.8rem 2rem 1.1rem 2rem;
  background-color: #e6e6e6;
  color: white;
}
.seo-text-container .seo-text {
  max-width: 96rem;
  margin: 0 auto;
}
.seo-text-container .seo-text a {
  color: white;
  text-decoration: underline;
}
.seo-text-container .seo-text h1,
.seo-text-container .seo-text h2,
.seo-text-container .seo-text h3 {
  text-transform: uppercase;
  text-align: center;
}
.global-footer {
  font-family: var(--font-family-danskespil);
  font-weight: 400;
  line-height: 1.4;
  color: white;
  border-top: 0.1rem solid white;
  box-sizing: border-box;
}
@media screen and (min-width: 950px) {
  .global-footer {
    padding-bottom: 0;
  }
}
@media print {
  .global-footer {
    display: none;
  }
}
.global-footer .info-bar {
  width: 100%;
  padding: 2rem 0;
}
@media screen and (min-width: 950px) {
  .global-footer .info-bar {
    padding: 4rem 0;
  }
}
.global-footer .global-footer-container {
  border-bottom: 0.1rem solid white;
  box-sizing: border-box;
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;
  padding: 0 2rem 2rem;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 950px) {
  .global-footer .global-footer-container {
    flex-direction: row;
    text-align: left;
    padding-bottom: 3rem;
  }
}
.global-footer *[class$="box"] {
  display: inline-block;
  vertical-align: top;
}
.global-footer .address-box,
.global-footer .contact-box,
.global-footer .navigation-box {
  flex: 1;
  order: 1;
  width: 100%;
  text-align: center;
  margin: 1rem 0;
}
@media screen and (min-width: 950px) {
  .global-footer .address-box,
  .global-footer .contact-box,
  .global-footer .navigation-box {
    text-align: left;
  }
}
@media screen and (min-width: 950px) {
  .global-footer .navigation-box {
    flex: 2;
    order: 2;
  }
  .global-footer .contact-box {
    order: 3;
  }
}
.global-footer .address-box {
  font-style: normal;
}
.global-footer .address-box .logo {
  margin-bottom: 0.2rem;
}
.global-footer .address-box .logo .logo-img {
  max-width: 15rem;
}
@media screen and (min-width: 768px) {
  .global-footer .address-box .logo .logo-img {
    max-width: 21rem;
  }
}
@media screen and (min-width: 950px) {
  .global-footer .address-box .logo .logo-img {
    max-width: 16rem;
    width: 100%;
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: 30em), (-ms-high-contrast: none) and (max-width: 30em) {
  .global-footer .address-box .logo .logo-img {
    max-width: 22.5rem;
    max-height: 3rem;
    width: auto;
    height: 100%;
  }
}
.global-footer .address-box p {
  margin: 0;
  font-size: 1.4rem;
  display: inline-block;
}
@media screen and (min-width: 950px) {
  .global-footer .address-box p {
    display: block;
  }
  .global-footer .address-box p::after {
    display: none;
  }
}
.global-footer .address-box p:nth-of-type(1) {
  display: block;
}
.global-footer .address-box p:nth-of-type(2)::after {
  content: ",";
  margin-right: 0.6rem;
}
.global-footer .navigation-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}
.global-footer .navigation-box a {
  flex: 1 1 50%;
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 0.4rem 0;
  display: inline-block;
  opacity: 1;
  transition: all 0.4s ease;
}
@media screen and (min-width: 950px) {
  .global-footer .navigation-box a {
    padding: 0;
  }
}
.global-footer .navigation-box a:hover {
  color: #000000;
  opacity: 0.5;
}
.global-footer .navigation-box::after {
  content: " ";
  height: 0.1rem;
  width: 90%;
  display: block;
  margin: 2.6rem auto 0.8rem;
  background-color: white;
}
@media screen and (min-width: 950px) {
  .global-footer .navigation-box::after {
    display: none;
  }
}
.global-footer .contact-box p {
  max-width: 100vw;
  display: block;
  margin: 0 auto;
  font-size: 1.4rem;
}
@media screen and (min-width: 950px) {
  .global-footer .contact-box p {
    max-width: 20rem;
    margin: 0;
  }
}
.global-footer .contact-box a[role="button"] {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  cursor: pointer;
  margin: 1.5rem 0;
  background-color: white;
  height: 5rem;
  line-height: 4.9rem;
  padding: 0 2rem;
  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0.5rem;
  display: inline-block;
  font-size: 1.4rem;
  white-space: nowrap;
}
.global-footer .global-footer-footer-theme-keno .info-bar {
  background-color: #00a5eb;
}
.global-footer .compliance {
  max-width: 95rem;
  margin: 0 auto;
  padding: 1.5rem 2rem 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .global-footer .compliance {
    padding: 3rem 2rem 1rem;
  }
}
.global-footer .compliance .compliance__title {
  font-size: 2.6rem;
  line-height: 1.3;
  font-weight: bold;
  max-width: 52rem;
  margin: 0 auto 0.6rem;
}
.global-footer .compliance .compliance__subtitle {
  font-size: 1.6rem;
  max-width: 52rem;
  line-height: 1.4;
  font-weight: 400;
  margin: 0 auto 0.6rem;
}
@media screen and (min-width: 768px) {
  .global-footer .compliance .compliance__subtitle {
    font-size: 2rem;
  }
}
.global-footer .compliance .compliance__subtitle a {
  text-decoration: underline;
  color: white;
}
.global-footer .compliance .compliance__items {
  margin-top: 2.3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.global-footer .compliance .compliance__items .compliance__item {
  font-weight: 300;
  background: rgba(0, 0, 0, 0.15);
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  flex: 0 0 auto;
  width: 100%;
  min-height: 8rem;
  margin-bottom: 2rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 1.6rem 0 rgba(0, 0, 0, 0.15);
  padding: 1rem 2rem;
}
.region-allornothing .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-allornothing .global-footer .compliance .compliance__items .compliance__item {
  background: #312f51;
}
.region-avalon-bingo .global-footer .compliance .compliance__items .compliance__item {
  background: #607f15;
}
.region-frontpage .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-frontpage .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-corporate .global-footer .compliance .compliance__items .compliance__item,
.region-kundecenter .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-kundecenter .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-danskespil .global-footer .compliance .compliance__items .compliance__item,
.region-retail-account .global-footer .compliance .compliance__items .compliance__item {
  background: #005f3f;
}
.region-dantoto .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-dantoto .global-footer .compliance .compliance__items .compliance__item {
  background: #Ae4a0c;
}
.region-eurojackpot .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-eurojackpot .global-footer .compliance .compliance__items .compliance__item {
  background: #936211;
}
.region-keno .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-keno .global-footer .compliance .compliance__items .compliance__item {
  background: #0091cf;
}
.region-avalon-livecasino .global-footer .compliance .compliance__items .compliance__item {
  background: #81693b;
}
.region-avalon-lotto .global-footer .compliance .compliance__items .compliance__item,
.region-lotto .global-footer .compliance .compliance__items .compliance__item {
  background: #a60b0f;
}
.region-oddset .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-oddset .global-footer .compliance .compliance__items .compliance__item,
.region-oddsetbonus .global-footer .compliance .compliance__items .compliance__item {
  background: #042048;
}
.region-playtogether .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-playtogether .global-footer .compliance .compliance__items .compliance__item {
  background: #004b32;
}
.region-poker .global-footer .compliance .compliance__items .compliance__item {
  background: #443e4c;
}
.region-avalon-klasselotteriet .global-footer .compliance .compliance__items .compliance__item {
  background: #CC1C1F;
}
.region-avalon-quick .global-footer .compliance .compliance__items .compliance__item {
  background: #672d70;
}
.region-spillehjoernet-gamelauncher .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-spillehjoernet .global-footer .compliance .compliance__items .compliance__item,
.region-spillehjoernet .global-footer .compliance .compliance__items .compliance__item {
  background: #376c8f;
}
.region-subscriptions .global-footer .compliance .compliance__items .compliance__item {
  background: #2e3535;
}
.region-tips .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-tips .global-footer .compliance .compliance__items .compliance__item {
  background: #006d44;
}
.region-vikinglotto .global-footer .compliance .compliance__items .compliance__item,
.region-avalon-vikinglotto .global-footer .compliance .compliance__items .compliance__item {
  background: #0000b4;
}
.region-casino .global-footer .compliance .compliance__items .compliance__item {
  background: #564523;
}
.region-avalon-ovb .global-footer .compliance .compliance__items .compliance__item {
  background: #5B9AB3;
}
@media screen and (min-width: 480px) {
  .global-footer .compliance .compliance__items .compliance__item {
    flex-direction: column;
    text-align: center;
    width: calc(50% - 0.5rem);
    margin-bottom: 1rem;
    padding: 3rem 1rem 2rem;
  }
}
@media screen and (min-width: 768px) {
  .global-footer .compliance .compliance__items .compliance__item {
    width: calc(25% - 0.5rem);
  }
}
@media screen and (min-width: 1024px) {
  .global-footer .compliance .compliance__items .compliance__item {
    width: calc(25% - 1rem);
  }
}
.global-footer .compliance .compliance__items .compliance__item .compliance__item-logo {
  flex: 0 0 8rem;
  padding-right: 1rem;
  box-sizing: border-box;
}
@media screen and (min-width: 480px) {
  .global-footer .compliance .compliance__items .compliance__item .compliance__item-logo {
    padding: 0;
    flex: 0 0 9rem;
  }
}
.global-footer .compliance .compliance__items .compliance__item .compliance__item-logo img {
  height: auto;
  width: 6rem;
}
@media screen and (min-width: 480px) {
  .global-footer .compliance .compliance__items .compliance__item .compliance__item-logo img {
    height: 6rem;
    width: auto;
  }
}
@media screen and (min-width: 1024px) {
  .global-footer .compliance .compliance__items .compliance__item .compliance__item-logo img {
    height: 7rem;
  }
}
.global-footer .compliance .compliance__items .compliance__item .compliance__item-text {
  font-size: 1.4rem;
  line-height: 1.3;
  color: white;
  text-decoration: none;
}
.global-footer .disclaimer {
  background: white;
  padding-top: 3rem;
}
.global-footer .disclaimer__content {
  display: flex;
  justify-content: center;
  max-width: 35rem;
  margin: 0 auto;
}
.global-footer .disclaimer-item {
  font-weight: 300;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.5rem 3rem;
}
.global-footer a.disclaimer-item {
  color: inherit;
}
.global-footer .disclaimer-item--logo img {
  height: 5rem;
}
.global-footer .disclaimer-item--clock {
  position: absolute;
  color: #000000;
  font-weight: bold;
  font-size: 1.2rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.3rem solid #000000;
  border-radius: 50%;
}
.global-footer .disclaimer-item--label {
  margin-top: 1rem;
  text-align: center;
  font-size: 1.1rem;
  color: #000000;
  max-width: 100%;
}
