@smartbannerHeight: 84px;

.generic-smartbanner-static-fix {
  height: 0;
  overflow: hidden;

  &.is-open {
    height: @smartbannerHeight;
  }
}

.generic-smartbanner {
  width: 100%;
  height: 0;
  overflow: hidden;
  background: #f3f3f3;
  font-family: Helvetica, sans, sans-serif;
  position: relative;
  z-index: 2;
  transition: all 0.4s ease;

  &.is-open {
    height: @smartbannerHeight;
  }

  &__content {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    & > * {
      &:not(:first-child):not(:last-child) {
        margin-left: 1rem;
      }
    }
  }

  &__button--close {
    font-size: 20px;
    display: block;
    margin: 0;
    font-family: serif;
    color: #000;
    text-decoration: none;
  }

  &__logo {
    width: 64px;
    height: 64px;
    border-radius: 15px;
  }

  &__info {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 60%;

    &--app-name {
      font-size: 14px;
      font-weight: 600;
    }

    &--company-name,
    &--description {
      font-size: 12px;
    }
  }

  &__cta {
    color: #1474fc;
    margin-left: auto;
    padding: 1rem;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    display: block;

    &:hover, &:active {
      text-decoration: none;
    }
  }
}
