html {
  font-size: 62.5%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html {
    font-size: calc(1em * 0.625);
  }
}
html:has(a[href^="#"]):not(a[href$="#"]) {
  scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1.2;
  color: #333;
  text-wrap: pretty;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#wrapper {
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  margin: 0 0 -200px 0;
}
.region-area {
  min-height: 560px;
}
.region-area.campaign-page {
  margin-top: 30px;
}
.page-box {
  position: relative;
}
ul,
li {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
