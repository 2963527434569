@import "./Anton/Anton";
@import "./AvenirNext/AvenirNext";
@import "./DanskeSpil/DanskeSpil";
@import "./Eurojackpot/Eurojackpot";
@import "./KansasNew/KansasNew";
@import "./Klasselotteriet/Klasselotteriet";
@import "./MergePro/MergePro";
@import "./OpenSans/OpenSans";
@import "./OliveVillage/OliveVillage";
@import "./Raleway/Raleway";
@import "./ShadowsIntoLight/ShadowsIntoLight";
@import "./Helium/Helium";
@import "./DSCasinoMultima/DSCasinoMultima";
@import "./VikingLottoDisplayBasic/VikinglottoDisplay";

:root {
  --font-family-danskespil: DanskeSpil, "Open Sans", Helvetica, Arial, sans-serif;
  --font-family-raleway: Raleway, "Open Sans", Helvetica, Arial, sans-serif;
  --font-family-degular: Degular, Arial, sans-serif;
  --font-family-ds-casino-multima: DSCasinoMultima, Arial, sans-serif;
}
