@tailwind base;
@tailwind utilities;


// This normally gets added with tailwinds 'preflight' mode.
::before,
::after {
  --tw-content: '';
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}