@import url("variables.less");

.mega-menu__campaign {
  display: none;
  @media all and (min-width: @nav-medium) { 
    display: block;

    &__link {
      display: inline-block;
      text-decoration: none;
      &__image {
        width: auto;
        max-width: 100%;
        height: auto;
      }
      &__text {
        display: block;
        font-size: 16px;
      }
    }
    &__cta-button {
      font-size: 16px;
      line-height: 1.2;
      padding: .2em 1em .6em;
      border-radius: 4px;
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: none;
      transition: .3s;
      margin-top: 12px;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
