﻿@import "../../../../../Common/Framework/Design/Styles/_GlobalMixins.less";
@import "../../../../Project/Design/Styles/Framework/Mixins/button-mixins.less";
@import "_DanskeSpilVariables.less";
@import "_DanskeSpilColorVariables.less";
@import "FontFaces.less";
@import "RenderingParameters.less";
@import "TypographyMixins.less";
@import "ButtonMixins.less";
@import "FillMixins.less";
@import "TextMixins.less";
@import "BreakPoints";

.box-sizing(@box-model: border-box) {
  -webkit-box-sizing: @box-model;
  -moz-box-sizing: @box-model;
  box-sizing: @box-model;
}

.clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
  //&:extend(.clearfixing all);
}

.opacity(@opacity: 1) {
  @ieOpacity: @opacity * 100;
  // IE 8
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=@{ieOpacity})";
  // Good browsers
  opacity: @opacity;
}

.global-sprite(@left: 0, @top: 0) {
  background-image: url(~'@{design-base-url}/Graphics/regions/all-regions/sprite-global.png');
  background-repeat: no-repeat;
  background-position: @left @top;
}
// Corner (border-radius)

.corners-all(@radius: 5px) {
  border-radius: @radius;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.corners-top(@radius: 5px) {
  border-radius: @radius @radius 0 0;
}

.corners-bottom(@radius: 5px) {
  border-radius: 0 0 @radius @radius;
}

.corners-left(@radius: 5px) {
  border-radius: @radius 0 0 @radius;
}

.corners-right(@radius: 5px) {
  border-radius: 0 @radius @radius 0;
}

// Gradients

.gradient-horizontal (@start-color: #fdfdfd, @end-color: #b4b4b4) {
  background-color: @end-color !important; //IE
  background-repeat: repeat-x;
  background: -webkit-linear-gradient(left, @start-color, @end-color);
  background: -moz-linear-gradient(left, @start-color, @end-color);
  background: -ms-linear-gradient(left, @start-color, @end-color);
  background: linear-gradient(left, @start-color, @end-color);
}

.gradient-vertical (@start-color: #fdfdfd, @end-color: #b4b4b4) {
  background-color: @end-color;
  background-repeat: repeat-x;
  background: -webkit-linear-gradient(@start-color, @end-color);
  background: -moz-linear-gradient(@start-color, @end-color);
  background: -ms-linear-gradient(@start-color, @end-color);
  background: linear-gradient(@start-color, @end-color);
}


.rotate(@deg-value: 45) {
  -moz-transform: rotate(~"@{deg-value}deg");
  -webkit-transform: rotate(~"@{deg-value}deg");
  -o-transform: rotate(~"@{deg-value}deg");
  -ms-transform: rotate(~"@{deg-value}deg");
  transform: rotate(~"@{deg-value}deg");
}

// Box-shadow

.box-shadow(@horizontalOffset: 0, @verticalOffset: 15px, @blurRadius: 35px, @spreadRadius: 2px, @shadowColor: rgba(0, 0, 0, 0.50)) {
  -moz-box-shadow: @horizontalOffset @verticalOffset @blurRadius @spreadRadius @shadowColor;
  box-shadow: @horizontalOffset @verticalOffset @blurRadius @spreadRadius @shadowColor;
}

.inner-shadow(@color: #084631) {
  -moz-box-shadow: inset 0 0 10px @color;
  box-shadow: inset 0 0 10px @color;
}

.text-shadow(@x: 1px, @y: 1px, @blur: 1px, @color: rgba(0, 0, 0, 0.50)) {
  text-shadow: @x @y @blur @color;
}


.visuallyhidden() {
  //Hide only visually, but not for screenreaders
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
}
